export enum AgentDesignation {
  Attorney = 'a',
  CPA = 'b',
  EnrolledAgent = 'c',
  Officer = 'd',
  FullTimeEmployee = 'e',
  FamilyMember = 'f',
  EnrolledActuary = 'g',
  UnenrolledReturnPreparer = 'h',
  QualifyingStudent = 'k',
  EnrolledRetirementPlanAgent = 'r',
}
