import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TestProvider } from './test-provider';

@NgModule({
  imports: [CommonModule],
})
export class MockCoreLibModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: MockCoreLibModule,
      providers: [
        ...TestProvider.PROVIDED_MOCK_SERVICES, // If mock services need to be injected into other mock services, provide them here
        ...TestProvider.getTestProviders(),
      ],
    };
  }
}
