import {
  MintAddressModel,
  MintAddressOwnership,
  MintAddressUse,
  MintEntityMeta,
  MintModel,
  MintObject,
  MintProperty,
  MintResource,
  MintTag,
  mintTagModelTransformer,
} from '@bryllyant/mint-ngx';
import { Transform, Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsObject,
  IsString,
} from 'class-validator';
import { BusinessModel } from '../business.model';

@MintResource('/business-addresses')
export class BusinessAddressModel extends MintModel {
  @MintProperty()
  @IsString()
  declare readonly uid: string;

  @MintProperty()
  @Type(() => BusinessModel)
  readonly business: BusinessModel;

  @MintProperty()
  @Type(() => MintAddressModel)
  readonly address: MintAddressModel;

  @MintProperty()
  @IsEnum(MintAddressOwnership)
  readonly ownership: MintAddressOwnership;

  @MintProperty()
  @IsEnum(MintAddressUse)
  readonly addressUse: MintAddressUse;

  @MintProperty()
  @IsBoolean()
  readonly isActive: boolean;

  @MintProperty()
  @IsBoolean()
  readonly isPrimary: boolean;

  @MintProperty()
  @Type(() => Date)
  readonly startDate: Date;

  @MintProperty()
  @Type(() => Date)
  readonly endDate: Date;

  @MintProperty()
  @IsArray()
  @Transform(mintTagModelTransformer)
  readonly tags: MintTag[];

  @MintProperty()
  @IsObject()
  readonly context: MintObject;

  @MintProperty()
  @Type(() => Date)
  declare readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  declare readonly updatedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  declare readonly _meta: MintEntityMeta;

  constructor(model?: Partial<BusinessAddressModel>) {
    super(model);
  }
}
