import { Injectable } from '@angular/core';
import {
  MintAuthLoginCredentialsDto,
  MintSession,
  MintSessionToken,
  MintAuthChannel,
  MintVerificationStatus,
  MintRegistrationDto,
  MintUserMobileUpdateDto,
  MintAuthPasswordUpdateDto,
  MintAuthCodeVerificationDto,
  MintUserModel,
  MintAuthPasswordStatus,
} from '@bryllyant/mint-ngx';

import { MockPropStrategy } from '../test-util';
import { TestController } from '../test.controller';
import { MockUserService } from './user.service.mock';

@Injectable()
export class MockAuthService extends TestController<MintSession | any> {
  constructor(private readonly mockUserService: MockUserService) {
    super();

    this.model = this.generateModel(this.getMockSchema());
  }

  getMockSchema(): MintSession {
    return new MintSession({
      user: this.generateModel(this.mockUserService.getMockSchema()),
      token: new MintSessionToken({
        accessToken: MockPropStrategy.String,
      }),
      mfa: {
        required: true,
        channels: [{ channel: MintAuthChannel.Email, isDefault: true }],
      },
      verification: {
        email: {
          required: true,
          status: MintVerificationStatus.NotVerified,
        },
        mobileNumber: {
          required: true,
          status: MintVerificationStatus.NotVerified,
        },
        password: {
          required: true,
          status: MintAuthPasswordStatus.Exists,
        },
      },
    });
  }

  async login(
    loginRequest: MintAuthLoginCredentialsDto,
    rememberMe: boolean,
  ): Promise<MintSession> {
    return this.model;
  }

  async register(registration: MintRegistrationDto) {
    return this.model;
  }

  async setUserMobileNumber(request: MintUserMobileUpdateDto) {
    return this.model;
  }

  async updatePassword(data: MintAuthPasswordUpdateDto) {
    return this.model;
  }

  async logout(clearStorage?: boolean): Promise<void> {
    return Promise.resolve();
  }

  async requestEmailAuthCode() {
    return;
  }

  async requestMobileAuthCode() {
    return;
  }

  async resetPasswordRequest(username: string) {
    return;
  }

  async requestMfaCode(channel: MintAuthChannel) {
    return;
  }

  async verifyAuthCode(authCodeVerificationReq: MintAuthCodeVerificationDto) {
    return this.model;
  }

  async me(): Promise<MintUserModel> {
    return this.model.user;
  }
}
