<ng-container *ngIf="field && field.visible(field.control)">
  <input
    [placeholder]="field.placeholder ? field.placeholder : ''"
    nz-input
    [(ngModel)]="field.value"
    (ngModelChange)="modelChanged($event)"
    [nzAutocomplete]="auto"
    [attr.maxLength]="field.maxLength || null"
    disableAutofill
  />
  <nz-autocomplete #auto [nzWidth]="500" nzOverlayClassName="autcomplete-dropdown">
    <nz-auto-option *ngIf="field.loading === 'search'" nzDisabled nzLabel="" nzValue="">
      <i nz-icon nzType="loading" class="mint-select-loading-icon"></i>
      Loading ...
    </nz-auto-option>

    <ng-container *ngIf="field.loading !== 'search'">
      <nz-auto-option
        *ngFor="let option of field.options"
        [nzLabel]="option.name"
        [nzValue]="option.value"
      >
        <ng-container *ngIf="(option.name || option.displayName) && !option.displayTemplate">
          <div
            [innerHTML]="(option.displayName ? option.displayName : option.name) | mintSafeHtml"
          ></div>
        </ng-container>

        <ng-container *ngIf="option.displayTemplate">
          <ng-container
            *ngTemplateOutlet="
              option.displayTemplate.templateRef;
              context: option.displayTemplate.context
            "
          ></ng-container>
        </ng-container>
      </nz-auto-option>

      <nz-auto-option *ngIf="field.loading === 'scroll'" nzDisabled>
        <i nz-icon nzType="loading" class="mint-select-loading-icon"></i>
        Loading ...
      </nz-auto-option>
    </ng-container>
  </nz-autocomplete>
  <div *ngIf="!isValid()" class="mint-form-field-help">
    <p *ngFor="let error of getErrors()" [innerHtml]="error | mintSafeHtml"></p>
  </div>
</ng-container>
