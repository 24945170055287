import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintObject,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerInvitationRequestDto } from './taxpayer-invitation-request.dto';
import { TaxpayerInvitationModel } from './taxpayer-invitation.model';

const logger = MintLogger.getLogger('TaxpayerInvitationService');

@Injectable({ providedIn: 'root' })
export class TaxpayerInvitationService extends MintService<TaxpayerInvitationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerInvitationModel,
      TaxpayerInvitationModel._mintResourceName,
    );
  }

  async create(
    dto: TaxpayerInvitationRequestDto,
    urlPathVars?: MintObject,
    params?: MintObject,
  ): Promise<TaxpayerInvitationModel> {
    return super.create(dto, urlPathVars, params);
  }
}
