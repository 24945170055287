import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import {
  MintCheckboxGroupModule,
  MintCommonModule,
  MintCoreModule,
  MintEmailValidator,
  MintInputPhoneModule,
  MintMobileNumberValidator,
  MintPipesModule,
  MintPlainTextModule,
  MintRadioGroupModule,
  MintSelectModule,
} from '@bryllyant/mint-ngx';

import { MockCoreLibModule } from './test.module';

export class TestConfig {
  static MODULE_CONFIG = {
    _imports: [
      BrowserAnimationsModule,
      CommonModule,
      FormsModule,
      MockCoreLibModule.forRoot(),
      MintCommonModule.forRoot({
        env: 'local',
        configs: {},
      }),
      MintCheckboxGroupModule,
      MintCoreModule,
      MintInputPhoneModule,
      MintPipesModule,
      MintPlainTextModule,
      MintRadioGroupModule,
      MintSelectModule,
      RouterTestingModule,

      NzButtonModule,
      NgIdleKeepaliveModule.forRoot(),
      NzCheckboxModule,
      NzDividerModule,
      NzDropDownModule,
      NzGridModule,
      NzIconModule,
      NzInputModule,
      NzMenuModule,
      NzModalModule,
      NzRadioModule,
      NzSelectModule,
      NzSwitchModule,
      NzTableModule,
      NzToolTipModule,
      NzTypographyModule,
      NzUploadModule,
    ],
    get imports() {
      return this._imports;
    },
    set imports(value) {
      this._imports = value;
    },
    declarations: [],
    providers: [
      {
        provide: NzNotificationService,
        useValue: {},
      },
      {
        provide: HttpClient,
        useValue: {},
      },
      {
        provide: MintMobileNumberValidator,
        useValue: {
          isValid() {
            return true;
          },
        },
      },
      {
        provide: MintEmailValidator,
        useValue: {
          isValid(control: string | UntypedFormControl) {
            let email = control as string;

            if (typeof control !== 'string') {
              email = control.value;
            }

            const valid = MintEmailValidator.EMAIL_REGEXP.test(email);

            if (!valid) {
              return {
                format: true,
              };
            }

            return null;
          },
        },
      },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  };
}
