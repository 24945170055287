import { MintFormFieldOption } from '@bryllyant/mint-ngx';

export enum TranscriptType {
  AccountTranscript = 'ACTR',
  TaxReturnTranscript = 'RETR',
  RecordOfAccount = 'RECA',
  WageAndIncome = 'WAID',
  VerificationOfNonFiling = 'VENF',
}

export enum TranscriptPurpose {
  FederalTax = 'Federal Tax',
  MortgageIndustry = 'Mortgage Industry',
  SBA = 'SBA',
  FEMA = 'FEMA/Disaster',
  HUD = 'HUD',
  StudentLoan = 'Student Loan',
  INS_SponsorFinancial = 'INS Sponsor Financial',
  StateLicensing = 'State Licensing',
  ForeignCertification = 'Foreign Certification',
  Other = 'Other',
  StateOrLocalTax = 'State or Local Tax',
}

export enum TaxPeriod {
  January = '1',
  February = '2',
  March = '3',
  April = '4',
  May = '5',
  June = '6',
  July = '7',
  August = '8',
  September = '9',
  October = '10',
  November = '11',
  December = '12',
}

export const taxPeriodToMonth = (taxPeriod: TaxPeriod): string | undefined => {
  return taxPeriodOptions.find(
    (taxPeriodOption) => taxPeriodOption.value === taxPeriod,
  )?.name;
};

export const taxPeriodOptions: MintFormFieldOption[] = [
  {
    name: 'January',
    value: TaxPeriod.January,
  },
  {
    name: 'February',
    value: TaxPeriod.February,
  },
  {
    name: 'March',
    value: TaxPeriod.March,
  },
  {
    name: 'April',
    value: TaxPeriod.April,
  },
  {
    name: 'May',
    value: TaxPeriod.May,
  },
  {
    name: 'June',
    value: TaxPeriod.June,
  },
  {
    name: 'July',
    value: TaxPeriod.July,
  },
  {
    name: 'August',
    value: TaxPeriod.August,
  },
  {
    name: 'September',
    value: TaxPeriod.September,
  },
  {
    name: 'October',
    value: TaxPeriod.October,
  },
  {
    name: 'November',
    value: TaxPeriod.November,
  },
  {
    name: 'December',
    value: TaxPeriod.December,
  },
];

export enum TaxFormNumber {
  _11C = '11C',
  _706 = '706',
  _706GS = '706GS(T)',
  _706NA = '706NA',
  _720 = '720',
  _730 = '730',
  _940 = '940',
  _940EZ = '940EZ',
  _941 = '941',
  _943 = '943',
  _944 = '944',
  _945 = '945',
  _990 = '990',
  _990C = '990C',
  _990EZ = '990EZ',
  _990PF = '990PF',
  _990T = '990T',
  _1040 = '1040',
  _1040A = '1040A',
  _1040EZ = '1040EZ',
  _1040NR = '1040NR',
  _1040SS = '1040SS/PR',
  _1040NREZ = '1040NREZ',
  _1040SeparateAssessment = '1040 SEPARATE ASSESSMENT',
  _1041 = '1041',
  _1041A = '1041A',
  _1041QFT = '1041QFT',
  _1042 = '1042',
  _1042_S = '1042-S',
  _1065 = '1065',
  _1066 = '1066',
  _1065B = '1065B',
  _1097_BTC = '1097-BTC',
  _1098 = '1098',
  _1098_C = '1098-C',
  _1098_E = '1098-E',
  _1098_Q = '1098-Q',
  _1098_T = '1098-T',
  _1099_A = '1099-A',
  _1099_B = '1099-B',
  _1099_C = '1099-C',
  _1099_CAP = '1099-CAP',
  _1099_DIV = '1099-DIV',
  _1099_G = '1099-G',
  _1099_H = '1099-H',
  _1099_INT = '1099-INT',
  _1099_K = '1099-K',
  _1099_LS = '1099-LS',
  _1099_LTC = '1099-LTC',
  _1099_MISC = '1099-MISC',
  _1099_MSA = '1099-MSA',
  _1099_OID = '1099-OID',
  _1099_PATR = '1099-PATR',
  _1099_Q = '1099-Q',
  _1099_R = '1099-R',
  _1099_S = '1099-S',
  _1099_SA = '1099-SA',
  _1099_SB = '1099-SB',
  _1120 = '1120',
  _1120A = '1120A',
  _1120C = '1120C',
  _1120F = '1120F',
  _1120FSC = '1120FSC',
  _1120H = '1120H',
  _1120L = '1120L',
  _1120ND = '1120ND',
  _1120PC = '1120PC',
  _1120POL = '1120POL',
  _1120REIT = '1120REIT',
  _1120RIC = '1120RIC',
  _1120S = '1120S',
  _1120SF = '1120SF',
  _2290 = '2290',
  _3520A = '3520A',
  _3921 = '3921',
  _3922 = '3922',
  _4720 = '4720',
  _5227 = '5227',
  _5329 = '5329',
  _5498 = '5498',
  _5498_ESA = '5498-ESA',
  _5498_MSA = '5498-MSA',
  _5498_SA = '5498-SA',
  _8288 = '8288',
  _8752 = '8752',
  _8804 = '8804',
  _8805 = '8805',

  CivilPenalty = 'CIVIL PENALTY',
  CT_1 = 'CT-1',
  EOIIR = 'EOIIR',
  K_1_1065 = 'K-1 1065',
  K_1_1120S = 'K-1 1120S',
  K_1_1041 = 'K-1 1041',
  SSA_1099 = 'SSA-1099',
  Summary = 'SUMMARY',
  W_2 = 'W-2',
  W_2G = 'W-2G',
  W_4 = 'W-4',
}
