import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService } from '@fynvana/common';
import { IrsFormModel, IrsFormService, IrsFormSigner } from '@fynvana/core';
import { DocusignSessionEndPayload } from './docusign.types';

const logger = MintLogger.getLogger('DocusignComponent');

// There are no types available for the docusign client js bundle, so defining what we need
type Docusign = {
  DocuSign: {
    loadDocuSign: (integrationKey: string) => Promise<DocusignClientApi>;
  };
};

interface DocusignClientApi {
  signing: DocusignSigningViewFunction;
}

type DocusignSigningViewFunction = (opts: {
  url: string;
  displayFormat: string;
  style: any;
}) => DocusignSigningView;

interface DocusignSigningView {
  on: (eventName: string, eventHandler: (event: any) => void) => void;
  mount: (htmlId: string) => void;
}

@Component({
  selector: 'fynvana-docusign',
  templateUrl: './docusign.component.html',
  styleUrls: ['./docusign.component.scss'],
})
export class DocusignComponent extends BaseController implements AfterViewInit {
  @Input() irsForm: IrsFormModel;
  @Input() signer: IrsFormSigner;

  @Output() handleReady = new EventEmitter<any>(); // Event emitted when docusign widget has been rendered
  @Output() handleSessionEnd = new EventEmitter<DocusignSessionEndPayload>(); // Event emitted when a docusign session ends

  readonly bundleUrl: string;
  private integrationKey: string;
  signingUrl: string;

  private docusignApi: DocusignClientApi;

  constructor(
    public baseService: BaseService,
    private readonly irsFormService: IrsFormService,
  ) {
    super(baseService);

    this.bundleUrl = this.baseService.configService.isProdution()
      ? 'https://js.docusign.com/bundle.js'
      : 'https://js-d.docusign.com/bundle.js';

    this.integrationKey = this.baseService.config.services.docusign
      ?.integrationKey as string;
  }

  async ngAfterViewInit() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.src = this.bundleUrl;
    body.appendChild(script);

    try {
      const { url } = await this.irsFormService.getSigningUrl(
        this.irsForm,
        this.signer,
      );

      this.signingUrl = url;

      this.docusignApi = await (
        window as Window & typeof globalThis & Docusign
      ).DocuSign.loadDocuSign(this.integrationKey);

      this.mountDocusignWidget('#fynvana-docusign');
    } catch (ex) {
      // Any configuration or API limits will be caught here
      logger.error(ex);
    }
  }

  mountDocusignWidget(htmlId: string) {
    const signing = this.docusignApi.signing({
      url: this.signingUrl,
      displayFormat: 'focused',
      style: {
        branding: {
          primaryButton: {
            /** Background color of primary button */
            backgroundColor: '#333',
            /** Text color of primary button */
            color: '#fff',
          },
        },
        signingNavigationButton: {
          finishText: 'Continue',
          /** 'bottom-left'|'bottom-center'|'bottom-right',  default: bottom-right */
          position: 'bottom-left',
        },
      },
    });

    /** Event handlers **/
    signing.on('ready', (event: any) => {
      logger.debug('docusign UI is rendered', event);
      this.handleReady.emit();
    });
    signing.on('sessionEnd', (event: DocusignSessionEndPayload) => {
      /** The event here denotes what caused the sessionEnd to trigger, such as signing_complete, ttl_expired etc../ */
      logger.debug('sessionEnd', event);
      this.handleSessionEnd.emit(event);
    });

    signing.mount(htmlId);

    // Causes component to be rendered correctly
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 2000);
  }
}
