import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Config, ErrorPageType } from '@fynvana/common';
import { ErrorPageComponent } from '@fynvana/components';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('./public/public.module').then((m) => m.PublicModule),
          data: { preload: true },
        },
        {
          path: '**',
          component: ErrorPageComponent,
          data: { preload: false, type: ErrorPageType.PageNotFound },
        },
      ],
      Config.ROUTE_OPTIONS,
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
