import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';

import { ClientUserModel } from './client-user.model';

const logger = MintLogger.getLogger('ClientUserService');

@Injectable({ providedIn: 'root' })
export class ClientUserService extends MintService<ClientUserModel> {
  clientUserCreatedSubject = new Subject<boolean | undefined>();
  clientUserUpdatedSubject = new Subject<boolean | undefined>();

  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      ClientUserModel,
      ClientUserModel._mintResourceName,
    );
  }

  invite(clientUser: ClientUserModel) {
    return this.httpService.post(`${this.urlPath}/invitations`, clientUser);
  }
}
