import { Component, Input } from '@angular/core';

@Component({
  selector: 'fynvana-container',
  template: `
    <section
      class="{{ wrapperClasses }}"
      [ngClass]="{
        '': type === 'no-style',
        container: type === 'normal',
        'container-full': type === 'full'
      }"
    >
      <ng-content></ng-content>
    </section>
  `,
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
  @Input() type: 'full' | 'normal' | 'no-style' = 'normal';
  @Input() wrapperClasses = '';
}
