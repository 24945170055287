import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorPageType } from '@fynvana/common';

@Component({
  selector: 'fynvana-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  @Input() errorType: ErrorPageType;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {
    const errorType =
      this.route.snapshot.data.type ?? this.route.snapshot.queryParams.type;

    if (!this.errorType) {
      this.errorType = errorType ?? ErrorPageType.PageNotFound;
    }
  }
}
