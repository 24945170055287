import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintError,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { plainToInstance } from 'class-transformer';
import { TaxpayerConsentModel } from '../taxpayer/consent/taxpayer-consent.model';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';
import { ProjectModel } from './project.model';
import {
  ProjectWithTaxpayerAndConsent,
  TaxpayerWithProjectAndConsent,
} from './project.types';

const logger = MintLogger.getLogger('ProjectService');

@Injectable({ providedIn: 'root' })
export class ProjectService extends MintService<ProjectModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      ProjectModel,
      ProjectModel._mintResourceName,
    );
  }

  // ':projectUid/taxpayers'
  async getTaxpayersForProject(
    project: ProjectModel | string,
  ): Promise<ProjectWithTaxpayerAndConsent> {
    const urlPath = `${this.urlPath}/${typeof project === 'string' ? project : project.uid}/taxpayers`;

    try {
      const result = (await this.httpService.get(
        urlPath,
      )) as ProjectWithTaxpayerAndConsent;

      return {
        project: plainToInstance(ProjectModel, result.project),
        data: result.data.map((item) => {
          return {
            taxpayer: plainToInstance(TaxpayerModel, item.taxpayer),
            consent: plainToInstance(TaxpayerConsentModel, item.consent),
          };
        }),
      };
    } catch (err) {
      logger.error('getTaxpayersForProject: error: ', err);

      throw new MintError('Error fetching taxpayers for project', {
        data: {
          error: err,
        },
      });
    }
  }

  // 'taxpayers/:taxpayerUid'
  async getProjectsForTaxpayer(
    taxpayer: TaxpayerModel | string,
  ): Promise<TaxpayerWithProjectAndConsent> {
    const urlPath = `${this.urlPath}/taxpayers/${typeof taxpayer === 'string' ? taxpayer : taxpayer.uid}`;

    try {
      const result = (await this.httpService.get(
        urlPath,
      )) as TaxpayerWithProjectAndConsent;

      return {
        taxpayer: plainToInstance(TaxpayerModel, result.taxpayer),
        data: result.data.map((item) => {
          return {
            project: plainToInstance(ProjectModel, item.project),
            consent: plainToInstance(TaxpayerConsentModel, item.consent),
          };
        }),
      };
    } catch (err) {
      logger.error('getProjectsForTaxpayer: error: ', err);

      throw new MintError('Error fetching projects for taxpayer', {
        data: {
          error: err,
        },
      });
    }
  }
}
