import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { IndividualEmailModel } from './individual-email.model';

const logger = MintLogger.getLogger('IndividualEmailService');

@Injectable({ providedIn: 'root' })
export class IndividualEmailService extends MintService<IndividualEmailModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      IndividualEmailModel,
      IndividualEmailModel._mintResourceName,
    );
  }
}
