<fynvana-main-layout
  [enableMinimalFooter]="true"
  [homeMenuItem]="{ routerLink: undefined, url: undefined }"
>
  <main class="p-2">
    <fynvana-container>
      <ng-container *ngIf="errorType === '404'">
        <h1 class="title blue">Error 404: Page not found</h1>

        <div class="py-2">
          <fynvana-alert
            type="error"
            message="The page you are looking for cannot be found. Please check the link that was provided to you and try again."
          >
          </fynvana-alert>
        </div>
      </ng-container>

      <ng-container *ngIf="errorType === '503-maintenance'">
        <h1 class="title blue">Error 503: Service Temporarily Unavailable</h1>

        <div class="py-2">
          <fynvana-alert
            type="error"
            message="We are performing maintenance and will be back online as soon as possible. We apologize for the inconvenience."
          >
          </fynvana-alert>
        </div>
      </ng-container>

      <ng-container *ngIf="errorType.includes('account-locked')">
        <h1 class="title blue">Your Account is Locked!</h1>

        <div class="py-2">
          <fynvana-alert type="error" [message]="accountLockedMessage">
          </fynvana-alert>

          <ng-template #accountLockedMessage>
            <span *ngIf="errorType === 'account-locked-max-failed-attempts'"
              >Too many failed attempts.</span
            >
            Your account has been locked. Please
            <a routerLink="/contact">contact us</a> for additional support.
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngIf="errorType === 'device-locked'">
        <h1 class="title blue">Your Device is Locked!</h1>

        <div class="py-2">
          <fynvana-alert type="error" [message]="deviceLockedMessage">
          </fynvana-alert>

          <ng-template #deviceLockedMessage>
            Your device has been locked. Please
            <a routerLink="/contact">contact us</a> for additional support.
          </ng-template>
        </div>
      </ng-container>
    </fynvana-container>
  </main>
</fynvana-main-layout>
