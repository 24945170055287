import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';

import {
  BaseController,
  BaseService,
  ModalSize,
  StatusType,
} from '@fynvana/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fynvana-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent extends BaseController implements OnDestroy {
  @Input() visible = false;
  @Input() modalIsLoading = false;
  @Input() closable = false;
  @Input() size: ModalSize = ModalSize.Large;
  @Input() wrapperClasses = '';

  @Input() modalTitle: string;
  @Input() modalSubTitle: string;
  @Input() infoTooltipTitle: string | null;
  @Input() infoTooltipText: string | null;

  @Input() hideModalActions = false;

  @Input() submitLabel = 'Save';
  @Input() submitDisabled = false;
  @Input() hideSubmit = false;

  @Input() cancelLabel = 'Cancel';
  @Input() hideCancel = false;

  @Input() statusAlertType: StatusType | undefined | any;
  @Input() statusAlertTitle: string | undefined;
  @Input() statusAlertMessage: string | TemplateRef<void> | undefined;

  @Output() submitted = new EventEmitter<null>();
  @Output() closed = new EventEmitter<null>();

  loadingListener: Subscription;
  loadingProcesses = 0;

  constructor(public baseService: BaseService) {
    super(baseService);
    this.loadingListener = this.baseService.toggleLoading.subscribe(
      (isLoading) =>
        isLoading ? this.loadingProcesses++ : this.loadingProcesses--,
    );
  }

  getModalWidth(): number {
    switch (this.size) {
      case ModalSize.Large:
        return 864;
      case ModalSize.Small:
        return 572;
    }
  }

  ngOnDestroy() {
    this.loadingListener.unsubscribe();
  }
}
