import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import * as Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-typescript';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

import 'prism-json-fold';

@Injectable({ providedIn: 'root' })
export class SyntaxHighlightService {
  prism = Prism;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  /** NOTE: this will break 'prism-json-folds' if used
   *  Do not use if json code collapsing is needed
   */
  highlightAll() {
    if (isPlatformBrowser(this.platformId)) {
      Prism.highlightAll();
    }
  }

  highlightElement(element: Element) {
    Prism.highlightElement(element);
  }
}
