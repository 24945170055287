import { Component, Input } from '@angular/core';
import { BaseService } from '@fynvana/common';

@Component({
  selector: 'fynvana-loader',
  template: `
    <ng-template #indicatorTemplate>
      <div
        class="loading-spinner-container {{
          baseService.layoutState.colorTheme
        }} {{ wrapperClasses }}"
        [ngStyle]="{ position: positionStyle }"
        [ngClass]="{ 'mask-bg': hasMaskBg }"
      >
        <div class="flex-center flex-col">
          <div class="loading-spinner">
            <div [ngClass]="{ sm: size === 'sm' }"></div>
            <div [ngClass]="{ sm: size === 'sm' }"></div>
            <div [ngClass]="{ sm: size === 'sm' }"></div>
            <div [ngClass]="{ sm: size === 'sm' }"></div>
          </div>
          <div class="mt-1" *ngIf="loadingText">
            {{ loadingText }}
          </div>
        </div>
      </div>
    </ng-template>
    <nz-spin
      [nzSpinning]="isLoading"
      [nzIndicator]="indicatorTemplate"
    ></nz-spin>
  `,
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() isLoading = false;
  @Input() loadingText: string;
  @Input() hasMaskBg = true;
  @Input() positionStyle: 'absolute' | 'fixed' = 'fixed';
  @Input() size: 'sm' | 'lg' = 'lg';
  @Input() wrapperClasses = '';

  constructor(public baseService: BaseService) {}
}
