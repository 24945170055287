import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MintConfigService, MintLogger } from '@bryllyant/mint-ngx';
import { lastValueFrom } from 'rxjs';

import { CustomOpenAPIObject } from './documentation.types';

const logger = MintLogger.getLogger('ApiDocumentationService');

@Injectable({ providedIn: 'root' })
export class ApiDocumentationService {
  apiBaseUrl: string;
  docs: CustomOpenAPIObject | undefined;

  constructor(
    private readonly http: HttpClient,
    private readonly config: MintConfigService,
  ) {
    this.apiBaseUrl = this.config.getConfig().api?.baseUrl;
  }

  async initDocs() {
    try {
      const response = this.http.get<CustomOpenAPIObject>(
        `${this.apiBaseUrl}open-api`,
        {
          headers: {
            'FYNVANA-CLIENT-ID': this.config.getConfig().api?.clientId,
          },
        },
      );
      this.docs = await lastValueFrom(response);
    } catch (err) {
      logger.debug(err);
    }
  }

  async getDocs(): Promise<CustomOpenAPIObject | undefined> {
    if (!this.docs) {
      await this.initDocs();
    }

    return this.docs;
  }
}
