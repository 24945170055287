// Angular
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// External Packages
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormDirective } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule, NzTableStyleService } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

// Bryllyant / Fynvana
import {
  MintCheckboxGroupModule,
  MintCoreModule,
  MintFormModule,
  MintInputPhoneModule,
  MintPlainTextModule,
  MintRadioGroupModule,
  MintSelectModule,
} from '@bryllyant/mint-ngx';
import { CommonLibModule } from '@fynvana/common';

// App
import { AlertComponent } from './alert/alert.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BtnComponent } from './btn/btn.component';
import { DocusignComponent } from './docusign/docusign.component';
import { AutocompleteComponent } from './form/autocomplete/autocomplete.component';
import { InputTagsComponent } from './form/input-tags/input-tags.component';
import { IconComponent } from './icon/icon.component';
import { InlineVerificationComponent } from './inline-verification/inline-verification.component';
import { ContainerComponent } from './layout/container/container.component';
import { MainFooterComponent } from './layout/main-footer/main-footer.component';
import { AvatarProfileMenuComponent } from './layout/main-header/avatar-profile-menu/avatar-profile-menu.component';
import { MainHeaderComponent } from './layout/main-header/main-header.component';
import { MainLayoutComponent } from './layout/main-layout.component';
import { SectionHeaderComponent } from './layout/section-header/section-header.component';
import { SidebarNavComponent } from './layout/sidebar-nav/sidebar-nav.component';
import { LoaderComponent } from './loader/loader.component';
import { QuickAccessMenuComponent } from './menus/quick-access-menu/quick-access-menu.component';
import { AboutModalComponent } from './modals/about-modal/about-modal.component';
import { BaseModalComponent } from './modals/base-modal.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { PolicyModalComponent } from './modals/policy-modal/policy-modal.component';
import { StatusAlertModalComponent } from './modals/status-alert-modal/status-alert-modal.component';
import { VerificationModalComponent } from './modals/verification-modal/verification-modal.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';

const imports = [
  AngularSvgIconModule.forRoot(),
  CommonLibModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  MintCheckboxGroupModule,
  MintCoreModule,
  MintFormModule,
  MintInputPhoneModule,
  MintPlainTextModule,
  MintRadioGroupModule,
  MintSelectModule,
  NzAlertModule,
  NzAutocompleteModule,
  NzAvatarModule,
  NzBreadCrumbModule,
  NzButtonModule,
  NzCheckboxModule,
  NzDropDownModule,
  NzGridModule,
  NzInputModule,
  NzLayoutModule,
  NzMenuModule,
  NzModalModule,
  NzNotificationModule,
  NzPaginationModule,
  NzRadioModule,
  NzSkeletonModule,
  NzSpinModule,
  NzSwitchModule,
  NzTableModule,
  NzTagModule,
  NzToolTipModule,
  RouterModule,
];
const declarations = [
  AboutModalComponent,
  AlertComponent,
  AutocompleteComponent,
  AvatarProfileMenuComponent,
  BaseModalComponent,
  BreadcrumbsComponent,
  BtnComponent,
  ConfirmationModalComponent,
  ContactPageComponent,
  ContainerComponent,
  DocusignComponent,
  ErrorPageComponent,
  IconComponent,
  InlineVerificationComponent,
  InputTagsComponent,
  LoaderComponent,
  MainFooterComponent,
  MainHeaderComponent,
  MainLayoutComponent,
  PolicyModalComponent,
  QuickAccessMenuComponent,
  SectionHeaderComponent,
  SidebarNavComponent,
  StatusAlertModalComponent,
  TableComponent,
  TabsComponent,
  VerificationModalComponent,
];
const providers = [{ provide: NZ_I18N, useValue: en_US }, NzTableStyleService];

@NgModule({
  imports: [imports, NzFormDirective],
  declarations,
  exports: [...declarations],
  providers,
})
export class ComponentLibModule {}
