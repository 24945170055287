import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { FormController, BaseService, StatusType } from '@fynvana/common';

const logger = MintLogger.getLogger('ConfirmationModalComponent');

@Component({
  selector: 'fynvana-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: [
    '../base-modal.component.scss',
    './confirmation-modal.component.scss',
  ],
})
export class ConfirmationModalComponent extends FormController {
  @Input() isVisible: boolean;
  @Input() closable = true;
  @Input() messageTitle: string;
  @Input() message: string | TemplateRef<void>;
  @Input() type: StatusType | any;
  @Input() confirmationTitle = 'Confirm Action';
  @Input() confirmBtnLabel = 'Yes';
  @Input() cancelBtnLabel = 'Cancel';

  @Output() closeModal = new EventEmitter<null>();
  @Output() handleSuccess = new EventEmitter<any>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }

  onSubmit() {
    this.handleSuccess.emit();
  }
}
