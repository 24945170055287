import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MintFormFieldComponent, MintLogger } from '@bryllyant/mint-ngx';
import { Autocomplete } from './autocomplete';

const logger = MintLogger.getLogger('AutocompleteComponent');

export const FYNVANA_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'fynvana-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FYNVANA_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class AutocompleteComponent
  extends MintFormFieldComponent<Autocomplete>
  implements ControlValueAccessor, OnInit
{
  @Input() override field: Autocomplete;

  @ViewChild('control', { static: false }) controlElement: any;

  constructor() {
    super();
  }

  ngOnInit() {
    super.init(this.field, this.controlElement);
  }

  modelChanged(value: string) {
    if (this.field.disabled) {
      return;
    }

    this.field.value = value;

    // propagate value into form control using control value accessor interface
    if (this.propagateChange) {
      this.propagateChange(this.field.value);
    }
  }
}
