import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { lastValueFrom } from 'rxjs';

import {
  MintAuthService,
  MintLogger,
  MintStorageService,
  MintStorageType,
} from '@bryllyant/mint-ngx';
import {
  ADMIN_LOGGED_IN_AS_USER_KEY,
  PolicyConsentService,
  UserService,
} from '@fynvana/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AlertUtil } from '../utils';

const logger = MintLogger.getLogger('ConsentGuard');

@Injectable({ providedIn: 'root' })
export class ConsentGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: MintAuthService,
    private readonly policyConsentService: PolicyConsentService,
    private readonly notificationService: NzNotificationService,
    private readonly userService: UserService,
    private readonly localStorage: MintStorageService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const bypassPolicyConsent = await lastValueFrom(
      this.localStorage.getItem(
        ADMIN_LOGGED_IN_AS_USER_KEY,
        MintStorageType.Local,
      ),
    );

    if (bypassPolicyConsent) {
      return true;
    }

    const user = await this.authService.me();
    const hasValidConsents =
      await this.policyConsentService.hasPrivacyAndTermsPoliciesAccepted(
        user,
        false,
      );

    if (!hasValidConsents) {
      await this.userService.logout();
      AlertUtil.notify(this.notificationService, {
        error:
          'One of your consent agreements is invalid or out of date. Please login again.',
      });
      await this.router.navigateByUrl('/');
      return false;
    }

    return true;
  }
}
