import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { BusinessEmailModel } from './business-email.model';

const logger = MintLogger.getLogger('BusinessEmailService');

@Injectable({ providedIn: 'root' })
export class BusinessEmailService extends MintService<BusinessEmailModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      BusinessEmailModel,
      BusinessEmailModel._mintResourceName,
    );
  }
}
