import {
  MintAddressModel,
  MintEntityMeta,
  MintMediaModel,
  MintModel,
  MintObject,
  MintProperty,
  MintResource,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsObject, IsString } from 'class-validator';
import { TaxIdType } from '../../main.types';
import { IndividualModel } from '../individual.model';
import {
  IndividualIdentificationType,
  IndividualVerificationStatus,
  IndividualVerificationType,
} from './individual-verification.types';

@MintResource('/individual-verifications')
export class IndividualVerificationModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => IndividualModel)
  readonly individual: IndividualModel;

  @MintProperty()
  @IsEnum(IndividualIdentificationType)
  readonly signerIdentificationType: IndividualIdentificationType;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly signerIdentification: MintMediaModel;

  @MintProperty()
  @IsObject()
  readonly signerIdentificationDetails: MintObject;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly signerAuthorization: MintMediaModel;

  @MintProperty()
  @IsString()
  readonly signerAuthorizationType: string;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly signerSelfie: MintMediaModel;

  @MintProperty()
  @IsBoolean()
  readonly signerSelfieMatch: boolean;

  @MintProperty()
  @IsObject()
  readonly signerComparisonDetails: MintObject;

  @MintProperty()
  @IsEnum(IndividualVerificationType)
  readonly individualVerification1Type: IndividualVerificationType;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly individualVerification1: MintMediaModel;

  @MintProperty()
  @IsObject()
  readonly individualVerification1Details: MintObject;

  @MintProperty()
  @IsEnum(IndividualVerificationType)
  readonly individualVerification2Type: IndividualVerificationType;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly individualVerification2: MintMediaModel;

  @MintProperty()
  @IsObject()
  readonly individualVerification2Details: MintObject;

  @MintProperty()
  @IsEnum(IndividualVerificationType)
  readonly individualVerification3Type: IndividualVerificationType;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly individualVerification3: MintMediaModel;

  @MintProperty()
  @IsObject()
  readonly individualVerification3Details: MintObject;

  @MintProperty()
  @IsString()
  readonly individualLegalName: string;

  @MintProperty()
  @Type(() => MintAddressModel)
  readonly individualAddress: MintAddressModel;

  @MintProperty()
  @IsString()
  readonly individualTaxId: string;

  @MintProperty()
  @IsEnum(TaxIdType)
  readonly individualTaxIdType: TaxIdType;

  @MintProperty()
  @Type(() => Date)
  readonly individualBirthDate: Date;

  @MintProperty()
  @IsEnum(IndividualVerificationStatus)
  readonly individualLegalNameStatus: IndividualVerificationStatus;

  @MintProperty()
  @IsEnum(IndividualVerificationStatus)
  readonly individualAddressStatus: IndividualVerificationStatus;

  @MintProperty()
  @IsEnum(IndividualVerificationStatus)
  readonly individualTaxIdStatus: IndividualVerificationStatus;

  @MintProperty()
  @IsEnum(IndividualVerificationStatus)
  readonly individualBirthDateStatus: IndividualVerificationStatus;

  @MintProperty()
  @IsBoolean()
  readonly isVerified: boolean;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly verifiedBy: MintUserModel;

  @MintProperty()
  @Type(() => Date)
  readonly verifiedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<IndividualVerificationModel>) {
    super(model);
  }
}
