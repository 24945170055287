import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { IndividualVerificationModel } from './individual-verification.model';

const logger = MintLogger.getLogger('IndividualVerificationService');

@Injectable({ providedIn: 'root' })
export class IndividualVerificationService extends MintService<IndividualVerificationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      IndividualVerificationModel,
      IndividualVerificationModel._mintResourceName,
    );
  }
}
