import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerService } from '../taxpayer.service';
import { TaxpayerAuthorizationDto } from './taxpayer-authorization.dto';
import { TaxpayerAuthorizationModel } from './taxpayer-authorization.model';
import { TaxpayerAndAuthorizationsResponse } from './taxpayer-authorization.types';

const logger = MintLogger.getLogger('TaxpayerAuthorizationService');

@Injectable({ providedIn: 'root' })
export class TaxpayerAuthorizationService extends MintService<TaxpayerAuthorizationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly taxpayerService: TaxpayerService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerAuthorizationModel,
      TaxpayerAuthorizationModel._mintResourceName,
    );
  }

  async grantAuthorizations(
    dto: TaxpayerAuthorizationDto,
  ): Promise<TaxpayerAndAuthorizationsResponse[]> {
    const result = await this.httpService.post<
      TaxpayerAndAuthorizationsResponse[]
    >(`${this.urlPath}/grant`, dto);

    return await Promise.all(
      result.map((item) => {
        return {
          taxpayer: this.taxpayerService.toModel(item.taxpayer),
          authorizations: this.toModelArray(item.authorizations),
        };
      }),
    );
  }
}
