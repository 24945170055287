import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisableAutofillDirective } from './directives/disable-autofill.directive';
import { OffClickDirective } from './directives/off-click.directive';
import {
  BigNumberToNumberPipe,
  CamelizePipe,
  FileSizePipe,
  ReadTimePipe,
  UserFirstLastPipe,
} from './pipes';

const imports = [CommonModule];
const declarations = [
  CamelizePipe,
  FileSizePipe,
  BigNumberToNumberPipe,
  OffClickDirective,
  DisableAutofillDirective,
  ReadTimePipe,
  UserFirstLastPipe,
];

@NgModule({
  imports,
  declarations,
  exports: [...declarations],
})
export class CommonLibModule {}
