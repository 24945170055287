import { MintError } from '@bryllyant/mint-ngx';

export enum RelationshipType {
  Spouse = 'spouse',
  DomesticPartner = 'domestic-partner',
  Parent = 'parent',
  Guardian = 'guardian',
  Child = 'child',
  Sibling = 'sibling',
  Employer = 'employer',
  Employee = 'employee',
  Vendor = 'vendor',
  Customer = 'customer',
  BusinessPartner = 'business-partner',
  Owner = 'owner',
  OwnedEntity = 'owned-entity',
  Other = 'other',
}

export const getReciprocalRelation = (type: RelationshipType) => {
  switch (type) {
    case RelationshipType.Parent:
    case RelationshipType.Guardian:
      return RelationshipType.Child;

    case RelationshipType.Child:
      return RelationshipType.Parent;

    case RelationshipType.Employer:
      return RelationshipType.Employee;

    case RelationshipType.Employee:
      return RelationshipType.Employer;

    case RelationshipType.Vendor:
      return RelationshipType.Customer;

    case RelationshipType.Customer:
      return RelationshipType.Vendor;

    case RelationshipType.Owner:
      return RelationshipType.OwnedEntity;

    case RelationshipType.OwnedEntity:
      return RelationshipType.Owner;

    // spouse, domestic-partner, sibling, business-partner
    case RelationshipType.Spouse:
    case RelationshipType.DomesticPartner:
    case RelationshipType.Sibling:
    case RelationshipType.BusinessPartner:
    case RelationshipType.Other:
      return type;

    default:
      throw new MintError('Invalid type: ' + (type as string));
  }
};
