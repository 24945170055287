<main
  class="avatar-profile-menu-container"
  *ngIf="isVisible && user"
  [@fadeInOut]
>
  <div class="avatar-profile-menu-header">
    <div class="avatar-circle">
      <fynvana-icon
        icon="user"
        color="#fff"
        [height]="32"
        [width]="32"
      ></fynvana-icon>
      <!-- <div class="avatar-notifications" *ngIf="notifications">
        {{ numberOfNotifications }}
      </div> -->
    </div>

    <div class="avatar-profile-info">
      <div class="profile-name">
        {{ user.fullName }}
      </div>
      <div class="profile-email">
        {{ user.email || '' }}
      </div>
    </div>
  </div>

  <hr />
  <div class="avatar-profile-menu-nav">
    <nav>
      <ng-container *ngTemplateOutlet="navMenu"></ng-container>
    </nav>
  </div>
</main>

<!-- Start Menu Template -->
<ng-template #navMenu>
  <ul nz-menu nzMode="inline">
    <ng-container *ngFor="let menuItem of avatarMenuItems; let i = index">
      <!-- Start Menu Item -->
      <li
        *ngIf="!menuItem.children"
        nz-menu-item
        routerLinkActive="active-link"
        #rla="routerLinkActive"
        [nzSelected]="rla.isActive"
        [routerLink]="menuItem.routerLink"
        (click)="
          navigate(menuItem); subMenusOpenToggle(); toggleAvatarMenu.emit()
        "
        class="single-nav-item top-level-nav-item"
      >
        <fynvana-icon [icon]="menuItem.icon || ''"></fynvana-icon>
        <a>
          {{ menuItem.title }}
        </a>
      </li>
      <!-- End Menu Item -->

      <!-- Start Sub Menu -->
      <ng-template #subMenuTitle>
        <fynvana-icon [icon]="menuItem.icon || ''"></fynvana-icon>
        <div class="submenu-title">
          {{ menuItem.title }}
        </div>
      </ng-template>

      <li
        nz-submenu
        *ngIf="menuItem.children"
        [nzTitle]="subMenuTitle"
        [nzIcon]="'down'"
        [(nzOpen)]="subMenuOpenMap[i]"
        (nzOpenChange)="subMenusOpenToggle(i)"
        class="top-level-nav-item"
        [ngClass]="{
          'active-parent-link': determineIsParentMenuActive(menuItem)
        }"
      >
        <ul>
          <li nz-menu-group>
            <ul>
              <ng-container *ngFor="let child of menuItem.children">
                <li
                  nz-menu-item
                  routerLinkActive="active-link"
                  #rla="routerLinkActive"
                  [nzSelected]="rla.isActive"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [routerLink]="child.routerLink"
                  (click)="navigate(child); toggleAvatarMenu.emit()"
                >
                  <a class="child-link">
                    {{ child.title }}
                  </a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </li>
      <!-- End Sub Menu -->
    </ng-container>
  </ul>
</ng-template>
<!-- End Menu Template -->
