import { ComponentFixture } from '@angular/core/testing';
import { MintMathUtil, MintUidUtil } from '@bryllyant/mint-ngx';

export enum MockPropStrategy {
  Email = 'email',
  MobileNumber = 'mobileNumber',
  String = 'string',
  StringArray = 'string[]',
  Number = 'number',
  NumberArray = 'number[]',
  Boolean = 'boolean',
}

export class TestUtil {
  static randomTs() {
    const ts = (start: Date, end: Date) => {
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime()),
      );
    };

    return ts(new Date(2020, 0, 1), new Date());
  }

  static randomStr(): string {
    return MintUidUtil.uid();
  }

  static randomEmail(): string {
    return `${this.randomStr()}@${this.randomStr()}.com`;
  }

  static randomMobileNumber(): string {
    return `${this.randomInt(100, 999)}-${this.randomInt(
      100,
      999,
    )}-${this.randomInt(1000, 9999)}`;
  }

  static randomStrArray(length?: number): string[] {
    const _length = length ?? this.randomInt();
    const randomStrs: string[] = [];

    for (let i = 0; i < _length; i++) {
      randomStrs.push(this.randomStr());
    }

    return randomStrs;
  }

  static randomInt(min = 1, max = 100): number {
    return MintMathUtil.randomInt(min, max);
  }

  static randomIntArray(length?: number): number[] {
    const _length = length ?? this.randomInt();
    const randomInts: number[] = [];

    for (let i = 0; i < _length; i++) {
      randomInts.push(this.randomInt());
    }

    return randomInts;
  }

  static randomBool(): boolean {
    return this.randomInt(0, 100) >= 50 ? true : false;
  }

  static getFormErrors(fixture: ComponentFixture<any>): string[] {
    const formFieldErrorEls =
      fixture.debugElement.nativeElement.querySelectorAll(
        '.mint-form-field-help p',
      );

    const errors = [];

    for (const formFieldEl of formFieldErrorEls) {
      errors.push(formFieldEl.innerHTML);
    }

    return errors;
  }
}
