import { FormGroup } from '@angular/forms';
import { MintFormController, MintLogger } from '@bryllyant/mint-ngx';
import { SubmittingOptions } from '../types';

import { BaseController } from './base.controller';
import { BaseService } from './base.service';

const logger = MintLogger.getLogger('FormController');

export abstract class FormController<MODEL = any> extends BaseController {
  isDisabled = false;
  isSubmitting = false;

  form: MintFormController & FormGroup<any>;
  formFields: Record<string, any>;

  protected constructor(public baseService: BaseService) {
    super(baseService);
  }

  /**
   * Sets isSubmitting while passed in code block runs
   * then notifies on resolve or reject. (See handleLoad())
   */
  handleSubmit(
    fn: any,
    options: SubmittingOptions = {},
    form?: MintFormController,
  ) {
    if (!options.disableStatusAlertReset) {
      this.statusAlert = null;
    }

    this.isSubmitting = true;

    const _form = form ?? this.form;

    this.handleLoad(async () => {
      try {
        if (_form) {
          await _form.triggerFormValidation();

          if (!_form.valid) {
            this.isSubmitting = false;
            return Promise.reject(this.messages.requiredFields);
          }
        }

        await fn();

        if (!options.disableFormReset && _form) {
          _form.resetForm();
        }

        this.isSubmitting = false;
      } catch (err) {
        this.isSubmitting = false;
        return Promise.reject(err);
      }
    }, options);
  }
}
