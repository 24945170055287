import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

// Regexes

export class ValidatorUtil {
  static EMPTY_WHITESPACE_REGEX = /^(?!\s+$).+/;
  static WHITESPACE_REGEX = /^\S*$/;
  static ALPHABETIC_REGEX = /^[A-Za-z]+$/;
  static ALPHANUM_REGEX = /^((?![_[\]^\\])[a-z\d])+$/i;
  static NUM_REGEX = /^\d+$/i;
  static FOUR_DIGITS_REGEX = /^[0-9]{4}$/;
  static FIVE_DIGITS_REGEX = /^[0-9]{5}$/;
  static EMAIL_REGEX = /.+\@.+/;
  static CURLY_BRACE_REGEX = /[{}]/g;
  static URL_REGEX =
    /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/;
  static CITY_REGEX = /^[A-Za-z\s\-']+$/i;
  static STREET_ADDRESS_REGEX =
    /^([A-Za-z0-9.]+\s?)+\d*\s+([A-Za-z0-9.]+\s?)+$/i;

  /**
   * Validates first and last name.
   * Allows spaces, ', -, ., accented/non-accented chars, numbers.
   * First char must be a letter.
   */
  static NAME_REGEX = /^[a-zÀ-ú]((?![_[\]^\\])[A-zÀ-ú\d\-'.\s])*$/i;

  // similar to NAME_REGEX but remove restriction on first character (e.g. 7-Eleven)
  static BUSINESS_NAME_REGEX = /((?![_[\]^\\])[A-zÀ-ú\d\-'.\s])*$/i;

  /**
   * Custom form validator, validates a regex pattern and
   * returns an error if value does not pass check.
   *
   * EXAMPLE USAGE:
   * validators: [ValidatorUtil.regex(numeric, { numeric: '' })]
   *
   * @param regex Regex to be tested.
   * @param error Key Value pair for returned error object.
   * @returns If error occurs, null if not.
   */
  static regex(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value as string);
      return valid ? null : error;
    };
  }

  static required(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !control.value ? error : null;
    };
  }

  /**
   * Validates passwords.
   *
   * Validates alphanumeric, no whitespace, and a selection of special characters.
   * Doesn't allow the same character to be repeated more than 3 times.
   *
   * EXAMPLE USAGE:
   * validators: [ValidatorUtil.password()]
   *
   * @returns Key/Value pair if error occurs, null if not.
   */
  static password(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const val: string = control.value;

      if (!val) {
        return null;
      }

      const whitespace = /^\S*$/;
      const whiteSpaceValid = whitespace.test(val);

      // At least one uppercase character, one special, one lowercase
      const charRequirements =
        /(?=[A-Za-z0-9!@#$%^&*()\-_+=[\]\\{}\:\;'"?\/\.><,`~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+=[\]\\{}\:\;'"?\/\.><,`~]).*$/;
      const charRequirementsValid = charRequirements.test(val);

      if (!whiteSpaceValid) {
        return {
          noWhiteSpace: { error: 'No whitespace allowed' },
        };
      }

      for (let i = 0; i < val.length; i++) {
        if (val[i] === val[i - 1] && val[i - 1] === val[i - 2] && val[i - 2]) {
          return {
            charRepetition: { error: 'No 3 of the same character repeated' },
          };
        }
      }

      if (!charRequirementsValid) {
        return {
          charRequirements: {
            error: `
            At least one uppercase <br/>
            At least one lowercase <br/>
            At least one special character`,
          },
        };
      }

      return null;
    };
  }

  /**
   * Returns a form error if supplied strings do not match
   */
  static comparePassword(
    currentVal: string,
    matchingVal: string,
  ): { [key: string]: any } | null {
    if (!currentVal || !matchingVal) {
      return null;
    }

    if (currentVal !== matchingVal) {
      return {
        notMatched: { error: 'Passwords do not match' },
      };
    }

    return null;
  }

  /**
   * Sets form errors appropriately when values do or don't match
   */
  static setMatchingInputErrs(
    control: UntypedFormControl,
    matchingControl: AbstractControl,
    err: { [key: string]: any } | null,
  ) {
    if (err) {
      control.setErrors({
        ...control.errors,
        ...err,
      });

      matchingControl.setErrors({
        ...matchingControl.errors,
        ...err,
      });
    } else if (matchingControl.errors) {
      delete matchingControl.errors['notMatched'];

      matchingControl.setErrors({
        ...matchingControl?.errors,
      });

      if (!Object.keys(matchingControl.errors)?.length) {
        matchingControl.updateValueAndValidity();
      }
    }
  }
}
