import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { TranscriptModel } from './transcript.model';

const logger = MintLogger.getLogger('TranscriptService');

@Injectable({ providedIn: 'root' })
export class TranscriptService extends MintService<TranscriptModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      TranscriptModel,
      TranscriptModel._mintResourceName,
    );
  }
}
