import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintPaymentModel,
  MintService,
} from '@bryllyant/mint-ngx';

const logger = MintLogger.getLogger('PaymentService');

@Injectable({ providedIn: 'root' })
export class PaymentService extends MintService<MintPaymentModel> {
  constructor(
    protected readonly httpService: MintHttpService,
    protected readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      MintPaymentModel,
      MintPaymentModel._mintResourceName,
    );
  }

  async refreshReceipt(
    payment: MintPaymentModel | string,
  ): Promise<MintPaymentModel> {
    const uid = typeof payment === 'string' ? payment : payment.uid;
    return this.httpService.patch(`${this.urlPath}/${uid}/refresh-receipt`, {});
  }
}
