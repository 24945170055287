export const _default = {
  app: {
    baseHref: '/',
    version: '0.0.0',
    build: '0000',
  },

  websiteBaseUrl: 'http://localhost:4201/',
  adminBaseUrl: 'http://localhost:4202/',
  developerBaseUrl: 'http://localhost:4203/',
  docsBaseUrl: 'http://localhost:4204/',
  clientBaseUrl: 'http://localhost:4205/',
  taxpayerBaseUrl: 'http://localhost:4206/',

  api: {
    baseUrl: 'http://localhost:3000/',
    clientIdHeaderName: 'fynvana-client-id',
    clientId: 'e5ef09b672b04b15a74d1d64542087e4',
    propertyNameCase: 'snake',
    apiVersion: '1.0.0',
    service: {
      auth: 'auth',
      user: 'users',
      geocoder: 'geocoder',
      helpdesk: {
        message: 'helpdesk/messages',
      },
      userRole: 'user-roles',
    },
  },

  seo: {
    title: 'Fynvana',
    description:
      'Our mission is to demystify the IRS, streamline access to your tax data and unlock unparalleled financial freedom.',
    author: 'Fynvana',
    twitterUsername: 'Fynvana',
  },

  logger: {
    level: 'all',
  },

  analytics: {
    google: {
      trackingId: 'G-C16LNKE1CW',
    },
  },

  auth: {},
  ui: {},
  services: {},

  components: {
    auth: {
      login: {
        route: '/auth/login',
        redirect: '/dashboard',
        socialLoginEnabled: false,
      },
      logout: {
        route: '/auth/logout',
        redirect: '/',
        redirectDelay: 500,
      },
      resetPassword: {
        route: '/auth/reset-password',
        redirect: '/auth/change-password',
        redirectDelay: 1000,
      },
      register: {
        route: '/auth/signup',
        redirect: '/auth/signup/register-mobile',
        acceptTerms: true,
        socialLoginEnabled: false,
      },
      registerMobile: {
        route: '/auth/register-mobile',
        redirect: '/dashboard',
        redirectDelay: 500,
      },
      verification: {
        route: '/auth/verification',
      },
    },
  },
};
