import { MintUserModel } from '@bryllyant/mint-ngx';
import { plainToInstance, Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class IrsFormEventNote {
  // can only be set by admin.  will default to current user if not set
  @Type(() => MintUserModel)
  @IsOptional()
  readonly user: Pick<MintUserModel, 'uid'>;

  @IsString()
  @IsNotEmpty()
  readonly note: string;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt: Date;

  constructor(model?: Partial<IrsFormEventNote>) {
    if (model) {
      Object.assign(this, plainToInstance(IrsFormEventNote, model));
    }
  }
}
