import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { AgentModel } from './agent.model';

const logger = MintLogger.getLogger('AgentService');

@Injectable({ providedIn: 'root' })
export class AgentService extends MintService<AgentModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(httpService, cacheService, AgentModel, AgentModel._mintResourceName);
  }
}
