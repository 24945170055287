import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

import { MintLogger, MintStorageType } from '@bryllyant/mint-ngx';

import {
  AppIdentifier,
  BaseService,
  COLOR_THEME_KEY,
  ColorTheme,
  Config,
  MenuItem,
  ViewportSize,
} from '@fynvana/common';
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

const logger = new MintLogger('MainLayoutComponent');

@Component({
  selector: 'fynvana-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements AfterViewInit, OnDestroy {
  // Main / Shared
  @Input() app: AppIdentifier;
  @Input() wrapperClasses = '';
  @Input() socialMenuItems: MenuItem[] = [];
  @Input() homeMenuItem: MenuItem = { routerLink: '/' };
  @Input() contactMenuItem: MenuItem;

  // Header
  @Input() headerButtonShown: MenuItem | null;
  @Input() headerButtonPosition: 'left' | 'right' = 'right';
  @Input() headerMenuItems: MenuItem[] = [];
  @Input() avatarMenuItems: MenuItem[];
  @Input() hideHeaderMenu = false;
  @Input() enableHeaderSearch = false;
  @Input() enableHeaderThemeToggle = false;
  @Input() enableHeaderContactUs = false;

  // Sidebar Navigation
  @Input() sidebarNavTitle: string | null;
  @Input() sidebarMenuItems: MenuItem[] | null;

  // Footer
  @Input() enableMinimalFooter = false;
  @Input() footerMenuItems: MenuItem[] = [];

  // Color Theme
  @Input() colorTheme: ColorTheme = ColorTheme.Light;

  // Loader
  loadingListener: Subscription;
  loadingProcesses = 0;

  windowViewChanged = new Subject();

  @HostListener('window:resize', ['$event'])
  handleWindowResize() {
    this.windowViewChanged.next(null);
  }

  @HostListener('window:scroll', ['$event'])
  handleWindowScroll() {
    this.windowViewChanged.next(null);
  }

  constructor(public baseService: BaseService) {
    this.loadingListener = this.baseService.toggleLoading.subscribe(
      (isLoading) => {
        isLoading ? this.loadingProcesses++ : this.loadingProcesses--;

        if (this.loadingProcesses < 0) {
          this.loadingProcesses = 0;
        }
      },
    );
  }

  async ngAfterViewInit() {
    const colorTheme = await lastValueFrom(
      this.baseService.localStorage.getItem(
        COLOR_THEME_KEY,
        MintStorageType.Local,
      ),
    );
    await this.baseService.setColorTheme(colorTheme as ColorTheme);

    this.windowViewChanged
      .asObservable()
      .pipe(throttleTime(100, undefined, { trailing: true }))
      .subscribe(() => this.setLayoutState());

    this.windowViewChanged.next(null);
  }

  ngOnDestroy() {
    this.windowViewChanged.unsubscribe();
    this.loadingListener.unsubscribe();
  }

  setLayoutState() {
    const { xl, lg, md, sm } = Config.VIEWPORT_BREAKPOINT;
    const testBreakpoint = (ceiling: number, floor: number): boolean => {
      if (window.innerWidth <= ceiling && window.innerWidth > floor) {
        return true;
      }
      return false;
    };

    if (window.innerWidth > xl) {
      this.baseService.setLayoutViewport(ViewportSize.Full);
    } else if (testBreakpoint(xl, lg)) {
      this.baseService.setLayoutViewport(ViewportSize.Xl);
    } else if (testBreakpoint(lg, md)) {
      this.baseService.setLayoutViewport(ViewportSize.Lg);
    } else if (testBreakpoint(md, sm)) {
      this.baseService.setLayoutViewport(ViewportSize.Md);
    } else {
      this.baseService.setLayoutViewport(ViewportSize.Sm);
    }

    if (window.innerWidth <= Config.MOBILE_BREAKPOINT) {
      this.baseService.setLayoutIsMobile(true);
    } else {
      this.baseService.layoutState.isMobileMenuOpen = false;
      this.baseService.setLayoutIsMobile(false);
    }

    // If page has been scrolled past full header, load minimal header,
    // and don't restore full header until scrollY is 0 again.
    if (
      window.scrollY > 120 ||
      (this.baseService.layoutState.isScrolled && window.scrollY > 0)
    ) {
      this.baseService.setLayoutScrolled(true);
    } else {
      this.baseService.setLayoutScrolled(false);
    }
  }

  /** Only allow setting dark theme on portals that it's set up for */
  determineColorThemeClass(): ColorTheme | undefined {
    switch (this.app) {
      case AppIdentifier.Docs:
        return this.baseService.layoutState.colorTheme;
      default:
        return ColorTheme.Light;
    }
  }
}
