import {
  MintAddressModel,
  MintEmailType,
  MintEntityMeta,
  MintModel,
  MintPhoneType,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsEmail, IsEnum, IsPhoneNumber, IsString } from 'class-validator';
import { IndividualModel } from '../individual/individual.model';
import { TaxIdType } from '../main.types';
import { BusinessOwnership, BusinessType } from './business.types';

@MintResource('/businesses')
export class BusinessModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @IsEnum(BusinessType)
  readonly type: BusinessType;

  @MintProperty()
  @IsEnum(BusinessOwnership)
  readonly ownership: BusinessOwnership;

  @MintProperty()
  @Type(() => MintAddressModel)
  readonly address: MintAddressModel;

  @MintProperty()
  @Type(() => IndividualModel)
  readonly signer: IndividualModel;

  @MintProperty()
  @IsString()
  readonly signerTitle: string;

  @MintProperty()
  @IsString()
  readonly legalName: string;

  @MintProperty()
  @IsString()
  readonly dbaName: string;

  @MintProperty()
  @IsString()
  readonly slug: string;

  @MintProperty()
  @IsString()
  readonly taxId: string;

  @MintProperty()
  @IsEnum(TaxIdType)
  readonly taxIdType: TaxIdType;

  @MintProperty()
  @IsEmail({ require_tld: false })
  readonly email: string;

  @MintProperty()
  @IsEnum(MintEmailType)
  readonly emailType: MintEmailType;

  @MintProperty()
  @IsPhoneNumber()
  readonly phoneNumber: string;

  @MintProperty()
  @IsString()
  readonly phoneExtension: string;

  @MintProperty()
  @IsEnum(MintPhoneType)
  readonly phoneType: MintPhoneType;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;
  constructor(model?: Partial<BusinessModel>) {
    super(model);
  }
}
