import { Type } from 'class-transformer';
import { IsDefined, IsEnum, IsObject, IsOptional } from 'class-validator';
import { TaxpayerAuthorizationModel } from '../taxpayer-authorization.model';
import {
  TaxpayerAuthorizationEvent,
  TaxpayerAuthorizationEventType,
} from './taxpayer-authorization-event.types';

export class TaxpayerAuthorizationEventDto {
  @Type(() => TaxpayerAuthorizationModel)
  @IsDefined()
  readonly authorization: TaxpayerAuthorizationModel;

  @IsEnum(TaxpayerAuthorizationEventType)
  @IsDefined()
  readonly type: TaxpayerAuthorizationEventType;

  @IsObject()
  @IsOptional()
  readonly event?: TaxpayerAuthorizationEvent;

  @Type(() => Date)
  @IsOptional()
  readonly eventAt?: Date;
}
