import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService, MenuItem } from '@fynvana/common';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

const logger = MintLogger.getLogger('SectionHeaderComponent');

@Component({
  selector: 'fynvana-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
})
export class SectionHeaderComponent
  extends BaseController
  implements OnInit, OnDestroy, OnChanges
{
  @Input() wrapperClasses: string;
  @Input() breadcrumbs: MenuItem[];
  @Input() pageTitle: string;
  @Input() includesBackNav: boolean;
  @Input() truncateTitleLength = 18;
  @Input() btn: MenuItem;
  @Input() secondBtn: MenuItem;
  @Input() link: MenuItem;
  @Input() navTabs: MenuItem[];

  @Output() btnClicked = new EventEmitter<null>();
  @Output() secondBtnClicked = new EventEmitter<null>();
  @Output() linkClicked = new EventEmitter<null>();

  isEllipsisActive = false;
  resizeSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(
    public baseService: BaseService,
    public location: Location,
  ) {
    super(baseService);
  }

  ngOnInit() {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(500))
      .subscribe(() => {
        logger.debug('enters resize sub');
        this.displayEllipsis();
      });
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.pageTitle) {
      this.displayEllipsis();
    }
  }

  displayEllipsis() {
    setTimeout(() => {
      const element = document.getElementById('title')?.getBoundingClientRect();
      this.isEllipsisActive = element?.width === 700;
    }, 0);
  }
}
