import { MintFormFieldOption } from '@bryllyant/mint-ngx';
import { BusinessType } from '../business/business.types';
import { IrsFormSignerTitle } from './irs-form.types';

export class IrsFormSignerTitleMap {
  private static titleMap = new Map<IrsFormSignerTitle, BusinessType[]>();
  private static isInitialized = false;

  static _init(): void {
    if (this.isInitialized) return;

    this.titleMap.set(IrsFormSignerTitle.Partner, [
      BusinessType.GeneralPartnership,
      // BusinessType.InvestmentFund,
    ]);

    this.titleMap.set(IrsFormSignerTitle.LimitedPartner, [
      BusinessType.LimitedPartnership,
    ]);

    this.titleMap.set(IrsFormSignerTitle.Director, [BusinessType.NonProfit]);

    this.titleMap.set(IrsFormSignerTitle.CAO, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.CEO, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
      // BusinessType.InvestmentFund,
    ]);

    this.titleMap.set(IrsFormSignerTitle.COO, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.CFO, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
      // BusinessType.InvestmentFund,
    ]);

    this.titleMap.set(IrsFormSignerTitle.President, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
      // BusinessType.NativeAmericanTribe,
    ]);

    this.titleMap.set(IrsFormSignerTitle.VicePresident, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.Treasurer, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.AssistantTreasurer, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    // this.titleMap.set(IrsFormSignerTitle.OnePercentShareholder, [
    //   BusinessType.C_Corporation,
    //   BusinessType.S_Corporation,
    //   BusinessType.LLC,
    // ]);

    this.titleMap.set(IrsFormSignerTitle.Shareholder, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.Controller, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.ManagingMember, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
    ]);

    this.titleMap.set(IrsFormSignerTitle.Owner, [
      BusinessType.C_Corporation,
      BusinessType.S_Corporation,
      BusinessType.LLC,
      BusinessType.SoleProprietorship,
      BusinessType.GeneralPartnership,
      BusinessType.LimitedPartnership,
    ]);

    this.titleMap.set(IrsFormSignerTitle.SoleProprietor, [
      BusinessType.SoleProprietorship,
    ]);

    this.titleMap.set(IrsFormSignerTitle.Executor, [BusinessType.Trust]);
    this.titleMap.set(IrsFormSignerTitle.Beneficiary, [BusinessType.Trust]);
    this.titleMap.set(IrsFormSignerTitle.Trustee, [BusinessType.Trust]);
    this.titleMap.set(IrsFormSignerTitle.Administrator, [BusinessType.Trust]);

    this.isInitialized = true;
  }

  static isValid(
    businessType: BusinessType,
    title: IrsFormSignerTitle,
  ): boolean {
    const types = this.titleMap.get(title) ?? [];
    return types.includes(businessType);
  }

  static getBusinessTypes(title: IrsFormSignerTitle): BusinessType[] {
    return this.titleMap.get(title) ?? [];
  }

  static getTitlesForBusinessType(
    businessType: BusinessType,
  ): IrsFormSignerTitle[] {
    const result: IrsFormSignerTitle[] = [];

    for (const key of this.titleMap.keys()) {
      const types = this.titleMap.get(key);
      if (types?.includes(businessType)) {
        result.push(key);
      }
    }
    return result;
  }
}

IrsFormSignerTitleMap._init();

export const businessTypeTitleOptions = (
  type: BusinessType,
): MintFormFieldOption[] => {
  const valueToNameValue = (value: IrsFormSignerTitle) => {
    return { name: value, value };
  };

  switch (type) {
    case BusinessType.GeneralPartnership:
      return [
        valueToNameValue(IrsFormSignerTitle.Partner),
        valueToNameValue(IrsFormSignerTitle.Owner),
      ];

    case BusinessType.LimitedPartnership:
      return [
        valueToNameValue(IrsFormSignerTitle.LimitedPartner),
        valueToNameValue(IrsFormSignerTitle.Owner),
      ];

    case BusinessType.NonProfit:
      return [valueToNameValue(IrsFormSignerTitle.Director)];

    case BusinessType.C_Corporation:
    case BusinessType.S_Corporation:
    case BusinessType.LLC:
      return [
        valueToNameValue(IrsFormSignerTitle.CAO),
        valueToNameValue(IrsFormSignerTitle.CEO),
        valueToNameValue(IrsFormSignerTitle.COO),
        valueToNameValue(IrsFormSignerTitle.CFO),
        valueToNameValue(IrsFormSignerTitle.President),
        valueToNameValue(IrsFormSignerTitle.VicePresident),
        valueToNameValue(IrsFormSignerTitle.Treasurer),
        valueToNameValue(IrsFormSignerTitle.AssistantTreasurer),
        // valueToNameValue(IrsFormSignerTitle.OnePercentShareholder),
        valueToNameValue(IrsFormSignerTitle.Shareholder),
        valueToNameValue(IrsFormSignerTitle.Controller),
        valueToNameValue(IrsFormSignerTitle.ManagingMember),
        valueToNameValue(IrsFormSignerTitle.Owner),
      ];

    case BusinessType.SoleProprietorship:
      return [
        valueToNameValue(IrsFormSignerTitle.Owner),
        valueToNameValue(IrsFormSignerTitle.SoleProprietor),
      ];

    case BusinessType.Trust:
      return [
        valueToNameValue(IrsFormSignerTitle.Executor),
        valueToNameValue(IrsFormSignerTitle.Beneficiary),
        valueToNameValue(IrsFormSignerTitle.Trustee),
        valueToNameValue(IrsFormSignerTitle.Administrator),
      ];
  }

  return [];
};
