import {
  MintEntityMeta,
  MintModel,
  MintObject,
  MintProperty,
  MintResource,
  MintTag,
  mintTagModelTransformer,
} from '@bryllyant/mint-ngx';
import { Transform, Type } from 'class-transformer';
import { IsArray, IsEnum, IsObject, IsString } from 'class-validator';
import { ClientModel } from '../client/client.model';
import { ProjectStatus } from './project.types';

@MintResource('/projects')
export class ProjectModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => ClientModel)
  readonly client: ClientModel;

  @MintProperty()
  @IsString()
  readonly name: string;

  @MintProperty()
  @IsString()
  readonly slug: string;

  @MintProperty()
  @IsString()
  readonly referenceNumber: string;

  @MintProperty()
  @IsEnum(ProjectStatus)
  readonly status: ProjectStatus;

  @MintProperty()
  @IsArray()
  @Transform(mintTagModelTransformer)
  readonly tags: MintTag[];

  @MintProperty()
  @IsObject()
  readonly context: MintObject;

  @MintProperty()
  @IsString()
  readonly description: string;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<ProjectModel>) {
    super(model);
  }
}
