import {
  MintEntityMeta,
  MintMediaModel,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsString } from 'class-validator';
import { IrsFormModel } from '../irs-form.model';
import { IrsFormEventNote } from './irs-form-event-note';
import { IrsFormEvent, IrsFormEventType } from './irs-form-event.types';

@MintResource('/irs-form-events')
export class IrsFormEventModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => IrsFormModel)
  readonly irsForm: IrsFormModel;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly file: MintMediaModel;

  @MintProperty()
  @IsEnum(IrsFormEventType)
  readonly type: IrsFormEventType;

  @MintProperty()
  readonly event: IrsFormEvent | IrsFormEvent[];

  @MintProperty()
  @Type(() => Date)
  readonly eventAt: Date;

  @MintProperty()
  @Type(() => IrsFormEventNote)
  @IsArray()
  readonly notes: IrsFormEventNote[];

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<IrsFormEventModel>) {
    super(model);
  }
}
