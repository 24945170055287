import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintInvitationModel,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';

const logger = MintLogger.getLogger('InvitationService');

@Injectable({ providedIn: 'root' })
export class InvitationService extends MintService<MintInvitationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      MintInvitationModel,
      MintInvitationModel._mintResourceName,
    );
  }

  async getByInvitationCode(
    invitationCode: string,
  ): Promise<MintInvitationModel> {
    return this.httpService.get(`${this.urlPath}/${invitationCode}`);
  }
}
