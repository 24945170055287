export enum ClientType {
  System = 'system',
  Partner = 'partner',
  Customer = 'customer',
}

export enum ClientStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Deleted = 'deleted',
}
