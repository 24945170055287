import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MintAppService,
  MintAuthChannel,
  MintAuthService,
  MintAuthType,
  MintLogger,
  MintSession,
  MintUserService,
} from '@bryllyant/mint-ngx';

import {
  BaseService,
  StatusType,
  VerificationController,
  VerificationService,
} from '@fynvana/common';
import { Subscription } from 'rxjs';

const logger = new MintLogger('TwoFactorVerificationModalComponent');

@Component({
  selector: 'fynvana-verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss'],
})
export class VerificationModalComponent
  extends VerificationController
  implements OnInit, OnDestroy
{
  @Input() isVisible: boolean;
  @Input() isNewSession = false;
  @Input() selectedUsername: string;
  @Input() selectedType: MintAuthType;
  @Input() selectedChannel: MintAuthChannel;
  @Input() forceVerification: boolean;

  @Output() verificationSuccess = new EventEmitter<MintSession>();
  @Output() verificationError = new EventEmitter<any>();
  @Output() verificationCanceled = new EventEmitter();

  verificationResultSub: Subscription;
  closable = true;

  constructor(
    public baseService: BaseService,
    public authService: MintAuthService,
    public verificationService: VerificationService,
    public appService: MintAppService,
    public userService: MintUserService,
  ) {
    super(
      baseService,
      authService,
      verificationService,
      appService,
      userService,
    );

    this.viewType = 'modal';
    this.verificationResultSub =
      this.verificationService.verificationResult.subscribe((result) => {
        switch (result.type) {
          case StatusType.Success:
            this.verificationSuccess.emit(result.value as MintSession);
            break;
          case StatusType.Error:
            this.verificationError.emit(result.value);
            break;
        }
      });
  }

  ngOnInit() {
    this.handleLoad(async () => {
      this.user = await this.authService.me();
      this.newSession = this.isNewSession;
      this.username = this.selectedUsername;
      this.type = this.selectedType;
      this.channel = this.selectedChannel;

      if (this.type === MintAuthType.Mfa) {
        await this.initMfaVerification();
      } else {
        await this.verificationService.requestCode(
          this.type,
          this.channel,
          this.user?.username ?? this.username,
        );
      }

      this.newSession = this.newSession ?? this.type === MintAuthType.Mfa;
    });
  }

  ngOnDestroy() {
    this.verificationResultSub.unsubscribe();
  }

  attemptCloseModal() {
    if (this.forceVerification) {
      this.statusAlert = {
        slug: 'logout-warning',
        message: '',
        type: StatusType.Warning,
      };
      this.closable = false;
      return;
    }

    this.verificationCanceled.emit();
  }

  verifyCode() {
    // reset modal closable state if the user decides they want to try to verify again
    if (this.forceVerification) {
      this.closable = true;
    }
    super.verifyCode();
  }
}
