import { Component, Input, OnInit } from '@angular/core';
import {
  NzButtonShape,
  NzButtonSize,
  NzButtonType,
} from 'ng-zorro-antd/button';

@Component({
  selector: 'fynvana-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
})
export class BtnComponent implements OnInit {
  @Input() label: string | undefined;
  @Input() icon: string;
  @Input() type: NzButtonType = 'default';
  @Input() size: NzButtonSize = 'default';
  @Input() shape: NzButtonShape = 'round';
  @Input() colorStyle: 'fill' | 'outline' = 'fill';
  @Input() classNames: 'btn-wide' | 'btn-full-width' | any = '';
  @Input() disabled = false;
  @Input() loading = false;

  ngOnInit() {
    this.buildClassNames();
  }

  buildClassNames(): void {
    const classArray = ['btn-cmp'];

    switch (this.size) {
      case 'small':
        classArray.push('btn-sm');
        break;
      case 'default':
      default:
    }

    switch (this.colorStyle) {
      case 'fill':
        classArray.push('btn-fill');
        break;
      case 'outline':
        classArray.push('btn-outline');
        break;
      default:
        classArray.push('btn-fill');
        break;
    }

    this.classNames += ' ' + classArray.join(' ');
  }
}
