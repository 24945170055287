<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="visible"
  [nzClosable]="closable"
  (nzOnCancel)="closed.emit()"
  [nzClassName]="'main-modal {{wrapperClasses}}'"
  [nzMaskClosable]="false"
  [nzKeyboard]="false"
  [nzWidth]="getModalWidth()"
  [nzContent]="modalContent"
>
  <ng-template #modalContent>
    <!-- Start Modal Content -->
    <section class="modal-content">
      <!-- Start Loader -->
      <fynvana-loader
        [isLoading]="modalIsLoading"
        positionStyle="absolute"
        size="sm"
      ></fynvana-loader>
      <!-- End Loader -->

      <!-- Start Modal Header -->
      <section class="modal-header" *ngIf="modalTitle">
        <div class="flex ai-center">
          <h3 class="modal-title">{{ modalTitle }}</h3>

          <fynvana-icon
            *ngIf="infoTooltipTitle && infoTooltipText"
            icon="info"
            [height]="30"
            [width]="30"
            color="#0785DF"
            nz-tooltip
            nzTooltipPlacement="top"
            [nzTooltipTitle]="tipTemplate"
          ></fynvana-icon>

          <ng-template #tipTemplate>
            <div class="tooltip-template">
              <strong>{{ infoTooltipTitle }}</strong>
              <div>
                {{ infoTooltipText }}
              </div>
            </div>
          </ng-template>
        </div>

        <div *ngIf="modalSubTitle" class="dark-heading-caption">
          {{ modalSubTitle }}
        </div>
      </section>
      <!-- End Modal Header -->

      <!-- Start Modal Body -->
      <section class="modal-body">
        <!-- Start Status Alert -->
        <div class="pb-2" *ngIf="statusAlertMessage && statusAlertType">
          <fynvana-alert
            [type]="statusAlertType"
            [alertTitle]="statusAlertTitle"
            [message]="statusAlertMessage"
            nzShowIcon
          ></fynvana-alert>

          <ng-template #alertTemplate>
            <div [innerHTML]="statusAlertMessage"></div>
          </ng-template>
        </div>
        <!-- End Status Alert -->

        <ng-content></ng-content>
      </section>
      <!-- End Modal Body -->

      <!-- Start Modal Actions -->
      <section class="modal-actions" *ngIf="!hideModalActions">
        <fynvana-btn
          *ngIf="!hideCancel"
          [label]="cancelLabel"
          colorStyle="outline"
          (click)="closed.emit()"
          classNames="mt-1"
        ></fynvana-btn>
        <fynvana-btn
          *ngIf="!hideSubmit"
          [label]="submitLabel"
          [disabled]="submitDisabled"
          [loading]="modalIsLoading"
          (click)="
            !submitDisabled ? submitted.emit() : $event.stopPropagation()
          "
          classNames="ml-1 mt-1"
        ></fynvana-btn>
      </section>
      <!-- End Modal Actions -->
    </section>
    <!-- End Modal Content -->
  </ng-template>
</nz-modal>
