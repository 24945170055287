import { plainToInstance, Type } from 'class-transformer';
import { IsArray, IsEnum, IsInt, IsString } from 'class-validator';
import { IrsFormStatus } from '../irs-form/irs-form.types';
import { TaxFormNumber, TaxPeriod } from '../tax.types';
import { TaxpayerAuthorizationStatus } from './authorization/taxpayer-authorization.types';

export class IrsFormPartial {
  @IsString()
  readonly uid: string;

  @IsEnum(IrsFormStatus)
  readonly status: IrsFormStatus;
}

export class TaxpayerTaxForm {
  @IsEnum(TaxFormNumber)
  readonly taxFormNumber: TaxFormNumber;

  @IsInt()
  readonly taxYear: number;

  @IsEnum(TaxPeriod)
  readonly taxPeriod: TaxPeriod;

  @IsEnum(TaxpayerAuthorizationStatus)
  readonly status: TaxpayerAuthorizationStatus;

  constructor(model?: Partial<TaxpayerTaxForm>) {
    if (model) {
      Object.assign(this, plainToInstance(TaxpayerTaxForm, model));
    }
  }
}

export class TaxpayerAuthorization {
  @IsEnum(IrsFormStatus)
  readonly status: IrsFormStatus;

  @Type(() => IrsFormPartial)
  readonly irsForm: IrsFormPartial;

  @Type(() => TaxpayerTaxForm)
  @IsArray()
  readonly taxForms: TaxpayerTaxForm[];

  constructor(model?: Partial<TaxpayerAuthorization>) {
    if (model) {
      Object.assign(this, plainToInstance(TaxpayerAuthorization, model));
    }
  }
}
