import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { RelationshipModel } from './relationship.model';

const logger = MintLogger.getLogger('RelationshipService');

@Injectable({ providedIn: 'root' })
export class RelationshipService extends MintService<RelationshipModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      RelationshipModel,
      RelationshipModel._mintResourceName,
    );
  }
}
