import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  MINT_SYSTEM_ACCOUNT_UID,
  MINT_SYSTEM_APP_UID,
  MintLogger,
  MintPolicy,
  MintPolicyModel,
  MintPolicyService,
} from '@bryllyant/mint-ngx';
import { BaseController, BaseService } from '@fynvana/common';
import { PolicyConsentService } from '@fynvana/core';

const logger = MintLogger.getLogger('PolicyModalComponent');

@Component({
  selector: 'fynvana-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss'],
})
export class PolicyModalComponent
  extends BaseController
  implements OnInit, OnChanges
{
  @Input() isVisible: boolean;
  @Input() isRevised: boolean;
  @Input() intention: 'view' | 'consent' = 'consent';
  @Input() policyType: MintPolicy;

  @Output() handleSuccess = new EventEmitter<any>();
  @Output() handleDenied = new EventEmitter<MintPolicy>();
  @Output() closeModal = new EventEmitter<null>();

  policyContent: string;

  constructor(
    public baseService: BaseService,
    private readonly policyService: MintPolicyService,
    private readonly policyConsentService: PolicyConsentService,
  ) {
    super(baseService);
  }

  get btnLabel(): string {
    switch (this.intention) {
      case 'consent':
        return 'Accept Policy';
      case 'view':
      default:
        return 'Ok';
    }
  }

  async ngOnInit() {
    await this.getPolicyContent();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.policyType && !changes.policyType?.firstChange) {
      await this.getPolicyContent();
    }
  }

  async grantConsent() {
    await this.policyConsentService.grantConsent(this.policyType);
    this.handleSuccess.emit(this.policyType);
  }

  async getPolicyContent(): Promise<void> {
    this.handleLoad(
      async () => {
        let policy: MintPolicyModel;

        switch (this.policyType) {
          case MintPolicy.TermsOfUse:
            policy = await this.policyService.getTermsOfUse(
              MINT_SYSTEM_APP_UID,
              MINT_SYSTEM_ACCOUNT_UID,
            );
            this.policyContent = policy.contentHtml;
            break;

          case MintPolicy.PrivacyPolicy:
            policy = await this.policyService.getPrivacyPolicy(
              MINT_SYSTEM_APP_UID,
              MINT_SYSTEM_ACCOUNT_UID,
            );
            this.policyContent = policy.contentHtml;
            break;
        }
      },
      { disableGlobalLoad: true },
    );
  }
}
