import {
  MintAccountModel,
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsObject, IsString } from 'class-validator';
import {
  TaxpayerOnboardingContext,
  TaxpayerOnboardingStatus,
  TaxpayerOnboardingType,
} from './taxpayer-onboarding.types';

@MintResource('/taxpayer-onboardings')
export class TaxpayerOnboardingModel extends MintModel {
  @MintProperty()
  readonly uid: string;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly user: MintUserModel;

  @MintProperty()
  @Type(() => MintAccountModel)
  readonly account: MintAccountModel;

  @MintProperty()
  @IsString()
  readonly consentId: string | null;

  @MintProperty()
  readonly type: TaxpayerOnboardingType;

  @MintProperty()
  readonly status: TaxpayerOnboardingStatus;

  @MintProperty()
  @IsObject()
  readonly context: Partial<TaxpayerOnboardingContext>;

  @MintProperty()
  @Type(() => Date)
  readonly completedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly exitedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerOnboardingModel>) {
    super(model);
  }
}
