import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerConsentLogModel } from './taxpayer-consent-log.model';

const logger = MintLogger.getLogger('TaxpayerConsentLogService');

@Injectable({ providedIn: 'root' })
export class TaxpayerConsentLogService extends MintService<TaxpayerConsentLogModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerConsentLogModel,
      TaxpayerConsentLogModel._mintResourceName,
    );
  }
}
