import { Injectable } from '@angular/core';

import {
  MintAddressModel,
  MintCacheService,
  MintConfigService,
  MintHttpService,
  MintLogger,
  MintModel,
  MintPolicyModel,
  MintPolicyService,
} from '@bryllyant/mint-ngx';
import { ClientModel } from '../../main/client/client.model';
import { TaxpayerModel } from '../../main/taxpayer/taxpayer.model';

const logger = MintLogger.getLogger('PolicyService');

@Injectable({ providedIn: 'root' })
export class PolicyService extends MintPolicyService {
  constructor(
    private readonly myHttpService: MintHttpService,
    private readonly myCacheService: MintCacheService,
    private readonly myConfigService: MintConfigService,
  ) {
    super(myHttpService, myCacheService, myConfigService);
  }

  getUidOrModel<T extends MintModel>(o: string | T): string | T {
    if (typeof o === 'string') {
      return o;
    }

    if (o?.uid) {
      return o.uid;
    }

    return o;
  }

  async getTaxpayerClientConsentPolicy(
    taxpayer: string | TaxpayerModel,
    client: string | ClientModel,
    options?: {
      taxpayerAddress?: string | MintAddressModel;
      clientAddress?: string | MintAddressModel;
    },
  ): Promise<MintPolicyModel> {
    const params = {
      taxpayer: this.getUidOrModel(taxpayer),
      client: this.getUidOrModel(client),
      taxpayerAddress: options?.taxpayerAddress
        ? this.getUidOrModel(options.taxpayerAddress)
        : undefined,
      clientAddress: options?.clientAddress
        ? this.getUidOrModel(options.clientAddress)
        : undefined,
    };

    const url = `${this.urlPath}/taxpayer-client-consent`;
    return this.myHttpService.get(url, {
      queryParams: params,
    });
  }
}
