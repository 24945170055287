import { TaxpayerConsentModel } from '../taxpayer/consent/taxpayer-consent.model';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';
import { ProjectModel } from './project.model';

export enum ProjectStatus {
  Active = 'active',
  Archived = 'archived',
  Deleted = 'deleted',
}

export interface TaxpayerAndConsent {
  taxpayer: TaxpayerModel;
  consent: TaxpayerConsentModel;
}

export interface ProjectAndConsent {
  project: ProjectModel;
  consent: TaxpayerConsentModel;
}

export interface ProjectWithTaxpayerAndConsent {
  project: ProjectModel;
  data: TaxpayerAndConsent[];
}

export interface TaxpayerWithProjectAndConsent {
  taxpayer: TaxpayerModel;
  data: ProjectAndConsent[];
}
