import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';

export const navSlideAni = () => {
  return trigger('navSlideAni', [
    state(
      'show',
      style({
        'margin-left': '0px',
      }),
    ),
    state(
      'hide',
      style({
        'margin-left': '-170px',
      }),
    ),
    state(
      'hideMobile',
      style({
        'margin-left': '-240px',
      }),
    ),
    transition('show => hide', [animate('.2s')]),
    transition('hide => show', [animate('.2s')]),

    transition('show => hideMobile', [animate('.2s')]),
    transition('hideMobile => show', [animate('.2s')]),
  ]);
};
