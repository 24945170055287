import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MintPolicy } from '@bryllyant/mint-ngx';

import {
  AppIdentifier,
  BaseController,
  BaseService,
  MenuItem,
} from '@fynvana/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fynvana-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent
  extends BaseController
  implements OnInit, OnDestroy
{
  @Input() app: AppIdentifier;
  @Input() menuItems: MenuItem[];
  @Input() socialMenuItems: MenuItem[];
  @Input() homeMenuItem: MenuItem;
  @Input() enableMinimalFooter = false;

  aboutModalToggleSub: Subscription;
  currentYear = new Date().getFullYear();
  selectedPolicy: MintPolicy;

  constructor(public baseService: BaseService) {
    super(baseService);
  }

  ngOnInit() {
    this.aboutModalToggleSub = this.baseService.actionTriggered.subscribe(
      (val) => {
        if (val === 'aboutModal') {
          this.toggleModal('aboutModal');
        }
      },
    );
  }

  ngOnDestroy() {
    this.aboutModalToggleSub.unsubscribe();
  }

  protected readonly AppIdentifier = AppIdentifier;
  protected readonly MintPolicy = MintPolicy;
}
