import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsEnum, IsInt, IsString } from 'class-validator';
import { RelationshipType } from '../../relationship/relationship.types';
import { TaxpayerModel } from '../taxpayer.model';
import { TaxpayerInvitationStatus } from './taxpayer-invitation.types';

@MintResource('/taxpayer-invitations')
export class TaxpayerInvitationModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly invitedTaxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly invitedUser: MintUserModel;

  @MintProperty()
  @IsEnum(RelationshipType)
  readonly relationshipType: RelationshipType;

  @MintProperty()
  @IsEnum(TaxpayerInvitationStatus)
  readonly status: TaxpayerInvitationStatus;

  @MintProperty()
  @IsString()
  readonly invitationCode: string | null;

  @MintProperty()
  @IsInt()
  readonly invitedCount: number | null;

  @MintProperty()
  @Type(() => Date)
  readonly invitedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly resentAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly revokedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly viewedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly acceptedAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly registeredAt: Date | null;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerInvitationModel>) {
    super(model);
  }
}
