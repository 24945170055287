import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { NavigationEnd, Router } from '@angular/router';

import {
  MintAnalytics,
  MintConfigService,
  MintLogger,
} from '@bryllyant/mint-ngx';
import { DefaultSEOImgs, SiteMetaData, SiteMetaDataOverrides } from '../types';

const logger = new MintLogger('SEOService');

@Injectable({ providedIn: 'root' })
export class SEOService {
  isInitialized = false;
  defaultMetaData: Partial<SiteMetaData> = {};
  defaultSeoImages: DefaultSEOImgs = {};

  constructor(
    private readonly analytics: MintAnalytics,
    private readonly configService: MintConfigService,
    private readonly meta: Meta,
    private readonly router: Router,
    private readonly title: Title,
  ) {
    const config = this.configService.getConfig();
    this.defaultMetaData = config.seo as SiteMetaData;
    const siteUrl = config.websiteBaseUrl;

    this.defaultMetaData = {
      ...this.defaultMetaData,
      siteUrl,
    };
  }

  setSEO(overrides: SiteMetaDataOverrides = {}, defaultImgs?: DefaultSEOImgs) {
    if (defaultImgs) {
      this.defaultSeoImages = defaultImgs;
    }

    this.resetSEO();

    const overrideKeys = Object.keys(overrides);

    if (overrideKeys.length) {
      overrideKeys.forEach((key) => {
        switch (key) {
          case 'title':
            this.title.setTitle(
              `${this.defaultMetaData.title} | ${overrides.title}`,
            );

            this.meta.updateTag({
              property: `og:title`,
              content: `${this.defaultMetaData.title} | ${overrides.title}`,
            });

            this.meta.updateTag({
              name: `twitter:title`,
              content: `${this.defaultMetaData.title} | ${overrides.title}`,
            });
            break;

          case 'description':
            this.meta.updateTag({
              name: `description`,
              content: overrides.description ?? '',
            });

            this.meta.updateTag({
              property: `og:description`,
              content: overrides.description ?? '',
            });

            this.meta.updateTag({
              name: `twitter:description`,
              content: overrides.description ?? '',
            });
            break;

          case 'customImg':
            this.meta.updateTag({
              name: `image`,
              content: `${this.defaultMetaData.siteUrl}${overrides.customImg}`,
            });

            this.meta.updateTag({
              name: `og:image`,
              content: `${this.defaultMetaData.siteUrl}${overrides.customImg}`,
            });

            this.meta.updateTag({
              name: `twitter:image`,
              content: `${this.defaultMetaData.siteUrl}${overrides.customImg}`,
            });
            break;

          case 'isArticle':
            this.meta.updateTag({
              property: `og:type`,
              content: overrides.isArticle ? 'article' : 'website',
            });
            break;
        }
      });
    }
  }

  resetSEO() {
    if (this.defaultMetaData.title) {
      this.title.setTitle(this.defaultMetaData.title);
    }

    const defaultMetaDefinitions: MetaDefinition[] = [
      {
        name: `image`,
        content: `${this.defaultMetaData.siteUrl}${this.defaultSeoImages.ogImg}`,
      },
      {
        name: `description`,
        content: this.defaultMetaData.description ?? '',
      },
      {
        name: `og:image`,
        content: `${this.defaultMetaData.siteUrl}${this.defaultSeoImages.ogImg}`,
      },
      {
        property: `og:url`,
        content: this.defaultMetaData.siteUrl ?? '',
      },
      {
        property: `og:title`,
        content: this.defaultMetaData.title ?? '',
      },
      {
        property: `og:description`,
        content: this.defaultMetaData.description ?? '',
      },
      {
        property: `og:type`,
        content: this.defaultMetaData.isArticle ? `article` : `website`,
      },
      {
        name: `twitter:image`,
        content: `${this.defaultMetaData.siteUrl}${this.defaultSeoImages.twitterImg}`,
      },
      {
        name: `twitter:domain`,
        content: this.defaultMetaData.siteUrl ?? '',
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:creator`,
        content:
          this.defaultMetaData.twitterUsername ??
          this.defaultMetaData.author ??
          '',
      },
      {
        name: `twitter:title`,
        content: this.defaultMetaData.title ?? '',
      },
      {
        name: `twitter:description`,
        content: this.defaultMetaData.description ?? '',
      },
    ];

    if (!this.isInitialized) {
      this.isInitialized = true;
      this.meta.addTags(defaultMetaDefinitions);
    } else {
      defaultMetaDefinitions.forEach((metaDefinition) => {
        this.meta.updateTag(metaDefinition);
      });
    }
  }

  initGoogleTagManager(trackingId: string | undefined) {
    if (!trackingId) {
      logger.debug('initGoogleTagManager(): trackingId is undefined');
      return;
    }

    // const gTagHeadScript = document.createElement('script');
    // gTagHeadScript.type = 'text/javascript';
    // gTagHeadScript.innerHTML = `
    //   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName[0],
    //   j=d.createElement,dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    //   })(window,document,'script','dataLayer','${trackingId}');
    // `;
    //
    // const head = document.getElementsByTagName('head')[0];
    // if (head !== null && head !== undefined) {
    //   document.head.prepend(gTagHeadScript);
    // }
    //
    // const gTagBodyNoScript = document.createElement('noscript');
    // const gTagBodyIFrame = document.createElement('iframe');
    // gTagBodyIFrame.src = `https://www.googletagmanager.com/ns.html?id=${trackingId}`;
    // gTagBodyIFrame.width = '0';
    // gTagBodyIFrame.height = '0';
    // gTagBodyIFrame.style.display = 'none';
    // gTagBodyIFrame.style.visibility = 'hidden';
    // gTagBodyNoScript.appendChild(gTagBodyIFrame);
    //
    // const body = document.getElementsByTagName('body')[0];
    // if (body !== null && body !== undefined) {
    //   document.body.prepend(gTagBodyNoScript);
    // }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;

        const tree = this.router.parseUrl(this.router.url);
        const section = tree.fragment ?? undefined;
        const path = window.location.pathname;

        this.analytics.pageView(section, '', url, path);
      }
    });
  }
}
