import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService, MenuItem } from '@fynvana/common';

const logger = MintLogger.getLogger('TabsComponent');

@Component({
  selector: 'fynvana-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent extends BaseController {
  @Input() menuItems: MenuItem[];

  @Output() itemSelected = new EventEmitter<MenuItem>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }
}
