import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService, StatusType } from '@fynvana/common';

const logger = MintLogger.getLogger('StatusAlertModalComponent');

@Component({
  selector: 'fynvana-status-alert-modal',
  templateUrl: './status-alert-modal.component.html',
  styleUrls: [
    '../base-modal.component.scss',
    './status-alert-modal.component.scss',
  ],
})
export class StatusAlertModalComponent extends BaseController {
  @Input() isVisible: boolean;
  @Input() alertTitle: string;
  @Input() alertSubTitle: string;
  @Input() messageTitle: string;
  @Input() message: string | TemplateRef<void>;
  @Input() btnLabel = 'Ok';
  @Input() type: StatusType | any;

  @Output() closeModal = new EventEmitter<null>();
  @Output() handleSuccess = new EventEmitter<any>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }
}
