import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  MintAuthService,
  MintLogger,
  MintUserModel,
} from '@bryllyant/mint-ngx';

import {
  AnimationUtil,
  AppIdentifier,
  BaseController,
  BaseService,
  ColorTheme,
  MenuItem,
  SearchableItem,
  ViewportSize,
} from '@fynvana/common';
import { Subscription } from 'rxjs';

const logger = new MintLogger('MainHeaderComponent');

@Component({
  selector: 'fynvana-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  animations: [
    AnimationUtil.getAni('fadeInOut'),
    AnimationUtil.getAni('slideInOutRight', '-300px'),
    AnimationUtil.getAni('scaleFromRight'),
  ],
})
export class MainHeaderComponent
  extends BaseController
  implements OnInit, OnDestroy
{
  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() app: AppIdentifier;
  @Input() menuItems: MenuItem[];
  @Input() buttonShown: MenuItem | null;
  @Input() buttonPosition: 'left' | 'right';
  @Input() homeMenuItem: MenuItem;
  @Input() avatarMenuItems: MenuItem[];
  @Input() contactMenuItem: MenuItem;

  @Input() hideMenu = false;
  @Input() enableSearch = false;
  @Input() enableColorThemeToggle = false;
  @Input() enableContactUs = false;

  layoutIsMobileSub: Subscription;
  layoutIsMobile: boolean;
  subMenuOpenMap: boolean[] = [];

  logoPath = './assets/common/imgs/logo-horizontal.svg';
  logoMobilePath = './assets/common/imgs/icons/logo-mark.svg';
  avatarMenuIsVisible = false;
  isSearchBarVisible = false;
  searchText = '';
  searchResults: SearchableItem[] = [];

  user: MintUserModel;

  constructor(
    public baseService: BaseService,
    private readonly authService: MintAuthService,
  ) {
    super(baseService);

    this.layoutIsMobileSub = this.baseService.layoutIsMobile.subscribe(
      (isMobile) => {
        if (isMobile === this.layoutIsMobile) {
          return;
        }

        this.layoutIsMobile = isMobile;

        if (this.avatarMenuItems?.length) {
          if (isMobile) {
            // add avatar menu to menu items if on mobile
            this.menuItems = [...this.menuItems, ...this.avatarMenuItems];
          } else {
            // remove avatar menu from menu items if not on mobile
            this.avatarMenuItems.forEach((avatarMenuItem) => {
              const idx = this.menuItems.findIndex(
                (menuItem) => menuItem.routerLink === avatarMenuItem.routerLink,
              );

              if (idx >= 0) {
                this.menuItems.splice(idx, 1);
              }
            });
          }
        }
      },
    );
  }

  async ngOnInit() {
    if (await this.authService.authenticated()) {
      this.user = await this.authService.me();
    }
  }

  ngOnDestroy() {
    this.layoutIsMobileSub.unsubscribe();
  }

  async toggleMobileMenu() {
    this.baseService.layoutState.isMobileMenuOpen =
      !this.baseService.layoutState.isMobileMenuOpen;
  }

  async setColorTheme(theme: ColorTheme | any) {
    await this.baseService.setColorTheme(theme as ColorTheme);
  }

  setSearch(term: string) {
    this.baseService.searchTriggered.next(term);
  }

  /** Closes current sub-menu when another is opened (only in mobile mode) */
  subMenusOpenToggle(value?: number): void {
    if (!value) {
      this.subMenuOpenMap = this.subMenuOpenMap.map((item) => (item = false));
    }

    this.subMenuOpenMap.map((item, i) => {
      value !== i
        ? (this.subMenuOpenMap[i] = false)
        : (this.subMenuOpenMap[i] = true);
    });
  }

  toggleSearchBar() {
    this.isSearchBarVisible = !this.isSearchBarVisible;

    if (this.isSearchBarVisible) {
      // Force async, and focus search bar
      // https://stackoverflow.com/questions/50006888/set-focus-on-input-element
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
  }

  search() {
    if (!this.searchText.length) {
      this.searchResults = [];
      return;
    }

    const dictionary = [...this.baseService.searchDictionary];

    this.searchResults = dictionary
      .filter((item) =>
        item.searchableText.toLocaleLowerCase().includes(this.searchText),
      )
      .slice(0, 5);
  }

  clearSearch() {
    this.searchText = '';
    this.searchResults = [];
  }

  protected readonly ViewportSize = ViewportSize;
}
