import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { MintLogger } from '@bryllyant/mint-ngx';

const logger = MintLogger.getLogger('DisableAutofillDirective');

@Directive({
  selector: '[disableAutofill]',
})
export class DisableAutofillDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const nativeElement: HTMLElement = this.elementRef.nativeElement;

    this.disableAutocomplete(nativeElement);

    const el: HTMLElement | null =
      nativeElement.querySelector('[autocomplete]');
    if (el) {
      this.disableAutocomplete(el);
    }
  }

  private disableAutocomplete(el: HTMLElement) {
    el?.setAttribute('autocomplete', 'off');
    el?.setAttribute('role', 'presentation');
  }
}
