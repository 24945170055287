import {
  MintEntityMeta,
  MintMediaModel,
  MintModel,
  MintObject,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsInt,
  IsObject,
  IsString,
} from 'class-validator';
import { AgentModel } from '../agent/agent.model';
import {
  TaxFormNumber,
  TaxPeriod,
  TranscriptPurpose,
  TranscriptType,
} from '../tax.types';
import { TaxpayerAuthorizationModel } from '../taxpayer/authorization/taxpayer-authorization.model';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';
import { FilingStatus } from './transcript.types';

@MintResource('/transcripts')
export class TranscriptModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => TaxpayerAuthorizationModel)
  readonly authorization: TaxpayerAuthorizationModel;

  @MintProperty()
  @Type(() => AgentModel)
  readonly agent: AgentModel;

  @MintProperty()
  @IsEnum(TranscriptType)
  readonly transcriptType: TranscriptType;

  @MintProperty()
  @IsEnum(TranscriptPurpose)
  readonly transcriptPurpose: TranscriptPurpose;

  @MintProperty()
  @IsEnum(TaxFormNumber)
  readonly taxFormNumber: TaxFormNumber;

  @MintProperty()
  @IsEnum(TaxPeriod)
  readonly taxPeriod: TaxPeriod;

  @MintProperty()
  @IsInt()
  readonly taxYear: number;

  @MintProperty()
  @IsString()
  readonly transcriptHtml: string;

  @MintProperty()
  @IsDate()
  readonly filingDate: Date;

  @MintProperty()
  @IsEnum(FilingStatus)
  readonly filingStatus: FilingStatus;

  @MintProperty()
  @IsObject()
  readonly transcriptJson: MintObject;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly transcriptPdf: MintMediaModel;

  @MintProperty()
  @IsBoolean()
  readonly isCurrent: boolean;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TranscriptModel>) {
    super(model);
  }
}
