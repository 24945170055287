import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsInt, IsString } from 'class-validator';
import { IrsFormModel } from '../../irs-form/irs-form.model';
import { IrsFormDescriptionOfMatter } from '../../irs-form/irs-form.types';
import { TaxFormNumber, TaxPeriod } from '../../tax.types';
import { TaxpayerModel } from '../taxpayer.model';
import {
  TaxpayerAuthorizationError,
  TaxpayerAuthorizationStatus,
} from './taxpayer-authorization.types';

@MintResource('/taxpayer-authorizations')
export class TaxpayerAuthorizationModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly jointTaxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => IrsFormModel)
  readonly irsForm: IrsFormModel;

  @MintProperty()
  @Type(() => IrsFormModel)
  readonly supersededByIrsForm: IrsFormModel;

  @MintProperty()
  @IsEnum(TaxpayerAuthorizationStatus)
  readonly status: TaxpayerAuthorizationStatus;

  @MintProperty()
  @IsArray()
  readonly irsErrors: TaxpayerAuthorizationError[];

  @MintProperty()
  @IsEnum(IrsFormDescriptionOfMatter)
  readonly descriptionOfMatter: IrsFormDescriptionOfMatter;

  @MintProperty()
  @IsEnum(TaxFormNumber)
  readonly taxFormNumber: TaxFormNumber;

  @MintProperty()
  @IsInt()
  readonly taxYear: number;

  @MintProperty()
  @IsEnum(TaxPeriod)
  readonly taxPeriod: TaxPeriod;

  @MintProperty()
  @IsString()
  readonly specificMatters: string;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerAuthorizationModel>) {
    super(model);
  }
}
