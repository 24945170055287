import {
  MintAccountStatus,
  MintFormFieldOption,
  MintObject,
  MintUserStatus,
} from '@bryllyant/mint-ngx';
import { ClientStatus } from './client/client.types';
import { ProjectStatus } from './project/project.types';
import { TaxpayerConsentStatus } from './taxpayer/consent/taxpayer-consent.types';
import { TaxpayerType } from './taxpayer/taxpayer.types';

export const taxpayerTypeFilterOptions: MintFormFieldOption[] = [
  { name: 'Individual', value: TaxpayerType.Individual },
  { name: 'Business', value: TaxpayerType.Business },
];

export const accountStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Enabled', value: MintAccountStatus.Enabled },
  { name: 'Disabled', value: MintAccountStatus.Disabled },
];

export const projectStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Active', value: ProjectStatus.Active },
  { name: 'Archived', value: ProjectStatus.Archived },
];

export const clientStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Enabled', value: ClientStatus.Enabled },
  { name: 'Disabled', value: ClientStatus.Disabled },
];

export const userStatusFilterOptions: MintFormFieldOption[] = [
  { name: 'Enabled', value: MintUserStatus.Enabled },
  { name: 'Disabled', value: MintUserStatus.Disabled },
];

export const transcriptConsentStatusFilterOptions: MintFormFieldOption[] = [
  {
    name: 'Granted',
    value: TaxpayerConsentStatus.TaxpayerGranted,
  },
  {
    name: 'Pending',
    value: TaxpayerConsentStatus.TaxpayerPendingAuthorization,
  },
];

// TODO: reference proper enum values once the ApiModels are created/implemented
export const apiFilterOptions: MintFormFieldOption[] = [
  { name: 'IRS', value: 'irs' },
  { name: 'Docusign', value: 'docusign' },
];

export const FYNVANA_CLIENT_SLUG = 'fynvana';

export enum ExecutionStatus {
  Queued = 'queued',
  Running = 'running',
  Success = 'success',
  Error = 'error',
}

export enum FynvanaPolicy {
  TaxpayerClientConsent = 'taxpayer-client-consent',
}

export enum FynvanaPortal {
  Website = 'fynvana-web-portal',
  Admin = 'fynvana-admin-portal',
  Developer = 'fynvana-dev-portal',
  Docs = 'fynvana-docs-portal',
  Client = 'fynvana-client-portal',
}

export enum FynvanaRole {
  Client = 'client',
}

export enum TaxIdType {
  SSN = 'ssn', // Social security number
  EIN = 'ein', // Employer identification number
  ITIN = 'itin', // Individual tax ID number
  VAT = 'vat', // International VAT number
}

export enum DocusignFlowType {
  Email = 'email',
  Embedded = 'embedded',
}

export type TypeofValue =
  | 'number'
  | 'string'
  | 'boolean'
  | 'undefined'
  | 'object'
  | 'symbol'
  | 'bigint'
  | 'function';

export interface ModelValidationError {
  key: string;
  original: {
    type: TypeofValue;
    value: any;
    valueTypes: MintObject;
  };

  target: {
    type: TypeofValue;
    value: any;
    valueTypes: MintObject;
  };
}
