import { isNullish, MintError, MintProperty } from '@bryllyant/mint-ngx';
import { plainToInstance, Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsDefined,
  IsEnum,
  IsInt,
  IsOptional,
  IsString,
} from 'class-validator';

import { ClientModel } from '../../client/client.model';
import { DocusignFlowType } from '../../main.types';
import { TaxFormNumber, TaxPeriod } from '../../tax.types';
import { BusinessForm, TaxpayerRelationship } from '../taxpayer-relationship';
import { TaxpayerModel } from '../taxpayer.model';

export class TaxpayerAuthorizationDto {
  // Generally this will be an individual, especially for self-registered
  // taxpayers.  However, in the client consent flow, it's possible this
  // will be a business (in the case where the client just needs a business
  // return and is signed by an authorized signer)
  @Type(() => TaxpayerModel)
  @IsDefined()
  readonly taxpayer: TaxpayerModel;

  // if defined and clientConsentGranted is true, the client
  // will be granted access to all authorized transcripts
  @Type(() => ClientModel)
  @IsOptional()
  readonly client: ClientModel;

  // if defined, client must be defined as well.
  @IsBoolean()
  @IsOptional()
  readonly clientConsentGranted: boolean;

  // if defined, the set of decoded uids should resolve to TaxpayerConsentEntity
  // objects that reference  the taxpayer and the client (if defined).
  @IsString()
  @IsOptional()
  readonly clientConsentRequestId: string;

  @IsInt()
  @IsDefined()
  readonly taxStartYear: number;

  @IsInt()
  @IsDefined()
  readonly taxEndYear: number;

  // required if taxpayer is a business
  @IsEnum(TaxPeriod)
  @IsOptional()
  readonly taxPeriod: TaxPeriod;

  // required if taxpayer is a business
  @MintProperty()
  @IsEnum(TaxFormNumber)
  @IsOptional()
  readonly taxFormNumber: BusinessForm;

  @Type(() => TaxpayerRelationship)
  @IsArray()
  @IsOptional()
  readonly relationships: TaxpayerRelationship[];

  @MintProperty()
  @IsOptional()
  readonly docusignFlowType: DocusignFlowType;

  constructor(model?: Partial<TaxpayerAuthorizationDto>) {
    if (model) {
      Object.assign(this, plainToInstance(TaxpayerAuthorizationDto, model));
    }

    if (this.taxStartYear > this.taxEndYear) {
      throw new MintError(
        'taxStartYear must be less than or equal to taxEndYear',
      );
    }

    if (this.client && isNullish(this.clientConsentGranted)) {
      throw new MintError(
        'client is defined but clientConsentGranted is not defined',
      );
    }

    if (isNullish(this.client) && !isNullish(this.clientConsentGranted)) {
      throw new MintError(
        'clientConsentGranted is defined but client is not defined',
      );
    }
  }
}
