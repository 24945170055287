import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export class AnimationUtil {
  /** Returns animation based on passed key */
  static getAni(
    animationKey: string,
    optionalStyle?: string,
  ): AnimationTriggerMetadata | undefined {
    switch (animationKey) {
      case 'fadeInOut':
        return trigger('fadeInOut', [
          transition(':enter', [
            style({ opacity: '0' }),
            animate('200ms ease-in', style({ opacity: '1.0' })),
          ]),
          transition(':leave', [
            animate('200ms ease-out', style({ opacity: '0' })),
          ]),
        ]);
      case 'scaleFromRight':
        return trigger('scaleFromRight', [
          transition(':enter', [
            style({ transform: 'scaleX(0)' }),
            animate(
              '200ms ease-in',
              style({
                'transform-origin': 'right',
                transform: 'scaleX(1)',
              }),
            ),
          ]),
          transition(':leave', [
            animate(
              '200ms ease-out',
              style({
                'transform-origin': 'right',
                transform: 'scaleX(0)',
                width: '0',
              }),
            ),
          ]),
        ]);
      case 'slideInOutRight':
        return trigger('slideInOut', [
          state(
            'show',
            style({
              right: '0px',
            }),
          ),
          state(
            'hide',
            style({
              right: `${optionalStyle}`,
            }),
          ),
          transition('show => hide', [animate('.15s')]),
          transition('hide => show', [animate('.15s')]),
        ]);
    }
  }
}
