import { Injectable } from '@angular/core';

import {
  MintAuthChannel,
  MintAuthService,
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintRegistrationDto,
  MintService,
  MintStorageService,
  MintStorageType,
  MintUserModel,
  MintUserService,
  MintUserSettings,
} from '@bryllyant/mint-ngx';
import { lastValueFrom } from 'rxjs';

const logger = MintLogger.getLogger('UserService');

export const ADMIN_LOGGED_IN_AS_USER_KEY = 'admin-logged-in-as-user';

@Injectable({ providedIn: 'root' })
export class UserService extends MintService<MintUserModel> {
  constructor(
    private readonly authService: MintAuthService,
    private readonly localStorage: MintStorageService,
    private readonly mintUserService: MintUserService,
    protected readonly httpService: MintHttpService,
    protected readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      MintUserModel,
      MintUserModel._mintResourceName,
    );
  }

  async adminRegisterUser(
    payload: MintRegistrationDto & { isAdmin: boolean },
  ): Promise<MintUserModel> {
    return this.httpService.post(
      this.urlPath + '/admin-register-user',
      payload,
    );
  }

  async hasRegistrationSpotsRemaining(): Promise<boolean> {
    return this.httpService.get(
      this.urlPath + '/has-registration-spots-remaining',
    );
  }

  async updateMfaSettingsEnabledChannels(
    user: MintUserModel,
    channel: MintAuthChannel,
    enabled: boolean,
    userSettings?: MintUserSettings,
  ): Promise<MintUserSettings> {
    if (!userSettings) {
      userSettings = await this.mintUserService.getSettings(user);
    }

    const disabledChannels = userSettings?.auth?.mfa?.disabledChannels ?? [];
    const mfaEnabled = userSettings?.auth?.mfa?.enabled;
    const newSettings: MintUserSettings = { ...userSettings };

    if (enabled) {
      const index = disabledChannels.findIndex((ch) => ch === channel);

      if (index >= 0) {
        disabledChannels.splice(index, 1);
        newSettings.auth.mfa.disabledChannels = disabledChannels;
      }

      if (!mfaEnabled) {
        newSettings.auth.mfa.enabled = true;
      }
    }

    if (!enabled) {
      if (!disabledChannels.includes(channel)) {
        disabledChannels.push(channel);
      }

      newSettings.auth.mfa.disabledChannels = disabledChannels;

      if (
        disabledChannels.includes(MintAuthChannel.Sms) &&
        disabledChannels.includes(MintAuthChannel.Email)
      ) {
        newSettings.auth.mfa.enabled = false;
      }
    }

    return await this.mintUserService.updateSettings(user, null, newSettings);
  }

  async logout(clearLocalStorage = true) {
    await this.authService.logout(clearLocalStorage);
    await lastValueFrom(
      this.localStorage.removeItem(
        ADMIN_LOGGED_IN_AS_USER_KEY,
        MintStorageType.Local,
      ),
    );
  }
}
