import {
  MintModel,
  MintQueryFilter,
  MintQueryOptions,
  MintResultSet,
  MintUidUtil,
} from '@bryllyant/mint-ngx';
import { MockPropStrategy, TestUtil } from './test-util';

export abstract class TestController<MODEL extends MintModel> {
  model: MODEL;
  models: MODEL[] = [];

  generateModel(modelSchema: any): any {
    const model: any = {};

    for (const key of Object.keys(modelSchema as object)) {
      switch (modelSchema[key]) {
        case MockPropStrategy.String:
          model[key] = TestUtil.randomStr();
          break;
        case MockPropStrategy.StringArray:
          model[key] = TestUtil.randomStrArray();
          break;
        case MockPropStrategy.Number:
          model[key] = TestUtil.randomInt();
          break;
        case MockPropStrategy.NumberArray:
          model[key] = TestUtil.randomIntArray();
          break;
        case MockPropStrategy.Boolean:
          model[key] = TestUtil.randomBool();
          break;
        case MockPropStrategy.Email:
          model[key] = TestUtil.randomEmail();
          break;
        case MockPropStrategy.MobileNumber:
          model[key] = TestUtil.randomMobileNumber();
          break;
        default:
          // If raw data provided instead of a mock strategy, use it
          model[key] = modelSchema[key];
          break;
      }
    }

    model.uid = MintUidUtil.uid();
    model.createdAt = TestUtil.randomTs();
    model.updatedAt = TestUtil.randomTs();

    return model as MODEL;
  }

  generateModelArray(mockModelSchema: MODEL, amount: number): MODEL[] {
    const models = [];

    for (let i = 0; i < amount; i++) {
      models.push(this.generateModel(mockModelSchema));
    }

    return models;
  }

  clearCache() {
    return;
  }

  findAll(filters?: MintQueryFilter<MODEL>): MODEL[] {
    const filteredData = { ...this.models };

    if (filters) {
      // TODO: Filter logic
    }

    return filteredData;
  }

  find(options?: MintQueryOptions): MintResultSet<MODEL> {
    const filteredData = { ...this.models };
    const resultSet = new MintResultSet<MODEL>({
      data: [],
      totalSize: 0,
      pageSize: 0,
      startIndex: 0,
    });

    if (options?.filter) {
      // TODO: Filter logic
    }

    if (options?.sort) {
      // TODO: Sort logic
    }

    if (options?.limit && options?.offset) {
      // TODO: Page logic
    }

    return resultSet;
  }

  findOne(filter: MintQueryFilter<MODEL>): MODEL {
    const filteredData = { ...this.models };

    // TODO: Filter logic

    return filteredData[0];
  }

  findByUid(uid: string): MODEL {
    const record = this.models.find((d) => d.uid === uid);

    return record ?? ({} as MODEL);
  }

  save(model: MODEL) {
    // Update if model has UID
    if (model.uid) {
      const recordIndex = this.models.findIndex((d) => d.uid === model.uid);

      if (recordIndex < 0) {
        throw new Error(`No record found for ${model.uid}`);
      }

      this.models[recordIndex] = {
        ...this.models[recordIndex],
        ...model,
      };

      return;
    }

    // Create if model does not have UID
    this.models.push(model);
  }

  create(model: MODEL) {
    this.save(model);
  }

  update(model: MODEL) {
    this.save(model);
  }

  remove(model: MODEL) {
    const recordIndex = this.models.findIndex((d) => d.uid === model.uid);

    if (recordIndex < 0) {
      throw new Error(`No record found for ${model.uid}`);
    }

    this.models.splice(recordIndex, 1);
  }

  removeByUid(uid: string) {
    this.remove({ uid } as MODEL);
  }
}
