import { Injectable } from '@angular/core';
import { MintUserModel, MintUserStatus } from '@bryllyant/mint-ngx';

import { MockPropStrategy, TestUtil } from '../test-util';
import { TestController } from '../test.controller';

@Injectable()
export class MockUserService extends TestController<MintUserModel> {
  constructor() {
    super();

    this.models = this.generateModelArray(
      this.getMockSchema(),
      TestUtil.randomInt(1, 10),
    );
  }

  getMockSchema(): MintUserModel {
    return new MintUserModel({
      firstName: MockPropStrategy.String,
      lastName: MockPropStrategy.String,
      username: MockPropStrategy.String,
      mobileNumber: MockPropStrategy.MobileNumber,
      email: MockPropStrategy.Email,
      status: MintUserStatus.Enabled,
    });
  }
}
