import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintQueryFilter,
  MintQueryOperator,
  MintService,
  MintUserModel,
} from '@bryllyant/mint-ngx';

import { BusinessModel } from '../business/business.model';
import { RelationshipService } from '../relationship/relationship.service';
import { IndividualModel } from './individual.model';

const logger = MintLogger.getLogger('IndividualService');

@Injectable({ providedIn: 'root' })
export class IndividualService extends MintService<IndividualModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
    private readonly relationshipService: RelationshipService,
  ) {
    super(
      httpService,
      cacheService,
      IndividualModel,
      IndividualModel._mintResourceName,
    );
  }

  async getRelatedBusinesses(
    individual: IndividualModel,
  ): Promise<BusinessModel[]> {
    const relationships = await this.relationshipService.findAll(
      MintQueryFilter.from([
        {
          key: 'subjectIndividual.uid',
          operator: MintQueryOperator.Equals,
          value: individual.uid,
        },
        {
          key: 'relatedBusiness.uid',
          operator: MintQueryOperator.NotEquals,
          value: null,
        },
      ]),
    );

    return relationships.map(({ relatedBusiness }) => relatedBusiness) ?? [];
  }

  async findByUser(
    user: string | MintUserModel,
  ): Promise<IndividualModel | null> {
    const userUid = typeof user === 'string' ? user : user.uid;

    try {
      const result = (await this.httpService.get<{ uid: string }>(
        `${this.urlPath}/users/${userUid}`,
      )) as IndividualModel;

      return this.toModel(result);
    } catch (err) {
      if ((err as any).status === HttpStatusCode.NotFound) {
        return null;
      }

      throw err;
    }
  }
}
