import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MintLogger, MintUserModel } from '@bryllyant/mint-ngx';
import {
  AnimationUtil,
  BaseController,
  BaseService,
  MenuItem,
} from '@fynvana/common';

const logger = MintLogger.getLogger('AvatarProfileMenuComponent');

@Component({
  selector: 'fynvana-avatar-profile-menu',
  templateUrl: './avatar-profile-menu.component.html',
  styleUrls: ['./avatar-profile-menu.component.scss'],
  animations: [AnimationUtil.getAni('fadeInOut')],
})
export class AvatarProfileMenuComponent extends BaseController {
  @Input() avatarMenuItems: MenuItem[] = [];
  @Input() user: MintUserModel;
  @Input() isVisible = false;
  @Output() toggleAvatarMenu = new EventEmitter<null>();

  subMenuOpenMap: boolean[] = [];

  constructor(public baseService: BaseService) {
    super(baseService);
  }

  /** Closes current sub-menu when another is opened (only in mobile mode) */
  subMenusOpenToggle(value?: number): void {
    if (!value) {
      this.subMenuOpenMap = this.subMenuOpenMap.map((item) => (item = false));
    }

    this.subMenuOpenMap.map((item, i) => {
      value !== i
        ? (this.subMenuOpenMap[i] = false)
        : (this.subMenuOpenMap[i] = true);
    });
  }

  determineIsParentMenuActive(menuItem: MenuItem): boolean {
    if (this.baseService.router.url.includes(menuItem.routerLink as string)) {
      return true;
    }

    return false;
  }
}
