import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { IrsFormEventNoteDto } from './irs-form-event-note.dto';
import { IrsFormEventDto } from './irs-form-event.dto';
import { IrsFormEventModel } from './irs-form-event.model';

const logger = MintLogger.getLogger('IrsFormEventService');

@Injectable({ providedIn: 'root' })
export class IrsFormEventService extends MintService<IrsFormEventModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      IrsFormEventModel,
      IrsFormEventModel._mintResourceName,
    );
  }

  async save(event: IrsFormEventDto): Promise<IrsFormEventModel> {
    return super.save(event);
  }

  async addEventNote(
    irsFormEvent: string | IrsFormEventModel,
    note: IrsFormEventNoteDto,
  ): Promise<IrsFormEventModel> {
    const eventUid =
      typeof irsFormEvent === 'string' ? irsFormEvent : irsFormEvent.uid;
    const url = `${this.urlPath}/${eventUid}/notes`;

    const result = await this.httpService.post<IrsFormEventModel>(url, note);

    return this.toModel(result);
  }
}
