import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { AppVersions, BaseController, BaseService } from '@fynvana/common';
import { lastValueFrom } from 'rxjs';

const logger = MintLogger.getLogger('AboutModalComponent');

let appVersionData: { versionTag: string; hash: string };

try {
  appVersionData = require('../../../../../../version.json');
} catch (e) {
  logger.debug('version.json does not exist');
}

@Component({
  selector: 'fynvana-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss'],
})
export class AboutModalComponent extends BaseController implements OnInit {
  @Input() title: string;
  @Input() isVisible: boolean;
  @Input() showCommitHash: boolean = false;
  @Output() closeModal = new EventEmitter<null>();
  @Output() handleSuccess = new EventEmitter<any>();

  versions: AppVersions = { frontend: '--', backend: '--' };
  currentYear = new Date().getFullYear();

  constructor(
    public baseService: BaseService,
    private readonly httpClient: HttpClient,
  ) {
    super(baseService);
  }

  ngOnInit() {
    this.handleLoad(
      async () => {
        const apiVersionData = await lastValueFrom(
          this.httpClient.get<{ api_version: string; hash: string }>(
            `${this.baseService.config.api.baseUrl}version`,
          ),
        );

        let appVersion = appVersionData?.versionTag ?? 'dev';
        if (this.showCommitHash && appVersionData?.hash) {
          appVersion = `${appVersion} / ${appVersionData.hash}`;
        }

        let apiVersion = apiVersionData?.api_version ?? 'dev';
        if (this.showCommitHash && apiVersionData?.hash) {
          apiVersion = `${apiVersion} / ${apiVersionData?.hash}`;
        }

        this.versions = {
          frontend: appVersion,
          backend: apiVersion,
        };
      },
      { disableGlobalLoad: true },
    );
  }
}
