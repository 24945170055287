import { Injectable } from '@angular/core';

import {
  MINT_SYSTEM_ACCOUNT_UID,
  MINT_SYSTEM_APP_UID,
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintPolicy,
  MintPolicyConsentModel,
  MintPolicyConsentStatus,
  MintQueryFilter,
  MintQueryOperator,
  MintQuerySortOrder,
  MintService,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import * as dayjs from 'dayjs';
import { CreatePolicyConsentDto } from './policy-consent.types';

const logger = MintLogger.getLogger('PolicyConsentService');

@Injectable({ providedIn: 'root' })
export class PolicyConsentService extends MintService<MintPolicyConsentModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      MintPolicyConsentModel,
      MintPolicyConsentModel._mintResourceName,
    );
  }

  async grantConsent(type: MintPolicy): Promise<MintPolicyConsentModel> {
    const policyConsentDto: CreatePolicyConsentDto = {
      appUid: MINT_SYSTEM_APP_UID,
      accountUid: MINT_SYSTEM_ACCOUNT_UID,
      type: type,
    };

    return this.httpService.post(`${this.urlPath}`, policyConsentDto);
  }

  async hasPolicyAccepted(
    user: MintUserModel,
    policyType: MintPolicy,
    clearCache = true,
  ): Promise<boolean> {
    if (clearCache) {
      await this.clearCache();
    }

    const { data } = await this.find({
      filter: MintQueryFilter.from([
        {
          key: 'user.uid',
          operator: MintQueryOperator.Equals,
          value: user.uid,
        },
        {
          key: 'policy.type',
          operator: MintQueryOperator.Equals,
          value: policyType,
        },
        {
          key: 'policy.isCurrent',
          operator: MintQueryOperator.Equals,
          value: true,
        },
        {
          key: 'consentStatus',
          operator: MintQueryOperator.Equals,
          value: MintPolicyConsentStatus.Granted,
        },
      ]),
      sort: { field: 'consentAt', order: MintQuerySortOrder.Desc },
      limit: 1,
    });

    const currentConsent = data[0];

    if (!currentConsent) {
      return false;
    }

    if (
      dayjs(currentConsent.consentAt).isBefore(currentConsent.policy.updatedAt)
    ) {
      return false;
    }

    return true;
  }

  async hasPrivacyAndTermsPoliciesAccepted(
    user: MintUserModel,
    clearCache = true,
  ): Promise<boolean> {
    const isPrivacyPolicyAccepted = await this.hasPolicyAccepted(
      user,
      MintPolicy.PrivacyPolicy,
      clearCache,
    );
    const isTermsOfUseAccepted = await this.hasPolicyAccepted(
      user,
      MintPolicy.TermsOfUse,
      clearCache,
    );

    return isPrivacyPolicyAccepted && isTermsOfUseAccepted;
  }
}
