import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
  AppIdentifier,
  BaseController,
  BaseService,
  KeyValPairs,
  MenuItem,
} from '@fynvana/common';

import { navSlideAni } from './utils';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'fynvana-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  animations: [navSlideAni()],
})
export class SidebarNavComponent
  extends BaseController
  implements OnInit, OnChanges, OnDestroy
{
  @Input() app: AppIdentifier;
  @Input() navTitle: string | null;
  @Input() menuItems: MenuItem[];

  @Output() navigationClicked = new EventEmitter<string>();

  routeSub: Subscription;

  layoutIsMobileSub: Subscription;
  layoutIsMobile: boolean;

  subMenuOpenMap: boolean[] = [];
  nestedSubMenusOpenMap: KeyValPairs = {};

  constructor(public baseService: BaseService) {
    super(baseService);

    this.baseService.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.buildSubMenuMap();
        this.buildNestedSubMenuMap();
      }
    });

    if (this.baseService.layoutState.isMobile !== undefined) {
      this.setLayout(this.baseService.layoutState.isMobile);
    }

    this.layoutIsMobileSub = this.baseService.layoutIsMobile.subscribe(
      (isMobile) => {
        this.setLayout(isMobile);
      },
    );
  }

  ngOnInit() {
    this.buildSubMenuMap();
    this.buildNestedSubMenuMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuItems && !changes.menuItems.firstChange) {
      this.buildSubMenuMap();
      this.buildNestedSubMenuMap();
    }
  }

  ngOnDestroy() {
    this.layoutIsMobileSub.unsubscribe();
  }

  setLayout(isMobile: boolean) {
    if (isMobile === this.layoutIsMobile) return;
    this.layoutIsMobile = isMobile;

    if (!this.layoutIsMobile) return;

    if (!isMobile) {
      this.baseService.layoutState.isNavSideBarOpen = true;
    } else {
      this.baseService.layoutState.isNavSideBarOpen = false;
    }
  }

  /** Build submenu open map, if menu contains selected slug, open that menu */
  buildSubMenuMap() {
    this.menuItems?.map((menuItem, i) => {
      this.subMenuOpenMap[i] = false;
      if (menuItem.children?.length) {
        menuItem.children.map((child) => {
          // skip building nested submenu map here
          // if (child.children) {
          //   return;
          // }

          if (
            this.baseService.router.url.includes(child.routerLink as string)
          ) {
            this.subMenuOpenMap[i] = true;
          }
        });
      }
    });
  }

  buildNestedSubMenuMap() {
    for (const menuItem of this.menuItems) {
      if (menuItem.children) {
        for (const child of menuItem.children) {
          if (child.children) {
            if (
              this.baseService.router.url.includes(child.routerLink as string)
            ) {
              this.nestedSubMenusOpenMap[child.title || ''] = true;
            } else {
              this.nestedSubMenusOpenMap[child.title || ''] = false;
            }
          }
        }
      }
    }
  }

  /** Closes current sub-menu when another is opened  */
  subMenusOpenToggle(value?: number): void {
    // Close all if no value is provided
    if (!value) {
      this.subMenuOpenMap.map((item) => (item = false));
    }

    this.subMenuOpenMap.map((item, i) => {
      value !== i
        ? (this.subMenuOpenMap[i] = false)
        : (this.subMenuOpenMap[i] = true);
    });
  }

  nestedSubMenusOpenToggle(value?: string): void {
    const keys = Object.keys(this.nestedSubMenusOpenMap);

    // Close all if no value is provided
    if (!value) {
      for (const key of keys) {
        this.nestedSubMenusOpenMap[key] = false;
      }

      return;
    }

    for (const key of keys) {
      key === value
        ? (this.nestedSubMenusOpenMap[value] = true)
        : (this.nestedSubMenusOpenMap[value] = false);
    }
  }

  /** If menu item is clicked in mobile mode, close the sidebar */
  toggleMobileSideBar() {
    if (this.baseService.layoutState.isMobile) {
      this.baseService.layoutState.isNavSideBarOpen = false;
    }
  }

  /** Determines if navigation/logic should be fired when a parent link is clicked */
  async onParentMenuToggle(
    event: boolean, // whether menu was open or closed
    menuItem: MenuItem,
    menuIdx: number,
  ) {
    const childIsSelected = menuItem.children?.find((child) =>
      this.baseService.router.url.includes(child.routerLink as string),
    );

    if (event && !childIsSelected) {
      this.subMenusOpenToggle(menuIdx);
      this.nestedSubMenusOpenToggle();
      this.toggleMobileSideBar();
      await this.navigate(menuItem);
    }
  }

  async onNestedMenuToggle(
    event: boolean, // whether menu was open or closed
    menuItem: MenuItem,
  ) {
    const childIsSelected = menuItem.children?.find((child) =>
      this.baseService.router.url.includes(child.routerLink as string),
    );

    if (event && !childIsSelected) {
      this.nestedSubMenusOpenToggle(menuItem.title || '');
      this.toggleMobileSideBar();
      await this.navigate(menuItem);
    }
  }

  determineIsParentMenuActive(menuItem: MenuItem): boolean {
    if (this.baseService.router.url.includes(menuItem.routerLink as string)) {
      return true;
    }

    return false;
  }
}
