export enum IndividualIdentificationType {
  DriversLicense = 'drivers-license',
  Passport = 'passport',
  PassportCard = 'passport-card',
  PermanentResidentCard = 'permanent-resident-card',
  TemporaryResidentCard = 'temporary-resident-card',
  EmployerIdentificationCard = 'employer-identification-card',
  SchoolIdentificationCard = 'school-identification-card',
  ArmedForcesCard = 'armed-forces-card',
  CoastGuardCard = 'coast-guard-card',
  VoterRegistrationCard = 'voter-registration-card',
  StateId = 'state-id',
  NationalIdentityCard = 'national-identity-card',
  ElectronicId = 'electronic-id',
}

export enum IndividualVerificationType {
  FederalTaxReturn = 'federal-tax-return',
  StateTaxReturn = 'state-tax-return',
  IrsFormW2 = 'irs-form-w-2',
  IrsForm1099 = 'irs-form-1099',
  IrsNoticeLetter = 'irs-notice-letter',
  SocialSecurityCard = 'social-security-card',
  CreditCardStatement = 'credit-card-statement',
  BankStatement = 'bank-statement',
  UtilityStatement = 'utility-statement',
}

export enum IndividualVerificationStatus {
  Verified = 'verified', // match found
  NotVerified = 'not-verified', // match not found
  NotReviewed = 'not-reviewed',
}
