<nz-alert
  *ngIf="type"
  [nzType]="type"
  [nzMessage]="showAlertTitle ? alertTitle || capitalizeFirst(type) : ''"
  [nzDescription]="message || ''"
  [nzCloseable]="closable"
  [nzAction]="actionTemplate"
  (nzOnClose)="alertClosed.emit()"
  [nzShowIcon]="showAlertIcon"
  class="{{ wrapperClasses }}"
></nz-alert>

<ng-template #actionTemplate>
  <div *ngIf="btn || secondBtn" class="button-col">
    <fynvana-btn
      *ngIf="secondBtn"
      [label]="secondBtn.label"
      [colorStyle]="secondBtn.colorStyle || 'outline'"
      (click)="secondBtnClicked.emit()"
      class="mr-1"
    >
    </fynvana-btn>
    <fynvana-btn
      *ngIf="btn"
      [label]="btn.label"
      [colorStyle]="btn.colorStyle || 'fill'"
      (click)="btnClicked.emit()"
    >
    </fynvana-btn>
  </div>
</ng-template>
