import {
  MintEmailType,
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
  MintVerificationStatus,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsBoolean, IsEmail, IsEnum, IsString } from 'class-validator';
import { BusinessModel } from '../business.model';

@MintResource('/business-emails')
export class BusinessEmailModel extends MintModel {
  @MintProperty()
  @IsString()
  declare readonly uid: string;

  @MintProperty()
  @IsEnum(MintEmailType)
  readonly type: MintEmailType;

  @MintProperty()
  @Type(() => BusinessModel)
  readonly business: BusinessModel;

  @MintProperty()
  @IsEmail()
  readonly email: string;

  @MintProperty()
  @IsBoolean()
  readonly isPrimary: boolean;

  @MintProperty()
  @IsEnum(MintVerificationStatus)
  readonly verificationStatus: MintVerificationStatus;

  @MintProperty()
  @Type(() => Date)
  readonly verifiedAt: Date;

  @MintProperty()
  @Type(() => Date)
  declare readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  declare readonly updatedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  declare readonly _meta: MintEntityMeta;

  constructor(model?: Partial<BusinessEmailModel>) {
    super(model);
  }
}
