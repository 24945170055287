import {
  MintAccessRoleModel,
  MintEntityMeta,
  MintModel,
  MintObject,
  MintPolicyConsentModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsEmail,
  IsEnum,
  IsInt,
  IsObject,
  IsString,
} from 'class-validator';
import { ClientModel } from '../../client/client.model';
import { ModelValidationError } from '../../main.types';
import { RelationshipModel } from '../../relationship/relationship.model';
import { TaxPeriod } from '../../tax.types';
import { TaxpayerModel } from '../taxpayer.model';
import { TaxpayerConsentStatus } from './taxpayer-consent.types';

@MintResource('/taxpayer-consents')
export class TaxpayerConsentModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => ClientModel)
  readonly client: ClientModel;

  @MintProperty()
  @Type(() => MintPolicyConsentModel)
  readonly policyConsent: MintPolicyConsentModel;

  @MintProperty()
  @Type(() => RelationshipModel)
  readonly relationship: RelationshipModel;

  @MintProperty()
  @Type(() => TaxpayerConsentModel)
  readonly relatedConsent: TaxpayerConsentModel;

  @MintProperty()
  @Type(() => TaxpayerConsentModel)
  readonly supersededBy: TaxpayerConsentModel;

  @MintProperty()
  @Type(() => MintAccessRoleModel)
  readonly role: MintAccessRoleModel;

  @MintProperty()
  @IsEnum(TaxpayerConsentStatus)
  readonly status: TaxpayerConsentStatus;

  @MintProperty()
  @IsObject()
  readonly taxpayerRawModel: MintObject;

  @MintProperty()
  @IsArray()
  readonly taxpayerValidationErrors: ModelValidationError[];

  @MintProperty()
  @IsEmail({ require_tld: false })
  readonly taxpayerEmail: string;

  @MintProperty()
  @IsInt()
  readonly taxStartYear: number;

  @MintProperty()
  @IsInt()
  readonly taxEndYear: number;

  @MintProperty()
  @IsEnum(TaxPeriod)
  readonly taxPeriod: TaxPeriod;

  @MintProperty()
  @Type(() => Date)
  readonly expirationDate: Date;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerConsentModel>) {
    super(model);
  }
}
