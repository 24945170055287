import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MintConfigService, MintLogger } from '@bryllyant/mint-ngx';
import { lastValueFrom } from 'rxjs';

const logger = MintLogger.getLogger('ApiStatusService');

@Injectable({ providedIn: 'root' })
export class ApiStatusService {
  apiBaseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly config: MintConfigService,
  ) {
    this.apiBaseUrl = this.config.getConfig().api?.baseUrl;
  }

  async isOnline(): Promise<boolean> {
    try {
      const response = this.http.get(this.apiBaseUrl, {
        responseType: 'text',
      });
      await lastValueFrom(response);
      return true;
    } catch (err) {
      logger.debug(err);
      return false;
    }
  }
}
