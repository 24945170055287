import { MintError } from '@bryllyant/mint-ngx';
import { plainToInstance, Type } from 'class-transformer';
import { IsArray, IsDefined, IsEnum, IsInt, IsOptional } from 'class-validator';
import { ClientModel } from '../../client/client.model';
import { ProjectModel } from '../../project/project.model';
import { TaxPeriod } from '../../tax.types';
import { TaxpayerRelationship } from '../taxpayer-relationship';
import { TaxpayerModel } from '../taxpayer.model';

export class TaxpayerConsentRequestDto {
  @Type(() => ClientModel)
  @IsDefined()
  readonly client: ClientModel;

  @Type(() => ProjectModel)
  @IsOptional()
  readonly project: ProjectModel;

  @Type(() => TaxpayerModel)
  @IsDefined()
  readonly taxpayer: TaxpayerModel;

  @IsInt()
  @IsDefined()
  readonly taxStartYear: number;

  @IsInt()
  @IsDefined()
  readonly taxEndYear: number;

  @IsEnum(TaxPeriod)
  @IsDefined()
  readonly taxPeriod: TaxPeriod;

  @Type(() => TaxpayerRelationship)
  @IsArray()
  @IsOptional()
  readonly relationships: TaxpayerRelationship[];

  constructor(model?: Partial<TaxpayerConsentRequestDto>) {
    if (model) {
      Object.assign(this, plainToInstance(TaxpayerConsentRequestDto, model));
    }

    if (this.taxStartYear > this.taxEndYear) {
      throw new MintError(
        'taxStartYear must be less than or equal to taxEndYear',
      );
    }
  }
}
