import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsString } from 'class-validator';
import { TaxpayerAuthorizationModel } from '../../taxpayer/authorization/taxpayer-authorization.model';
import { TaxpayerConsentModel } from '../../taxpayer/consent/taxpayer-consent.model';
import { TaxpayerModel } from '../../taxpayer/taxpayer.model';
import { ClientModel } from '../client.model';

@MintResource('/client-authorizations')
export class ClientAuthorizationModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => ClientModel)
  readonly client: ClientModel;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => TaxpayerConsentModel)
  readonly taxpayerConsent: TaxpayerConsentModel;

  @MintProperty()
  @Type(() => TaxpayerAuthorizationModel)
  readonly authorization: TaxpayerAuthorizationModel;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<ClientAuthorizationModel>) {
    super(model);
  }
}
