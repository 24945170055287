<fynvana-modal
  modalTitle="{{ isRevised ? 'Revised' : '' }} {{
    slugToTitleCase(policyType)
  }}"
  [visible]="isVisible"
  [modalIsLoading]="isLoading"
  [closable]="intention === 'view'"
  [hideCancel]="intention === 'view'"
  [submitLabel]="btnLabel"
  (submitted)="
    intention === 'view' ? handleSuccess.emit(policyType) : grantConsent()
  "
  (closed)="
    intention === 'view' ? closeModal.emit() : handleDenied.emit(policyType)
  "
>
  <div
    class="policy-container"
    [innerHTML]="policyContent"
    [hidden]="isLoading"
  ></div>
</fynvana-modal>
