import { MintObject } from '@bryllyant/mint-ngx';

export enum TaxpayerAuthorizationEventType {
  AuthorizationActive = 'authorization-active',
  AuthorizationSuperseded = 'authorization-superseded',
  TaxpayerPending = 'taxpayer-pending',
  TaxpayerRevoked = 'taxpayer-revoked',
  IrsPending = 'irs-pending',
  IrsExpired = 'irs-expired',
  IrsRejected = 'irs-rejected',
  IrsError = 'irs-error',
}

export type TaxpayerAuthorizationEvent = string | MintObject;
