import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsEnum, IsString } from 'class-validator';
import { TaxpayerAuthorizationModel } from '../taxpayer-authorization.model';
import {
  TaxpayerAuthorizationEvent,
  TaxpayerAuthorizationEventType,
} from './taxpayer-authorization-event.types';

@MintResource('/taxpayer-authorization-events')
export class TaxpayerAuthorizationEventModel extends MintModel {
  @MintProperty()
  @IsString()
  override readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerAuthorizationModel)
  readonly authorization: TaxpayerAuthorizationModel;

  @MintProperty()
  @IsEnum(TaxpayerAuthorizationEventType)
  readonly type: TaxpayerAuthorizationEventType;

  @MintProperty()
  readonly event: TaxpayerAuthorizationEvent | TaxpayerAuthorizationEvent[];

  @MintProperty()
  @Type(() => Date)
  readonly eventAt: Date;

  @MintProperty()
  @Type(() => Date)
  override readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  override readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  override readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerAuthorizationEventModel>) {
    super(model);
  }
}
