<div class="header-bg-mask"></div>

<header
  class="header"
  [ngClass]="{ 'header-scrolled': baseService.layoutState.isScrolled }"
>
  <div nz-row class="header-span">
    <!-- Start Header Left -->
    <div nz-col [nzSpan]="4" class="header-left">
      <!-- Start Logo -->
      <div class="logo">
        <span
          class="cursor-pointer"
          [ngClass]="{
            'cursor-initial': !homeMenuItem.routerLink && !homeMenuItem.url
          }"
          (click)="
            homeMenuItem.routerLink || homeMenuItem.url
              ? navigate(homeMenuItem)
              : $event.stopPropagation()
          "
        >
          <svg-icon
            [src]="
              baseService.layoutState.isScrolled ||
              baseService.layoutState.isMobile ||
              baseService.layoutState.viewportSize === ViewportSize.Xl
                ? logoMobilePath
                : logoPath
            "
          ></svg-icon>
        </span>
      </div>
      <!-- End Logo -->
    </div>
    <!-- End Header Left -->

    <!-- Start Header Right -->
    <div class="header-right" nz-col [nzSpan]="20">
      <!-- Start Desktop Nav -->
      <nav
        class="header-nav pr-2"
        *ngIf="
          !this.baseService.layoutState.isMobile &&
          !hideMenu &&
          !isSearchBarVisible
        "
      >
        <ng-container *ngTemplateOutlet="navMenu"></ng-container>
      </nav>

      <!-- Start Button Left -->
      <a
        *ngIf="
          buttonShown &&
          buttonPosition === 'left' &&
          !baseService.layoutState.isMobile
        "
        (click)="navigate(buttonShown)"
        class="header-btn"
      >
        <fynvana-btn [label]="buttonShown.title"></fynvana-btn>
      </a>
      <!-- End Button Left -->

      <!-- Start Search Bar -->
      <section class="search-bar" *ngIf="isSearchBarVisible" [@scaleFromRight]>
        <div class="search-icon">
          <fynvana-icon
            icon="search"
            [height]="25"
            [width]="25"
            color="#00B192"
          ></fynvana-icon>
        </div>

        <input
          nz-input
          type="text"
          placeholder="Search"
          #searchInput
          [(ngModel)]="searchText"
          (ngModelChange)="searchText = $event; search()"
        />

        <div
          class="search-close-icon"
          (click)="clearSearch(); isSearchBarVisible = false"
        >
          <fynvana-icon
            icon="close"
            [height]="12"
            [width]="12"
            color="#0785df"
          ></fynvana-icon>
        </div>

        <!-- Start Search Results -->
        <div class="search-results" *ngIf="searchResults.length">
          <div
            class="search-result"
            *ngFor="let searchResult of searchResults"
            [routerLink]="searchResult.routerLink"
            [queryParams]="searchResult.queryParams"
            (click)="clearSearch()"
          >
            {{ searchResult.label }}
          </div>
        </div>
        <!-- End Search Results -->
      </section>
      <!-- End Search Bar -->

      <!-- Start Optional Toggle Buttons (search, theme, etc ) -->
      <div
        *ngIf="enableSearch || enableColorThemeToggle || enableContactUs"
        class="optional-toggle-btns"
      >
        <fynvana-icon
          *ngIf="enableSearch && !isSearchBarVisible"
          icon="search"
          [height]="25"
          [width]="25"
          color="#ffffff"
          hoverColor="#00B192"
          (click)="toggleSearchBar()"
        ></fynvana-icon>

        <fynvana-icon
          *ngIf="enableColorThemeToggle"
          [icon]="
            baseService.layoutState.colorTheme === 'light' ? 'moon' : 'sun'
          "
          color="#ffffff"
          hoverColor="#00B192"
          nz-tooltip
          nzTooltipPlacement="bottom"
          [nzTooltipTitle]="
            baseService.layoutState.colorTheme === 'light'
              ? 'Enable Dark Mode'
              : 'Enable Light Mode'
          "
          (click)="
            setColorTheme(
              baseService.layoutState.colorTheme === 'light' ? 'dark' : 'light'
            )
          "
        ></fynvana-icon>

        <fynvana-icon
          *ngIf="enableContactUs && contactMenuItem"
          icon="help"
          [height]="25"
          [width]="25"
          color="#ffffff"
          hoverColor="#00B192"
          [routerLink]="contactMenuItem.routerLink"
        ></fynvana-icon>
      </div>
      <!-- End Optional Toggle Buttons-->

      <!-- Start Button Right -->
      <a
        *ngIf="
          buttonShown &&
          buttonPosition === 'right' &&
          !baseService.layoutState.isMobile
        "
        (click)="navigate(buttonShown)"
        class="header-btn"
      >
        <fynvana-btn [label]="buttonShown.title"></fynvana-btn>
      </a>
      <!-- End Button Right -->

      <!-- Start Avatar Menu -->
      <div
        class="profile-avatar"
        *ngIf="avatarMenuItems?.length && !baseService.layoutState.isMobile"
        (offclick)="avatarMenuIsVisible = false"
      >
        <nz-avatar
          nzIcon="user"
          (click)="avatarMenuIsVisible = !avatarMenuIsVisible"
        ></nz-avatar>
        <fynvana-avatar-profile-menu
          *ngIf="avatarMenuIsVisible"
          [user]="user"
          [isVisible]="avatarMenuIsVisible"
          [avatarMenuItems]="avatarMenuItems"
          (toggleAvatarMenu)="avatarMenuIsVisible = !avatarMenuIsVisible"
        ></fynvana-avatar-profile-menu>
      </div>
      <!-- End Avatar Menu -->

      <fynvana-icon
        icon="menu-lines"
        class="light-action-icon mobile-toggle-btn"
        *ngIf="
          baseService.layoutState.isMobile && menuItems.length && !hideMenu
        "
        (click)="toggleMobileMenu()"
      ></fynvana-icon>
      <!-- End Desktop Nav -->
    </div>
    <!-- End Header Right -->
  </div>
</header>

<!-- Start Mobile Nav -->
<section class="header-mobile-nav">
  <div
    class="mobile-background-overlay"
    *ngIf="baseService.layoutState.isMobileMenuOpen"
    [@fadeInOut]
    (click)="toggleMobileMenu()"
  ></div>

  <nav
    class="mobile-nav"
    [@slideInOut]="baseService.layoutState.isMobileMenuOpen ? 'show' : 'hide'"
  >
    <div class="mobile-menu-header">
      <fynvana-icon
        icon="close"
        class="close-mobile-nav-icon"
        (click)="toggleMobileMenu()"
      ></fynvana-icon>
    </div>

    <ng-container *ngTemplateOutlet="navMenu"></ng-container>
  </nav>
</section>
<!-- End Mobile Nav -->

<!-- Start Menu Template -->
<ng-template #navMenu>
  <ul
    nz-menu
    [nzMode]="baseService.layoutState.isMobile ? 'inline' : 'horizontal'"
  >
    <ng-container *ngFor="let menuItem of menuItems; let i = index">
      <!-- Start Menu Item -->
      <li
        *ngIf="!menuItem.children"
        nz-menu-item
        routerLinkActive
        #rla="routerLinkActive"
        [nzSelected]="rla.isActive"
        [routerLink]="menuItem.routerLink"
        (click)="
          navigate(menuItem);
          baseService.layoutState.isMobile
            ? toggleMobileMenu()
            : $event.stopPropagation();
          subMenusOpenToggle()
        "
        class="non-submenu-link"
      >
        <a>
          {{ menuItem.title }}
        </a>
      </li>
      <!-- End Menu Item -->

      <!-- Start Sub Menu -->
      <li
        nz-submenu
        *ngIf="menuItem.children"
        [nzTitle]="menuItem.title || ''"
        [nzIcon]="'down'"
        [(nzOpen)]="baseService.layoutState.isMobile && subMenuOpenMap[i]"
        (nzOpenChange)="subMenusOpenToggle(i)"
      >
        <ul>
          <li nz-menu-group>
            <ul>
              <ng-container *ngFor="let child of menuItem.children">
                <li
                  nz-menu-item
                  routerLinkActive
                  #rla="routerLinkActive"
                  [nzSelected]="rla.isActive"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [routerLink]="child.routerLink"
                  (click)="
                    navigate(child);
                    baseService.layoutState.isMobile
                      ? toggleMobileMenu()
                      : $event.stopPropagation()
                  "
                >
                  <a>
                    {{ child.title }}
                  </a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </li>
      <!-- End Sub Menu -->
    </ng-container>

    <!-- Start Button (for mobile menu) -->
    <li nz-menu-item *ngIf="baseService.layoutState.isMobile && buttonShown">
      <a (click)="navigate(buttonShown)">
        <span>{{ buttonShown.title }}</span>
      </a>
    </li>
    <!-- End Button (for mobile menu) -->
  </ul>
</ng-template>
<!-- End Menu Template -->
