import { MintFormFieldOption } from '@bryllyant/mint-ngx';

export enum BusinessOwnership {
  Exempt = 'exempt',
  Excluded = 'excluded',
  NonProfit = 'non-profit',
  LegalEntity = 'legal-entity',
}

export enum BusinessType {
  LLC = 'limited-liability-company',
  GeneralPartnership = 'general-partnership',
  LimitedPartnership = 'limited-partnership',
  SoleProprietorship = 'sole-proprietorship',
  C_Corporation = 'c-corporation',
  S_Corporation = 's-corporation',
  NonProfit = 'non-profit',
  // InvestmentFund = 'investment-fund',
  Trust = 'trust',
  // NativeAmericanTribe = 'native-american-tribe',
  IndependentContractor = 'independent-contractor',
  SelfEmployed = 'self-employed',
  // Other = 'other',
}

export enum BusinessRole {
  Owner = 'owner',
  AuthorizedSigner = 'authorized-signer',
  OwnerAndAuthorizedSigner = 'owner-and-authorized-signer',
}

export const businessRoleOptions: MintFormFieldOption[] = [
  {
    name: 'Owner Only',
    value: BusinessRole.Owner,
  },
  {
    name: 'Authorized Signer Only',
    value: BusinessRole.AuthorizedSigner,
  },
  {
    name: 'Owner and Authorized Signer',
    value: BusinessRole.OwnerAndAuthorizedSigner,
  },
];
