export enum TaxpayerConsentStatus {
  // taxpayer clicked link and viewed the consent request
  TaxpayerViewed = 'taxpayer-viewed',

  // new taxpayer; waiting on 8821 signature
  TaxpayerPendingAuthorization = 'taxpayer-pending-authorization',

  TaxpayerGranted = 'taxpayer-granted',
  TaxpayerRejected = 'taxpayer-rejected',
  TaxpayerRevoked = 'taxpayer-revoked',

  ClientRequested = 'client-requested',
  ClientWithdrawn = 'client-withdrawn',

  // new taxpayer; submitted 8821 to IRS and waiting on API activation
  // this relates to TaxpayerAuthorizationStatus
  IrsPendingActivation = 'irs-pending-activation',

  ConsentExpired = 'consent-expired',
  ConsentSuperseded = 'consent-superseded',
}
