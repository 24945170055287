import {
  MintAddressModel,
  MintEmailType,
  MintEntityMeta,
  MintMediaModel,
  MintModel,
  MintObject,
  MintPhoneType,
  MintProfileEyeColor,
  MintProfileGender,
  MintProfileMaritalStatus,
  MintProfileRace,
  MintProperty,
  MintResource,
  MintSocialLink,
  MintTag,
  mintTagModelTransformer,
  MintUserModel,
  MintUserProfileModel,
} from '@bryllyant/mint-ngx';
import { Transform, Type } from 'class-transformer';
import {
  IsArray,
  IsEmail,
  IsEnum,
  IsLocale,
  IsNumber,
  IsObject,
  IsPhoneNumber,
  IsString,
  IsUrl,
} from 'class-validator';
import { TaxIdType } from '../main.types';

@MintResource('/individuals')
export class IndividualModel
  extends MintModel
  implements Partial<MintUserProfileModel>
{
  @MintProperty()
  @IsString()
  declare readonly uid: string;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly user: MintUserModel;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly photo: MintMediaModel;

  @MintProperty()
  @Type(() => MintAddressModel)
  readonly address: MintAddressModel;

  @MintProperty()
  @IsString()
  readonly prefix: string;

  @MintProperty()
  @IsString()
  readonly firstName: string;

  @MintProperty()
  @IsString()
  readonly middleName: string;

  @MintProperty()
  @IsString()
  readonly maidenName: string;

  @MintProperty()
  @IsString()
  readonly lastName: string;

  @MintProperty()
  @IsString({ each: true })
  readonly otherLastNames: string[];

  @MintProperty()
  @IsString()
  readonly suffix: string;

  @MintProperty()
  @IsString()
  readonly preferredName: string;

  @MintProperty()
  @IsString()
  readonly fullName: string;

  @MintProperty()
  @IsString()
  readonly legalName: string;

  @MintProperty()
  @IsString()
  readonly taxId: string;

  @MintProperty()
  @IsEnum(TaxIdType)
  readonly taxIdType: TaxIdType;

  @MintProperty()
  @IsEnum(MintProfileGender)
  readonly gender: MintProfileGender;

  @MintProperty()
  @IsEnum(MintProfileRace)
  readonly race: MintProfileRace;

  @MintProperty()
  @IsEnum(MintProfileMaritalStatus)
  readonly maritalStatus: MintProfileMaritalStatus;

  @MintProperty()
  @IsNumber()
  readonly heightCm: number;

  @MintProperty()
  @IsNumber()
  readonly weightKg: number;

  @MintProperty()
  @IsEnum(MintProfileEyeColor)
  readonly eyeColor: MintProfileEyeColor;

  @MintProperty()
  @Type(() => Date)
  readonly birthDate: Date;

  @MintProperty()
  @IsLocale()
  readonly locale: string;

  @MintProperty()
  @IsString()
  readonly timeZone: string;

  @MintProperty()
  @IsEmail({ require_tld: false })
  readonly email: string;

  @MintProperty()
  @IsEnum(MintEmailType)
  readonly emailType: MintEmailType;

  @MintProperty()
  @IsPhoneNumber()
  readonly phoneNumber: string;

  @MintProperty()
  @IsString()
  readonly phoneExtension: string;

  @MintProperty()
  @IsEnum(MintPhoneType)
  readonly phoneType: MintPhoneType;

  @MintProperty()
  @IsUrl({ require_tld: false })
  readonly webUrl: string;

  @MintProperty()
  @IsArray()
  readonly socialLinks: MintSocialLink[];

  @MintProperty()
  @IsArray()
  @Transform(mintTagModelTransformer)
  readonly tags: MintTag[];

  @MintProperty()
  @IsObject()
  readonly context: MintObject;

  @MintProperty()
  @IsString()
  readonly description: string;

  @MintProperty()
  @Type(() => Date)
  declare readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  declare readonly updatedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  declare readonly _meta: MintEntityMeta;

  constructor(model?: Partial<IndividualModel>) {
    super(model);
  }
}
