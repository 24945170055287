import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MintLogger, MintQuerySort } from '@bryllyant/mint-ngx';
import { BaseController, BaseService, TableHeadCol } from '@fynvana/common';

const logger = MintLogger.getLogger('TableComponent');

@Component({
  selector: 'fynvana-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends BaseController {
  @Input() cols: TableHeadCol[];
  @Input() rows: any[] | undefined;

  @Input() isListLoading = false;
  @Input() totalItems: number;
  @Input() pageLimit: number;
  @Input() pageIndex: number;
  @Input() sort: MintQuerySort | undefined;

  @Input() hideNoDataDisplay = false;
  @Input() noDataText = 'No data to display';
  @Input() dynamicData = true; // Whether data is being paginated by the server or locally
  @Input() disablePagination = false;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() sortChanged = new EventEmitter<string | any>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }
}
