<!-- Start Menu Backdrop -->
<div
  class="sidebar-nav-backdrop"
  [@navSlideAni]="
    baseService.layoutState.isNavSideBarOpen
      ? 'show'
      : baseService.layoutState.isMobile
        ? 'hideMobile'
        : 'hide'
  "
></div>
<!-- End Menu Backdrop -->

<!-- Start Nav Sidebar -->
<main
  class="sidebar-nav-container"
  [ngClass]="{
    'is-scrolled': baseService.layoutState.isScrolled
  }"
>
  <!-- Start Menu -->
  <div
    class="sidebar-nav"
    [@navSlideAni]="
      baseService.layoutState.isNavSideBarOpen
        ? 'show'
        : baseService.layoutState.isMobile
          ? 'hideMobile'
          : 'hide'
    "
  >
    <div
      class="nav-title"
      *ngIf="navTitle && baseService.layoutState.isNavSideBarOpen"
    >
      {{ navTitle }}
    </div>

    <nav
      class="sidebar-navigation"
      [ngClass]="{
        'mt-1': !navTitle || !baseService.layoutState.isNavSideBarOpen
      }"
    >
      <ul nz-menu [nzMode]="'inline'">
        <ng-container *ngFor="let menuItem of menuItems; let i = index">
          <!-- Start Nav Open View -->
          <ng-container *ngIf="baseService.layoutState.isNavSideBarOpen">
            <!-- Start Special Items (separators, etc) -->
            <ng-container *ngIf="menuItem.data?.type === 'separator'">
              <div class="menu-separator">
                <hr />
              </div>
            </ng-container>
            <!-- End Special Items -->

            <!-- Start Normal Menu Items -->
            <ng-container *ngIf="menuItem.data?.type !== 'separator'">
              <!-- Start Menu Item -->
              <li
                *ngIf="!menuItem.children"
                nz-menu-item
                routerLinkActive="active-link"
                [routerLink]="menuItem.routerLink"
                class="single-nav-item top-level-nav-item"
                (click)="
                  subMenusOpenToggle();
                  toggleMobileSideBar();
                  navigate(menuItem)
                "
              >
                <fynvana-icon [icon]="menuItem.icon || ''"></fynvana-icon>
                <a>
                  {{ menuItem.title }}
                </a>
              </li>
              <!-- End Menu Item -->

              <!-- Start Sub Menus -->
              <ng-template #subMenuTitle>
                <fynvana-icon [icon]="menuItem.icon || ''"></fynvana-icon>
                <div class="submenu-title">
                  {{ menuItem.title }}
                </div>
              </ng-template>

              <li
                nz-submenu
                *ngIf="menuItem.children"
                [nzTitle]="subMenuTitle"
                [nzIcon]="'down'"
                [(nzOpen)]="subMenuOpenMap[i]"
                (nzOpenChange)="onParentMenuToggle($event, menuItem, i)"
                class="top-level-nav-item"
                [ngClass]="{
                  'active-parent-link': determineIsParentMenuActive(menuItem)
                }"
              >
                <ul>
                  <li nz-menu-group>
                    <ul>
                      <ng-container *ngFor="let child of menuItem.children">
                        <!-- Start Non-Sub Nested Items -->
                        <ng-container
                          *ngIf="!child.children || !child.children?.length"
                        >
                          <li
                            nz-menu-item
                            routerLinkActive="active-link"
                            class="child-item"
                            [routerLink]="child.routerLink"
                            (click)="toggleMobileSideBar(); navigate(child)"
                            nz-tooltip
                            [nzTooltipTitle]="
                              child.title && child.title.length > 20
                                ? child.title
                                : null
                            "
                            nzTooltipPlacement="right"
                          >
                            <a>
                              {{ truncateString(child.title ?? '', 20) }}
                            </a>
                          </li>
                        </ng-container>
                        <!-- Start Non-Sub Nested Items -->

                        <!-- Started Nested Sub Menu -->
                        <ng-container
                          *ngIf="child.children && child.children.length"
                        >
                          <ng-template #nestedSubMenuTitle>
                            <div class="submenu-title">
                              {{ child.title }}
                            </div>
                          </ng-template>

                          <li
                            nz-submenu
                            *ngIf="child.children"
                            [nzTitle]="nestedSubMenuTitle"
                            [nzIcon]="'down'"
                            [(nzOpen)]="
                              nestedSubMenusOpenMap[child.title || '']
                            "
                            (nzOpenChange)="onNestedMenuToggle($event, child)"
                            class="sub-nested-level-nav-item"
                            [ngClass]="{
                              'active-nested-link':
                                determineIsParentMenuActive(child)
                            }"
                          >
                            <ul>
                              <li nz-menu-group>
                                <ul>
                                  <ng-container
                                    *ngFor="let subChild of child.children"
                                  >
                                    <li
                                      nz-menu-item
                                      routerLinkActive="active-link"
                                      class="child-item sub-nested-child"
                                      [routerLink]="subChild.routerLink"
                                      (click)="
                                        toggleMobileSideBar();
                                        navigate(subChild)
                                      "
                                      nz-tooltip
                                      [nzTooltipTitle]="
                                        subChild.title &&
                                        subChild.title.length > 20
                                          ? subChild.title
                                          : null
                                      "
                                      nzTooltipPlacement="right"
                                    >
                                      <a>
                                        {{
                                          truncateString(
                                            subChild.title ?? '',
                                            20
                                          )
                                        }}
                                      </a>
                                    </li>
                                  </ng-container>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ng-container>
                        <!-- End Nested Sub Menu -->
                      </ng-container>
                    </ul>
                  </li>
                </ul>
              </li>
              <!-- End Sub Menu -->
            </ng-container>
            <!-- End Normal Menu Items -->
          </ng-container>
          <!-- End Nav Open View -->

          <!-- Start Nav Closed View -->
          <ng-container *ngIf="!baseService.layoutState.isNavSideBarOpen">
            <!-- Start Special Items (separators, etc) -->
            <ng-container *ngIf="menuItem.data?.type === 'separator'">
              <div class="menu-separator closed">
                <hr />
              </div>
            </ng-container>
            <!-- End Special Items -->

            <!-- Start Normal Menu Items -->
            <ng-container *ngIf="menuItem.data?.type !== 'separator'">
              <div class="nav-closed-nav-item">
                <fynvana-icon
                  [icon]="menuItem.icon || ''"
                  nz-tooltip
                  [nzTooltipTitle]="menuItem.title"
                  nzTooltipPlacement="right"
                  (click)="subMenusOpenToggle(i); navigate(menuItem)"
                  [routerLink]="menuItem.routerLink"
                  routerLinkActive="active-link"
                ></fynvana-icon>
              </div>
            </ng-container>
            <!-- End Normal Menu Items -->
          </ng-container>
          <!-- End Nav Closed View -->
        </ng-container>
      </ul>
    </nav>
  </div>
  <!-- End Menu -->

  <!-- Start Open/Close Drawer Button -->
  <div
    class="collapse-btn-col"
    [ngClass]="{
      'is-scrolled': baseService.layoutState.isScrolled,
      'is-nav-hidden': !baseService.layoutState.isNavSideBarOpen,
      'is-nav-hidden-mobile':
        !baseService.layoutState.isNavSideBarOpen &&
        baseService.layoutState.isMobile
    }"
  >
    <img
      src="./assets/common/imgs/icons/toggle-drawer-arrows.svg"
      (click)="
        baseService.layoutState.isNavSideBarOpen =
          !baseService.layoutState.isNavSideBarOpen
      "
    />
  </div>
  <!-- End Open/Close Drawer Button -->
</main>
<!-- End Nav Sidebar -->
