import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { TaxpayerConsentModel, TaxpayerConsentStatus } from '@fynvana/core';
import { Type } from 'class-transformer';
import { IsEnum, IsString } from 'class-validator';

@MintResource('/taxpayer-consent-logs')
export class TaxpayerConsentLogModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerConsentModel)
  readonly taxpayerConsent: TaxpayerConsentModel;

  @MintProperty()
  @IsEnum(TaxpayerConsentStatus)
  readonly status: TaxpayerConsentStatus;

  @MintProperty()
  @Type(() => Date)
  readonly changedAt: Date;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly changedBy: MintUserModel;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerConsentLogModel>) {
    super(model);
  }
}
