import { MetaDefinition } from '@angular/platform-browser';
import {
  MintAuthChannel,
  MintFormController,
  MintFormFieldOption,
  MintMenuItem,
  MintQueryOperator,
} from '@bryllyant/mint-ngx';

// Generic
export interface KeyValPairs {
  [key: string]: any;
}

export interface NameValPair {
  name: string;
  value: any;
}

export interface AlertOptions {
  success?: string;
  error?: string;
  duration?: number;
}

export interface LoadingOptions {
  successMessage?: string;
  errorMessage?: string;
  disableErrorToast?: boolean;
  disableGlobalLoad?: boolean;
  globalLoadingMessage?: string;
}

export interface SubmittingOptions extends LoadingOptions {
  disableFormReset?: boolean;
  disableStatusAlertReset?: boolean;
}

export enum AppIdentifier {
  Website = 'website',
  Admin = 'admin',
  Developer = 'developer',
  Docs = 'docs',
  Client = 'client',
  Taxpayer = 'taxpayer',
}

export enum ColorTheme {
  Dark = 'dark',
  Light = 'light',
}

export enum ViewportSize {
  Full = 'full',
  Xl = 'extra-large',
  Lg = 'large',
  Md = 'medium',
  Sm = 'small',
}

export interface LayoutState {
  viewportSize: ViewportSize;
  isMobile: boolean;
  isScrolled: boolean;
  isMobileMenuOpen: boolean;
  isNavSideBarOpen: boolean;
  colorTheme: ColorTheme;
  globalLoadingMessage: string | undefined;
}

export interface MenuItem extends MintMenuItem {
  slug?: string;
  children?: MenuItem[];
}

export interface InfoListItem {
  title: string;
  description: string;
  routerLink?: string;
  icon?: string;
  image?: string;
}

export enum StatusType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export interface StatusAlert {
  slug?: string;
  message: string | undefined;
  type: StatusType;
}

// List
export interface ListTextSearchFilter {
  fields: string[];
  value: any;
  operator?: MintQueryOperator;
}

export interface ListSelectFilter {
  field: string;
  options: MintFormFieldOption[];
  value: any;
  operator?: MintQueryOperator;
  placeholder: string;
}

export enum ListDateFilterType {
  DateOf = 'day-of',
  AfterDate = 'after-date',
  BeforeDate = 'before-date',
}

export interface ListDateFilter {
  field: string;
  value: any;
  operator?: MintQueryOperator;
  placeholder: string;
  type: ListDateFilterType;
}

export interface DynamicListFilters {
  textFilter: ListTextSearchFilter;
  selectFilters: ListSelectFilter[];
  dateFilters?: ListDateFilter[];
}

export enum LogoutType {
  UserTriggered = 'user-triggered',
  TokenExpired = 'token-expired',
  UserTimeOut = 'user-timeout',
  UnauthorizedAccess = 'unauthorized-access',
}

// Form
export interface MintFormAndFields {
  form: MintFormController;
  fields: any;
}

// Modal
export enum ModalSize {
  Large = 'large',
  Small = 'small',
}

// Auth
export interface TwoFactorChannel {
  type: MintAuthChannel;
  address?: string; // email or mobile
}

export enum AuthSection {
  Login = 'login',
  SignUp = 'signup',
  ResetPassword = 'reset-password',
}

// SEO
export interface SiteMetaDataOverrides {
  description?: string;
  lang?: string;
  meta?: MetaDefinition[];
  title?: string;
  customImg?: string | null;
  isArticle?: boolean;
}

export interface SiteMetaData {
  title: string;
  siteUrl: string;
  description: string;
  author: string;
  twitterUsername: string;
  isArticle: boolean;
}

export interface DefaultSEOImgs {
  ogImg?: any;
  twitterImg?: any;
}

// Blog
export interface BlogPost {
  slug?: string;
  title?: string;
  subtitle?: string;
  blogImage?: string;
  socialImage?: string;
  headerBackgroundStyle?: string; // rgba(0,0,0,0.6)
  category?: string;
  tags?: string[];
  date?: string | Date;
  lastUpdated?: string | Date;
  summary?: string;
  markdownPath?: string;
  markdown?: string;
}

export interface BlogPostWithPoints {
  post: BlogPost;
  points: number;
}

// Search
export interface SearchableItem {
  label: string;
  searchableText: string;
  description?: string;
  routerLink: string;
  queryParams?: KeyValPairs;
}

export interface TableHeadCol {
  width?: string;
  label: string;
  field?: string;
  sortable?: boolean;
  centerLabel?: boolean;
  tip?: string;
  disableColTooltip?: boolean;
}

export interface BtnItem {
  label: string;
  colorStyle?: 'fill' | 'outline';
}

export enum ErrorPageType {
  AccountLockedMaxFailedAttempts = 'account-locked-max-failed-attempts',
  AccountLocked = 'account-locked',
  DeviceLocked = 'device-locked',
  ServiceUnavailable = '503-unavailable',
  ServiceMaintenance = '503-maintenance',
  PageNotFound = '404',
}

export interface AppVersions {
  frontend: string;
  backend: string;
}

export enum RegistrationType {
  Client = 'client',
  Taxpayer = 'taxpayer',
  Developer = 'developer',
}
