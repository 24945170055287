import { TaxpayerOnboardingModel } from './taxpayer-onboarding.model';
import {
  TaxpayerOnboardingContext,
  TaxpayerOnboardingStep,
  TaxpayerOnboardingStepStatus,
} from './taxpayer-onboarding.types';

export class TaxpayerOnboardingUtil {
  static STEP_TO_URL_MAP: Map<TaxpayerOnboardingStep, string> = new Map([
    [TaxpayerOnboardingStep.PersonalDetails, '/onboarding/personal-details'],
    [TaxpayerOnboardingStep.BusinessDetails, '/onboarding/business-details'],
    [TaxpayerOnboardingStep.RequestSummary, '/onboarding/request-summary'],
    [TaxpayerOnboardingStep.Consent, '/onboarding/consent'],
    // [TaxpayerOnboardingStep.SignDocuments, '/onboarding/sign-documents'],
  ]);

  static URL_TO_STEP_MAP: Map<string, TaxpayerOnboardingStep> = new Map([
    ['/onboarding/personal-details', TaxpayerOnboardingStep.PersonalDetails],
    ['/onboarding/business-details', TaxpayerOnboardingStep.BusinessDetails],
    ['/onboarding/request-summary', TaxpayerOnboardingStep.RequestSummary],
    ['/onboarding/consent', TaxpayerOnboardingStep.Consent],
    // ['/onboarding/sign-documents', TaxpayerOnboardingStep.SignDocuments],
  ]);

  static getCurrentStep(
    taxpayerOnboardingContext: TaxpayerOnboardingContext,
  ): TaxpayerOnboardingStep {
    let currentStep = TaxpayerOnboardingStep.PersonalDetails;

    for (const key of Object.keys(taxpayerOnboardingContext)) {
      const onboardingKey = key as keyof TaxpayerOnboardingContext;
      const value = taxpayerOnboardingContext[onboardingKey];

      if (value?.status === TaxpayerOnboardingStepStatus.Current) {
        currentStep = onboardingKey;
        break;
      }
    }

    return currentStep;
  }

  static getContextValue(args: {
    taxpayerOnboarding: TaxpayerOnboardingModel;
    step: TaxpayerOnboardingStep;
    subStep?: TaxpayerOnboardingStep;
    key?: string;
    businessIndex?: number;
  }): any {
    if (!args.taxpayerOnboarding) {
      return null;
    }

    const { taxpayerOnboarding, step, subStep, key } = args;
    const businessIndex = args.businessIndex ?? 0;
    const { context } = taxpayerOnboarding as any;

    let found: any = {};

    if (subStep) {
      if (step === TaxpayerOnboardingStep.BusinessDetails) {
        found = context[step]?.businesses?.[businessIndex]?.[subStep] ?? {};
      } else {
        found = context[step]?.[subStep] ?? {};
      }
    } else {
      found = context[step] ?? {};
    }

    return key ? found[key] ?? null : found;
  }

  static isBusinessContextComplete(
    context: TaxpayerOnboardingContext,
    businessIndex: number,
  ): boolean {
    const businesses =
      context?.[TaxpayerOnboardingStep.BusinessDetails]?.businesses?.[
        businessIndex
      ] ?? {};

    return businesses.status === TaxpayerOnboardingStepStatus.Complete;
  }

  static areAllBusinessContextsComplete(context: TaxpayerOnboardingContext) {
    const businesses =
      context?.[TaxpayerOnboardingStep.BusinessDetails]?.businesses ?? [];

    let isComplete = true;

    for (let i = 0; i < businesses.length; i++) {
      if (!this.isBusinessContextComplete(context, i)) {
        isComplete = false;
      }
    }

    return isComplete;
  }

  static getCurrentBusinessIndex(context: TaxpayerOnboardingContext) {
    const businesses =
      context?.[TaxpayerOnboardingStep.BusinessDetails]?.businesses ?? [];

    let index = 0;

    for (let i = 0; i < businesses.length; i++) {
      if (!this.isBusinessContextComplete(context, i)) {
        index = i;
      }
    }

    return index;
  }
}
