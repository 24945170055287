import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsBoolean, IsEmail, IsEnum, IsString } from 'class-validator';
import { BusinessModel } from '../business/business.model';
import { IndividualModel } from '../individual/individual.model';
import { TaxIdType } from '../main.types';
import { TaxpayerAuthorization } from './taxpayer-authorization';
import { TaxpayerType } from './taxpayer.types';

@MintResource('/taxpayers')
export class TaxpayerModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @IsEnum(TaxpayerType)
  readonly type: TaxpayerType;

  @MintProperty()
  @Type(() => IndividualModel)
  readonly individual: IndividualModel;

  @MintProperty()
  @Type(() => BusinessModel)
  readonly business: BusinessModel;

  @MintProperty()
  @IsString()
  readonly firstName: string;

  @MintProperty()
  @IsString()
  readonly lastName: string;

  @MintProperty()
  @IsString()
  readonly legalName: string;

  @MintProperty()
  @IsString()
  readonly slug: string;

  @MintProperty()
  @IsString()
  readonly taxId: string;

  @MintProperty()
  @IsEnum(TaxIdType)
  readonly taxIdType: TaxIdType;

  @MintProperty()
  @IsEmail({ require_tld: false })
  readonly email: string;

  @MintProperty()
  @IsBoolean()
  readonly isSignerOnly: boolean;

  @MintProperty()
  @Type(() => TaxpayerAuthorization)
  readonly authorization: TaxpayerAuthorization;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<TaxpayerModel>) {
    super(model);
  }
}
