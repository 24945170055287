import { Component, OnInit } from '@angular/core';
import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService } from '@fynvana/common';

const logger = new MintLogger('AppComponent');

@Component({
  selector: 'fynvana-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent extends BaseController implements OnInit {
  constructor(public baseService: BaseService) {
    super(baseService);

    this.setSEO(
      {},
      {
        twitterImg: 'assets/seo/twitter-image.jpg',
        ogImg: 'assets/seo/og-image.jpg',
      },
    );
  }

  async ngOnInit() {
    this.baseService.seoService.initGoogleTagManager(
      this.baseService.config.analytics?.google?.trackingId,
    );
  }
}
