import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsEnum } from 'class-validator';
import { ClientModel } from '../client/client.model';
import { ClientUserType } from './client-user.types';

@MintResource('/client-users')
export class ClientUserModel extends MintModel {
  @MintProperty()
  readonly uid: string;

  @MintProperty()
  @Type(() => MintUserModel)
  readonly user: MintUserModel;

  @MintProperty()
  @Type(() => ClientModel)
  readonly client: ClientModel;

  @MintProperty()
  @IsEnum(ClientUserType)
  readonly type: ClientUserType;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<ClientUserModel>) {
    super(model);
  }
}
