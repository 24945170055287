import {
  MintEntityMeta,
  MintMediaModel,
  MintModel,
  MintObject,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsEnum, IsObject, IsString } from 'class-validator';
import { AgentModel } from '../agent/agent.model';
import { TaxpayerModel } from '../taxpayer/taxpayer.model';
import {
  IrsFormDocusignSigners,
  IrsFormStatus,
  IrsFormType,
} from './irs-form.types';

@MintResource('/irs-forms')
export class IrsFormModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => AgentModel)
  readonly agent: AgentModel;

  @MintProperty()
  @Type(() => IrsFormModel)
  readonly supersededBy: IrsFormModel;

  @MintProperty()
  @IsEnum(IrsFormType)
  readonly type: IrsFormType;

  @MintProperty()
  @IsEnum(IrsFormStatus)
  readonly status: IrsFormStatus;

  @MintProperty()
  @IsString()
  readonly formRevision: string;

  @MintProperty()
  @IsObject()
  readonly formFields: MintObject;

  @MintProperty()
  @IsObject()
  readonly docusignSigners: IrsFormDocusignSigners;

  @MintProperty()
  @IsString()
  readonly docusignEnvelopeId: string;

  @MintProperty()
  @IsString()
  readonly docusignDocumentId: string;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly generatedFile: MintMediaModel;

  @MintProperty()
  @Type(() => Date)
  readonly generatedAt: Date;

  @MintProperty()
  @Type(() => MintMediaModel)
  readonly executedFile: MintMediaModel;

  @MintProperty()
  @Type(() => Date)
  readonly executedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<IrsFormModel>) {
    super(model);
  }
}
