import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { ClientAuthorizationModel } from './client-authorization.model';

const logger = MintLogger.getLogger('ClientAuthorizationService');

@Injectable({ providedIn: 'root' })
export class ClientAuthorizationService extends MintService<ClientAuthorizationModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      ClientAuthorizationModel,
      ClientAuthorizationModel._mintResourceName,
    );
  }
}
