<button
  nz-button
  [ngClass]="classNames"
  [nzType]="type"
  [nzSize]="size"
  [nzShape]="shape"
  [disabled]="disabled"
  [nzLoading]="loading"
>
  <fynvana-icon *ngIf="icon" [icon]="icon" color="#ffffff"></fynvana-icon>
  <span>{{ label || 'Ok' }}</span>
</button>
