import { Injectable } from '@angular/core';

import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';

import { BusinessModel } from './business.model';

const logger = MintLogger.getLogger('BusinessService');

@Injectable({ providedIn: 'root' })
export class BusinessService extends MintService<BusinessModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      BusinessModel,
      BusinessModel._mintResourceName,
    );
  }
}
