import * as dayjs from 'dayjs';

import {
  DeepPartial,
  isUndefined,
  MintDebouncer,
  MintLogger,
  MintModel,
  MintQueryCondition,
  MintQueryFilter,
  MintQueryOperator,
  MintQuerySort,
  MintQuerySortOrder,
  MintService,
} from '@bryllyant/mint-ngx';
import {
  DynamicListFilters,
  ListDateFilterType,
  LoadingOptions,
  TableHeadCol,
} from '../types';

import { BaseController } from './base.controller';
import { BaseService } from './base.service';

const logger = MintLogger.getLogger('ListController');

export abstract class ListController<
  MODEL extends MintModel = any,
> extends BaseController {
  isListLoading = false;
  limit = 10;
  pageNumber = 1;
  offset = 0;
  totalItems = 0;

  staticFilters = new MintQueryCondition<MODEL>();

  dynamicFilters: DynamicListFilters = {
    textFilter: { fields: [], value: undefined },
    selectFilters: [],
    dateFilters: [],
  };
  sort: MintQuerySort<MODEL> = {
    field: 'createdAt',
    order: MintQuerySortOrder.Desc,
  };

  cols: TableHeadCol[];
  data: MODEL[];

  additionalDataMap: Map<string, any> = new Map();
  expandMap = new Map();
  selectedItem: MODEL | null;

  protected constructor(
    public baseService: BaseService,
    public dataService?: MintService<MODEL>,
  ) {
    super(baseService);
    this.debouncer = new MintDebouncer(250);
  }

  /** Builds mint compatible filter rule based off staticFilters and dynamicFilters. */
  get filters(): MintQueryFilter<MODEL> {
    const ruleSet = new MintQueryFilter<MODEL>();
    const baseFilter = new MintQueryCondition<MODEL>();
    const { textFilter, selectFilters, dateFilters } = this.dynamicFilters;

    if (this.staticFilters?.length) {
      for (const filter of this.staticFilters) {
        baseFilter.addExpression(filter);
      }
    }

    if (!this.dynamicFilters) {
      ruleSet.addCondition(baseFilter);
      return ruleSet;
    }

    if (selectFilters) {
      for (const select of selectFilters) {
        if (!isUndefined(select.value)) {
          baseFilter.addExpression({
            key: select.field,
            operator: select.operator ?? MintQueryOperator.Equals,
            value: select.value,
          });
        }
      }
    }

    if (dateFilters) {
      // TODO: support time filters as well?
      for (const date of dateFilters) {
        if (!isUndefined(date.value)) {
          const buildDateFilter = (type: 'before' | 'after' | 'between') => {
            let value: string | string[] = `${dayjs(
              date.value as string,
            ).format('YYYY-MM-DD')}T00:00:00.411Z`;
            let operator: MintQueryOperator;

            switch (type) {
              case 'before':
                operator = MintQueryOperator.LessThanOrEquals;
                break;
              case 'after':
                operator = MintQueryOperator.GreaterThanOrEquals;
                break;
              case 'between':
                operator = MintQueryOperator.Between;
                value = [
                  `${dayjs(date.value as string).format(
                    'YYYY-MM-DD',
                  )}T00:00:00.411Z`,
                  `${dayjs(date.value as string).format(
                    'YYYY-MM-DD',
                  )}T23:59:59.411Z`,
                ];
                break;
            }

            return {
              key: date.field,
              operator,
              value,
            };
          };

          switch (date.type) {
            case ListDateFilterType.DateOf:
              baseFilter.addExpression(buildDateFilter('between'));
              break;
            case ListDateFilterType.BeforeDate:
              baseFilter.addExpression(buildDateFilter('before'));
              break;
            case ListDateFilterType.AfterDate:
              baseFilter.addExpression(buildDateFilter('after'));
              break;
          }
        }
      }
    }

    if (textFilter?.value) {
      const { value, fields } = textFilter;
      const operator =
        this.dynamicFilters.textFilter.operator ??
        MintQueryOperator.LikeIgnoreCase;

      if (
        fields.includes('firstName') &&
        fields.includes('lastName') &&
        value.split(' ').length > 1
      ) {
        const valueParts = value.split(' ');
        const nameCondition = MintQueryCondition.from(baseFilter);

        nameCondition.addExpression({
          key: 'firstName',
          operator: operator,
          value: valueParts[0],
        });

        nameCondition.addExpression({
          key: 'lastName',
          operator: operator,
          value: valueParts[1],
        });

        ruleSet.addCondition(nameCondition);
      }

      for (const field of fields) {
        if (
          value.split(' ').length > 1 &&
          ((field === 'firstName' && fields.includes('lastName')) ||
            (field === 'lastName' && fields.includes('firstName')))
        ) {
          continue;
        }

        // addQueryCondition mutates filter so create local copy of baseFilter
        const filter = MintQueryCondition.from(baseFilter);

        filter.addExpression({
          key: field,
          operator: operator,
          value: value,
        });

        ruleSet.addCondition(filter);
      }
    } else {
      ruleSet.addCondition(baseFilter);
    }

    return ruleSet;
  }

  /** Test if filters are active */
  get isFilterActive(): boolean {
    const { textFilter, selectFilters } = this.dynamicFilters;
    if (textFilter?.value?.length) {
      return true;
    }

    if (selectFilters?.length) {
      for (const selectFilter of selectFilters) {
        if (selectFilter.value?.length) {
          return true;
        }
      }
    }

    return false;
  }

  handleListLoad(fn: () => Promise<void>, options: LoadingOptions = {}) {
    super.handleLoad(
      async () => {
        this.isListLoading = true;
        try {
          await fn();
          this.isListLoading = false;
        } catch (err) {
          this.isListLoading = false;
          return Promise.reject(err);
        }
      },
      { disableGlobalLoad: true, ...options },
    );
  }

  /**
   * Use this whenever a list item is deleted or is updated
   */
  handleListUpdate(fn: any, options: LoadingOptions = {}, clearCache = false) {
    this.handleListLoad(async () => {
      await fn();
      await this.fetchData(clearCache);
    }, options);
  }

  async fetchData(clearCache = false): Promise<void> {
    if (!this.dataService) {
      return Promise.reject('Please provide a data service');
    }

    this.isListLoading = true;
    if (clearCache) await this.dataService.clearCache();

    logger.debug('populateData(), filters', {
      filter: this.filters,
      sort: this.sort,
      limit: this.limit,
      offset: this.offset,
    });

    this.expandMap.clear();

    const { data, totalSize } = await this.dataService.find({
      filter: this.filters,
      sort: this.sort,
      limit: this.limit,
      offset: this.offset,
    });

    logger.debug('populateData(), data', data);

    this.data = data;
    this.totalItems = totalSize;
    this.baseService.listService.listRefreshed.next(clearCache);
    this.isListLoading = false;
  }

  async applyFilters(debounce = false) {
    if (this.dynamicFilters?.textFilter?.value) {
      this.dynamicFilters.textFilter.value =
        this.dynamicFilters.textFilter.value.trim();
    }

    this.offset = 0;
    this.isListLoading = true;

    if (debounce) {
      return await this.debouncer.debounce(async () => {
        await this.fetchData();
      });
    }

    await this.fetchData();
  }

  async applyPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.offset = (pageNumber - 1) * this.limit;
    await this.fetchData();
  }

  async applySort(field: (keyof DeepPartial<MODEL> & string) | string) {
    if (!field || !this.sort) return;

    const order =
      field === this.sort.field && this.sort.order === MintQuerySortOrder.Asc
        ? MintQuerySortOrder.Desc
        : MintQuerySortOrder.Asc;

    this.sort = { field, order };
    await this.fetchData();
  }

  /** Collapse/Uncollapses table expand rows */
  onExpandChange(id: string, value?: any) {
    this.expandMap.has(id)
      ? this.expandMap.delete(id)
      : this.expandMap.set(id, value);
  }

  // WIP: support non-API paged arrays - add to this as needed
  getStaticDataPageItems(arr: any[], page: number, limit: number): any[] {
    return arr.slice((page - 1) * limit, page * limit);
  }
}
