import { MintObject } from '@bryllyant/mint-ngx';
import { TaxpayerModel } from '../taxpayer.model';
import { TaxpayerAuthorizationModel } from './taxpayer-authorization.model';

export type TaxpayerAuthorizationError = string | MintObject;

export enum TaxpayerAuthorizationStatus {
  Active = 'active',
  Superseded = 'superseded',
  TaxpayerPending = 'taxpayer-pending',
  TaxpayerRevoked = 'taxpayer-revoked',
  IrsPending = 'irs-pending',
  IrsExpired = 'irs-expired',
  IrsRejected = 'irs-rejected',

  // An error was encountered when fetching the transcript from the IRS.
  // NOTE: the error may be normal or expected as in the case where a
  // transcript is requested for a year when a return was not filed.
  IrsError = 'irs-error',
}

export interface TaxpayerAndAuthorizationsResponse {
  taxpayer: TaxpayerModel;
  authorizations: TaxpayerAuthorizationModel[];
}
