import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService } from '@fynvana/common';

const logger = MintLogger.getLogger('InputTagsComponent');

@Component({
  selector: 'fynvana-input-tags',
  templateUrl: './input-tags.component.html',
  styleUrls: ['./input-tags.component.scss'],
})
export class InputTagsComponent extends BaseController {
  @Input() tags: string[] = [];
  @Output() removeTagIdx = new EventEmitter<number>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }
}
