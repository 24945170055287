import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  MintAccessRole,
  MintAuthService,
  MintLogger,
  MintUserModel,
  MintUserService,
} from '@bryllyant/mint-ngx';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AppIdentifier } from '../types';

import { UserService } from '@fynvana/core';
import { AlertUtil } from '../utils';

const logger = MintLogger.getLogger('RoleGuard');

@Injectable({ providedIn: 'root' })
export class RoleGuard {
  constructor(
    private readonly authService: MintAuthService,
    private readonly notificationService: NzNotificationService,
    private readonly userService: MintUserService,
    private readonly appUserService: UserService,
    private readonly router: Router,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const user: MintUserModel = await this.authService.me();
    const isAdminPortal = route.data.app === AppIdentifier.Admin;
    const expectedRole: MintAccessRole = route.data.role;
    logger.debug('canActivate(): role expected', expectedRole);

    if (user) {
      if (isAdminPortal) {
        const hasRole = await this.userService.hasRole(user, expectedRole);

        if (!hasRole) {
          await this.appUserService.logout(true);

          // avoid exposing user's role
          AlertUtil.notify(this.notificationService, {
            error: `Invalid username or password. Please try again.`,
          });
          return false;
        }
      }

      return true;
    }

    return false;
  }
}
