import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import { BaseController, BaseService, MenuItem } from '@fynvana/common';

const logger = MintLogger.getLogger('QuickAccessMenuComponent');

@Component({
  selector: 'fynvana-quick-access-menu',
  templateUrl: './quick-access-menu.component.html',
  styleUrls: ['./quick-access-menu.component.scss'],
})
export class QuickAccessMenuComponent extends BaseController {
  @Input() menuItems: MenuItem[];
  @Input() uid: string;
  @Input() iconHeight = 20;
  @Input() iconWidth = 20;

  @Output() actionTriggered = new EventEmitter<{
    action: string;
    uid: string;
  }>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }

  async determineAction(menuItem: MenuItem) {
    if (menuItem.data?.action) {
      return this.actionTriggered.emit({
        action: menuItem.data.action as string,
        uid: this.uid,
      });
    }

    await this.navigate(menuItem);
  }
}
