import { MintCamelizer, MintLogger } from '@bryllyant/mint-ngx';

const logger = MintLogger.getLogger('TranscriptTypes');

export enum FilingStatus {
  Single = 'single',
  MarriedFilingJointly = 'married-filing-jointly',
  MarriedFilingSeparately = 'married-filing-separately',
  HeadOfHousehold = 'head-of-household',
  QualifiedWidowerWithDependentChild = 'qualified-widower-with-dependent-child',
}

export function parseFilingStatus(status: string) {
  if (!status) {
    return null;
  }

  const key = MintCamelizer.pascalize(status);

  const ret: FilingStatus = (FilingStatus as any)[key] ?? null;

  if (!ret) {
    logger.error('Filing status not found:', key);
  }

  return ret;
}
