import { plainToInstance, Type } from 'class-transformer';
import { IsDefined, IsEnum, IsOptional } from 'class-validator';
import { IrsFormEventNote } from './event/irs-form-event-note';
import { IrsFormEvent, IrsFormEventType } from './event/irs-form-event.types';
import { IrsFormStatus } from './irs-form.types';

export class IrsFormStatusDto {
  @IsEnum(IrsFormStatus)
  @IsDefined()
  readonly status: IrsFormStatus;

  @IsEnum(IrsFormEventType)
  @IsOptional()
  readonly eventType?: IrsFormEventType;

  @IsOptional()
  readonly event?: IrsFormEvent | IrsFormEvent[];

  @Type(() => Date)
  @IsOptional()
  readonly eventAt?: Date;

  @Type(() => IrsFormEventNote)
  @IsOptional()
  readonly note?: IrsFormEventNote;

  constructor(model?: Partial<IrsFormStatusDto>) {
    if (model) {
      Object.assign(this, plainToInstance(IrsFormStatusDto, model));
    }
  }
}
