<fynvana-modal
  [modalTitle]="title"
  [visible]="isVisible"
  [modalIsLoading]="isLoading"
  [closable]="false"
  [hideCancel]="true"
  (submitted)="closeModal.emit()"
  submitLabel="Ok"
>
  <section class="flex-center flex-col">
    <div class="logo">
      <svg-icon
        src="./assets/common/imgs/logo-vertical.svg"
        svgClass="svg-fluid"
      ></svg-icon>
    </div>

    <div class="mt-1 text-center">
      Version: {{ versions.frontend }}
      <br />
      API Version: {{ versions.backend }}
    </div>

    <div class="mt-1 text-center">
      Copyright © {{ currentYear }} Fynvana. All rights reserved.
    </div>
  </section>
</fynvana-modal>
