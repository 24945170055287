import { MintObject } from '@bryllyant/mint-ngx';

export enum IrsFormEventType {
  FormCreated = 'form-created',
  // a newer form was filed (does not update irs-expired or irs-rejected)
  FormSuperseded = 'form-superseded',
  TaxpayerPendingSignature = 'taxpayer-pending-signature',
  TaxpayerSigned = 'taxpayer-signed',
  AgentPendingSignature = 'agent-pending-signature',
  AgentSigned = 'agent-signed',
  FormExecuted = 'form-executed',
  IrsExpired = 'irs-expired', // 8821 does not expire but 2848 does
  IrsSubmitted = 'irs-submitted',
  IrsPending = 'irs-pending',
  IrsRejected = 'irs-rejected',
  IrsAccepted = 'irs-accepted',

  TranscriptsAccessCheck = 'transcripts-access-check',
  TranscriptsRefreshed = 'transcripts-refreshed',

  AdminUpdate = 'admin-update',
}

export type IrsFormEvent = string | MintObject;
