import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Config } from '../config';
import { AlertOptions } from '../types';
import { ParseUtil } from './parse-util';

export class AlertUtil {
  /** Display a toast notification. */
  static notify(
    notificationService: NzNotificationService,
    { success, error, duration }: AlertOptions = {},
  ) {
    const type = success ? 'success' : 'error';

    const label = ParseUtil.capitalizeFirst(type);
    const message = success ?? error;

    const options = {
      nzDuration:
        duration ?? success ? Config.SUCCESS_DURATION : Config.ERROR_DURATION,
    };
    switch (type) {
      case 'error':
        notificationService.error(
          label,
          ParseUtil.errorToString(message ?? ''),
          options,
        );
        break;
      default:
        notificationService.success(label, message ?? '', options);
    }
  }
}
