import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { IndividualPhoneModel } from './individual-phone.model';

const logger = MintLogger.getLogger('IndividualPhoneService');

@Injectable({ providedIn: 'root' })
export class IndividualPhoneService extends MintService<IndividualPhoneModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      IndividualPhoneModel,
      IndividualPhoneModel._mintResourceName,
    );
  }
}
