import { Injectable } from '@angular/core';
import {
  MintCacheService,
  MintHttpService,
  MintLogger,
  MintService,
} from '@bryllyant/mint-ngx';
import { TaxpayerAuthorizationEventDto } from './taxpayer-authorization-event.dto';
import { TaxpayerAuthorizationEventModel } from './taxpayer-authorization-event.model';

const logger = MintLogger.getLogger('TaxpayerAuthorizationEventService');

@Injectable({ providedIn: 'root' })
export class TaxpayerAuthorizationEventService extends MintService<TaxpayerAuthorizationEventModel> {
  constructor(
    private readonly httpService: MintHttpService,
    private readonly cacheService: MintCacheService,
  ) {
    super(
      httpService,
      cacheService,
      TaxpayerAuthorizationEventModel,
      TaxpayerAuthorizationEventModel._mintResourceName,
    );
  }

  async save(
    event: TaxpayerAuthorizationEventDto,
  ): Promise<TaxpayerAuthorizationEventModel> {
    return super.save(event);
  }
}
