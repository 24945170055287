import {
  MintEntityMeta,
  MintModel,
  MintPhoneType,
  MintProperty,
  MintResource,
  MintVerificationStatus,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsPhoneNumber, IsString } from 'class-validator';
import { IndividualModel } from '../individual.model';

@MintResource('/individual-phones')
export class IndividualPhoneModel extends MintModel {
  @MintProperty()
  @IsString()
  declare readonly uid: string;

  @MintProperty()
  @IsEnum(MintPhoneType)
  readonly type: MintPhoneType;

  @MintProperty()
  @Type(() => IndividualModel)
  readonly individual: IndividualModel;

  @MintProperty()
  @IsPhoneNumber()
  readonly phoneNumber: string;

  @MintProperty()
  @IsString()
  readonly phoneExtension: string;

  @MintProperty()
  @IsBoolean()
  readonly isPrimary: boolean;

  @MintProperty()
  @IsEnum(MintVerificationStatus)
  readonly verificationStatus: MintVerificationStatus;

  @MintProperty()
  @Type(() => Date)
  readonly verifiedAt: Date;

  @MintProperty()
  @Type(() => Date)
  declare readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  declare readonly updatedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  declare readonly _meta: MintEntityMeta;

  constructor(model?: Partial<IndividualPhoneModel>) {
    super(model);
  }
}
