import {
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsString } from 'class-validator';
import { TaxpayerConsentModel } from '../../taxpayer/consent/taxpayer-consent.model';
import { TaxpayerModel } from '../../taxpayer/taxpayer.model';
import { ProjectModel } from '../project.model';

@MintResource('/project-taxpayers')
export class ProjectTaxpayerModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => ProjectModel)
  readonly project: ProjectModel;

  @MintProperty()
  @Type(() => TaxpayerModel)
  readonly taxpayer: TaxpayerModel;

  @MintProperty()
  @Type(() => TaxpayerConsentModel)
  readonly taxpayerConsent: TaxpayerConsentModel;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<ProjectTaxpayerModel>) {
    super(model);
  }
}
