import { MintConfigOptions } from '@bryllyant/mint-ngx';
import { _default } from './default';

export const environment: MintConfigOptions = {
  env: 'production',
  configs: [
    {
      _default: _default,
    },
    {
      production: {
        websiteBaseUrl: 'https://fynvana.com/',
        adminBaseUrl: 'https://admin.fynvana.com/',
        developerBaseUrl: 'https://developer.fynvana.com/',
        docsBaseUrl: 'https://docs.fynvana.com/',
        clientBaseUrl: 'https://client.fynvana.com/',

        api: {
          baseUrl: 'https://api.fynvana.com/',
        },

        logger: {
          level: 'info',
        },

        services: {},
      },
    },
  ],
};
