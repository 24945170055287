import { plainToInstance, Type } from 'class-transformer';
import { IsDefined, IsEnum, IsObject, IsOptional } from 'class-validator';
import { IrsFormModel } from '../irs-form.model';
import { IrsFormEventNote } from './irs-form-event-note';
import { IrsFormEvent, IrsFormEventType } from './irs-form-event.types';

export class IrsFormEventDto {
  @Type(() => IrsFormModel)
  @IsDefined()
  readonly irsForm: IrsFormModel;

  @IsEnum(IrsFormEventType)
  @IsDefined()
  readonly type: IrsFormEventType;

  @IsObject()
  @IsOptional()
  readonly event?: IrsFormEvent | IrsFormEvent[];

  @Type(() => Date)
  @IsOptional()
  readonly eventAt?: Date;

  @Type(() => IrsFormEventNote)
  @IsOptional()
  readonly note?: IrsFormEventNote;

  constructor(model?: Partial<IrsFormEventDto>) {
    if (model) {
      Object.assign(this, plainToInstance(IrsFormEventDto, model));
    }
  }
}
