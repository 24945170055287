import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { MintLogger } from '@bryllyant/mint-ngx';
import {
  BaseController,
  BaseService,
  BtnItem,
  StatusType,
} from '@fynvana/common';

const logger = MintLogger.getLogger('AlertComponent');

@Component({
  selector: 'fynvana-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent extends BaseController {
  @Input() type: StatusType | any;
  @Input() alertTitle: string | undefined;
  @Input() message: string | TemplateRef<void> | undefined;
  @Input() btn: BtnItem;
  @Input() secondBtn: BtnItem;
  @Input() showAlertIcon = true;
  @Input() showAlertTitle = true;
  @Input() wrapperClasses: string;

  @Input() closable = false;
  @Output() alertClosed = new EventEmitter<null>();
  @Output() btnClicked = new EventEmitter<null>();
  @Output() secondBtnClicked = new EventEmitter<null>();

  constructor(public baseService: BaseService) {
    super(baseService);
  }
}
