import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
  MintAuthChannel,
  MintAuthService,
  MintAuthType,
  MintLogger,
  MintSession,
} from '@bryllyant/mint-ngx';
import { StatusType } from '../types';

const logger = MintLogger.getLogger('VerificationService');

@Injectable({ providedIn: 'root' })
export class VerificationService {
  verificationResult = new Subject<{
    type: StatusType;
    value: MintSession | HttpErrorResponse | any;
  }>();

  constructor(private readonly authService: MintAuthService) {}

  async requestCode(
    verificationType: MintAuthType,
    mfaChannel: MintAuthChannel,
    username = '',
  ) {
    switch (verificationType) {
      case MintAuthType.EmailVerification:
        await this.authService.requestEmailAuthCode();
        break;
      case MintAuthType.MobileVerification:
        await this.authService.requestMobileAuthCode();
        break;
      case MintAuthType.Mfa:
        await this.authService.requestMfaCode(mfaChannel);
        break;
      case MintAuthType.PasswordReset:
        logger.debug('calling resetPasswordRequest ...');
        await this.authService.resetPasswordRequest(username);
        break;
    }
  }

  async resendCode(
    verificationType: MintAuthType,
    mfaChannel: MintAuthChannel,
    username = '',
  ) {
    switch (verificationType) {
      case MintAuthType.EmailVerification:
        await this.authService.requestEmailAuthCode();
        break;

      case MintAuthType.MobileVerification:
        await this.authService.requestMobileAuthCode();
        break;

      case MintAuthType.PasswordReset:
        await this.authService.resetPasswordRequest(username);
        break;

      case MintAuthType.Mfa:
        if (mfaChannel !== MintAuthChannel.Authenticator) {
          await this.authService.requestMfaCode(mfaChannel);
        }
    }
  }
}
