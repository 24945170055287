import {
  MintAccountModel,
  MintEntityMeta,
  MintModel,
  MintProperty,
  MintResource,
} from '@bryllyant/mint-ngx';
import { Type } from 'class-transformer';
import { IsEnum, IsString } from 'class-validator';
import { BusinessModel } from '../business/business.model';
import { ClientStatus, ClientType } from './client.types';

@MintResource('/clients')
export class ClientModel extends MintModel {
  @MintProperty()
  @IsString()
  readonly uid: string;

  @MintProperty()
  @Type(() => MintAccountModel)
  readonly account: MintAccountModel;

  @MintProperty()
  @Type(() => BusinessModel)
  readonly business: BusinessModel;

  @MintProperty()
  @IsString()
  readonly name: string;

  @MintProperty()
  @IsString()
  readonly slug: string;

  @MintProperty()
  @IsEnum(ClientType)
  readonly type: ClientType;

  @MintProperty()
  @IsEnum(ClientStatus)
  readonly status: ClientStatus;

  @MintProperty()
  @Type(() => Date)
  readonly updatedAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly createdAt: Date;

  @MintProperty()
  @Type(() => Date)
  readonly deletedAt: Date;

  @MintProperty()
  @Type(() => MintEntityMeta)
  readonly _meta: MintEntityMeta;

  constructor(model?: Partial<ClientModel>) {
    super(model);
  }
}
